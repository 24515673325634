<div class="row my-5">
    <div class="col-md-3">


        <!--Card-->
        <mdb-card cascade="true" narrower="true">

            <!--Card image-->
            <div class="view view-cascade overlay hm-white-slight waves-light" mdbWavesEffect>
                <img src="https://mdbootstrap.com/img/Photos/Horizontal/People/6-col/img%20%283%29.jpg"
                     class="img-fluid">
                <a>
                    <div class="mask"></div>
                </a>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">
                <!--Title-->
                <h4 class="card-title font-bold mb-2"><strong>Alice Mayer</strong></h4>
                <h5 class="indigo-text"><strong>Photographer</strong></h5>

                <h6 class='text-justify'><strong>About:</strong></h6>
                <p class='text-justify'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione perferendis
                    quod animi dignissimos consectetur
                    quibusdam numquam laboriosam, minus, provident...</p>

                <div class="text-right">
                    <button type="button" mdbBtn outline="true" color="primary" rounded="true" size="sm" mdbWavesEffect>
                        More...
                    </button>
                </div>


                <hr>
                <!--Linkedin-->
                <a class="icons-sm li-ic px-3">
                    <mdb-icon fab icon="linkedin-in"></mdb-icon>
                </a>
                <!--Twitter-->
                <a class="icons-sm tw-ic px-3">
                    <mdb-icon fab icon="twitter"></mdb-icon>
                </a>
                <!--Dribbble-->
                <a class="icons-sm fb-ic px-3">
                    <mdb-icon fab icon="facebook-f"></mdb-icon>
                </a>

            </mdb-card-body>
            <!--/.Card content-->

        </mdb-card>
        <!--/.Card-->


        <!--Card-->
        <mdb-card cascade="true" narrower="true" class="mt-5">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header blue-gradient">
                <h4 class="h4-responsive">Achievments</h4>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">

                <div class="chip">
                    <img src="https://d30y9cdsu7xlg0.cloudfront.net/png/158907-200.png"
                         alt="Ask a question and accept an answer" class="img-fluid"> Scholar
                </div>
                <div class="chip">
                    <img src="https://www.hrglobalindia.com/wp-content/uploads/2015/05/icon3-e1431786925198.png"
                         alt="Provide non-wiki answers of 15 total score in 20 of top 40 tags" class="img-fluid"> Generalist
                </div>
                <div class="chip">
                    <img src="https://d30y9cdsu7xlg0.cloudfront.net/png/359329-200.png"
                         alt=" Accepted answer and score of 40 or more" class="img-fluid"> Guru
                </div>
                <div class="chip">
                    <img src="https://d30y9cdsu7xlg0.cloudfront.net/png/62983-200.png"
                         alt="Answer a question with score of 1 or more" class="img-fluid"> Teacher
                </div>

            </mdb-card-body>
            <!--/.Card content-->

        </mdb-card>
        <!--/.Card-->

    </div>

    <div class="col-md-9">

        <!--Projects section v.2-->
        <section class="section text-center pb-3">

            <!--Section heading-->
            <!-- <h2 class="section-heading h1 pt-4"><strong>Latest projects:</strong></h2> -->
            <!--Section description-->

            <!--Grid row-->
            <div class="row d-flex justify-content-center">

                <!--Grid column-->
                <div class="col-lg-6 col-xl-5 mb-3">

                    <!--Card-->
                    <mdb-card cascade="true" narrower="true" class="d-flex mb-5">

                        <!--Card image-->
                        <div class="view view-cascade overlay hm-white-slight">
                            <img src="https://mdbootstrap.com/img/Mockups/Horizontal/6-col/pro-profile-page.jpg"
                                 class="img-fluid" alt="">
                            <a href="#">
                                <div class="mask"></div>
                            </a>
                        </div>

                        <!--Card content-->
                        <mdb-card-body cascade="true">
                            <!--Title-->
                            <h4 class="card-title font-bold mb-3"><strong>Project name</strong></h4>
                            <!--Text-->
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk
                                of the card's content.
                            </p>
                        </mdb-card-body>

                        <!--Card footer-->
                        <mdb-card-footer class="d-flex justify-content-between links-light">
                                                <span class="text-left left pt-2">
                                                    <a><mdb-icon fas icon="share-alt" class="mr-2"></mdb-icon></a>
                                                    <a><mdb-icon far icon="heart" class="mr-2"></mdb-icon>10</a>
                                                </span>
                            <span class="text-right right">
                                                    <a href="" class="waves-effect p-2">Live Preview <mdb-icon fas
                                                                                                               icon="camera"
                                                                                                               class="ml-1"></mdb-icon></a>
                                                </span>
                        </mdb-card-footer>

                    </mdb-card>
                    <!--/.Card-->

                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-6 col-xl-5 mb-3">

                    <!--Card-->
                    <mdb-card cascade="true" narrower="true" class="d-flex mb-5">

                        <!--Card image-->
                        <div class="view view-cascade overlay hm-white-slight">
                            <img src="https://mdbootstrap.com/img/Mockups/Horizontal/6-col/pro-signup.jpg"
                                 class="img-fluid" alt="">
                            <a href="#">
                                <div class="mask"></div>
                            </a>
                        </div>

                        <!--Card content-->
                        <mdb-card-body cascade="true">
                            <!--Title-->
                            <h4 class="card-title font-bold mb-3"><strong>Project name</strong></h4>
                            <!--Text-->
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk
                                of the card's content.
                            </p>
                        </mdb-card-body>

                        <!--Card footer-->
                        <mdb-card-footer class="d-flex justify-content-between links-light">
                            <span class="left pt-2">
                                <a><mdb-icon fas icon="share-alt" class="mr-2"></mdb-icon></a>
                                <a><mdb-icon far icon="heart" class="mr-2"></mdb-icon>10</a>
                            </span>
                            <span class="right"> 
                                <a href="" class="waves-effect p-2">Live Preview <mdb-icon fas icon="camera"
                                                                                           class="ml-1"></mdb-icon></a>
                            </span>
                        </mdb-card-footer>

                    </mdb-card>
                    <!--/.Card-->

                </div>
                <!--Grid column-->

            </div>
            <!--Grid row-->

            <!--Grid row-->
            <div class="row d-flex justify-content-center">

                <!--Grid column-->
                <div class="col-lg-6 col-xl-5 mb-3">

                    <!--Card-->
                    <mdb-card cascade="true" narrower="true" class="d-flex mb-5">

                        <!--Card image-->
                        <div class="view view-cascade overlay hm-white-slight">
                            <img src="https://mdbootstrap.com/img/Mockups/Horizontal/6-col/pro-pricing.jpg"
                                 class="img-fluid" alt="">
                            <a href="#">
                                <div class="mask"></div>
                            </a>
                        </div>

                        <!--Card content-->
                        <mdb-card-body cascade="true">
                            <!--Title-->
                            <h4 class="card-title font-bold mb-3"><strong>Project name</strong></h4>
                            <!--Text-->
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk
                                of the card's content.
                            </p>
                        </mdb-card-body>

                        <!--Card footer-->
                        <mdb-card-footer class="d-flex justify-content-between links-light">
                            <span class="left pt-2">
                                <a><mdb-icon fas icon="share-alt" class="mr-2"></mdb-icon></a>
                                <a><mdb-icon far icon="heart" class="mr-2"></mdb-icon>10</a>
                            </span>
                            <span class="right"> 
                                <a href="" class="waves-effect p-2">Live Preview <mdb-icon fas icon="camera"
                                                                                           class="ml-1"></mdb-icon></a>
                            </span>
                        </mdb-card-footer>

                    </mdb-card>
                    <!--/.Card-->

                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-6 col-xl-5 mb-3">

                    <!--Card-->
                    <mdb-card cascade="true" narrower="true" class="d-flex mb-5">

                        <!--Card image-->
                        <div class="view view-cascade overlay hm-white-slight">
                            <img src="https://mdbootstrap.com/img/Mockups/Horizontal/6-col/pro-landing.jpg"
                                 class="img-fluid" alt="">
                            <a href="#">
                                <div class="mask"></div>
                            </a>
                        </div>

                        <!--Card content-->
                        <mdb-card-body cascade="true">
                            <!--Title-->
                            <h4 class="card-title font-bold mb-3"><strong>Project name</strong></h4>
                            <!--Text-->
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk
                                of the card's content.
                            </p>

                        </mdb-card-body>

                        <!--Card footer-->
                        <mdb-card-footer class="d-flex justify-content-between links-light">
                            <span class="left pt-2">
                                <a><mdb-icon fas icon="share-alt" class="mr-2"></mdb-icon></a>
                                <a><mdb-icon far icon="heart" class="mr-2"></mdb-icon>10</a>
                            </span>
                            <span class="right"> 
                                <a href="" class="waves-effect p-2">Live Preview <mdb-icon fas icon="camera"
                                                                                           class="ml-1"></mdb-icon></a>
                            </span>
                        </mdb-card-footer>

                    </mdb-card>
                    <!--/.Card-->

                </div>
                <!--Grid column-->
                <div class="col-lg-12">
                    <div class="text-center">

                        <!--Pagination -->
                        <nav class="my-4">
                            <ul class="pagination pagination-circle pg-blue mb-0 flex-wrap">

                                <!--First-->
                                <li class="page-item disabled"><a class="page-link" mdbWavesEffect>First</a></li>

                                <!--Arrow left-->
                                <li class="page-item disabled">
                                    <a class="page-link" mdbWavesEffect aria-label="Previous">
                                        <span aria-hidden="true">«</span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                </li>

                                <!--Numbers-->
                                <li class="page-item active"><a class="page-link" mdbWavesEffect>1</a></li>
                                <li class="page-item"><a class="page-link" mdbWavesEffect>2</a></li>
                                <li class="page-item"><a class="page-link" mdbWavesEffect>3</a></li>
                                <li class="page-item"><a class="page-link" mdbWavesEffect>4</a></li>
                                <li class="page-item"><a class="page-link" mdbWavesEffect>5</a></li>

                                <!--Arrow right-->
                                <li class="page-item">
                                    <a class="page-link" mdbWavesEffect aria-label="Next">
                                        <span aria-hidden="true">»</span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </li>

                                <!--First-->
                                <li class="page-item"><a class="page-link">Last</a></li>

                            </ul>
                        </nav>
                        <!--/Pagination -->
                    </div>
                </div>
            </div>
            <!--Grid row-->

        </section>
        <!--/Projects section v.2-->
    </div>


</div>
