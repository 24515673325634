<div class="row mt-5">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">Tabs & pills</h4>
      </div>
      <!--/Card image-->
      <!--Card content-->
      <mdb-card-body cascade="true" class="text-center">
        <!--Basic example-->
        <section class="section">

          <h5 class="mt-5 mb-5 dark-grey-text font-bold">Basic examples</h5>

          <!--Grid row-->
          <div class="row mb-5">

            <!--Grid column-->
            <div class="col-md-6 my-5">

              <!-- Nav Tabs -->
              <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-3'" [contentClass]="'card'">
                <!--Panel 1-->
                <mdb-tab heading="Profile">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <!--Panel 2-->
                <mdb-tab heading="Follow">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <!--Panel 3-->
                <mdb-tab heading="Contact">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
              </mdb-tabset>

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-6 my-5">

              <!-- Nav tabs -->
              <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-3 indigo'" [contentClass]="''">
                <!--Panel 1-->
                <mdb-tab heading="<i class='fas fa-user'></i> Profile">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <!--Panel 2-->
                <mdb-tab heading="<i class='fas fa-heart'></i> Follow">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <!--Panel 3-->
                <mdb-tab heading="<i class='fas fa-envelope'></i> Mail">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
              </mdb-tabset>

            </div>
            <!--Grid column-->

          </div>
          <!--Grid row-->

          <!--Grid row-->
          <div class="row">

            <!--Grid column-->
            <div class="col-md-12">


              <!-- Nav tabs -->
              <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-4 pills-secondary white margin'" [contentClass]="''">
                <!--Panel 1-->
                <mdb-tab heading="Profile">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <!--Panel 2-->
                <mdb-tab heading="Follow">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <!--Panel 3-->
                <mdb-tab heading="Mail">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <!--Panel 4-->
                <mdb-tab heading="Help">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
              </mdb-tabset>

            </div>
            <!--Grid column-->


          </div>
          <!--Grid row-->

        </section>
        <!--Basic example-->

        <!--Classic tabs-->
        <section class="section mb-5 pb-4">
          <h5 class="mt-5 mb-5 dark-grey-text font-bold">Classic tabs</h5>

          <div class="row">
            <div class="col-12 my-3">

              <!-- Nav tabs -->
              <mdb-tabset [buttonClass]="'classic-tabs tabs-cyan'" [contentClass]="'card'" class="classic-tabs">
                <!--Panel 1-->
                <mdb-tab heading="Profile">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <!--Panel 2-->
                <mdb-tab heading="Follow">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <!--Panel 3-->
                <mdb-tab heading="Contact">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <mdb-tab heading="Be awesome">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
              </mdb-tabset>
            </div>

            <div class="col-12 my-3">

              <!-- Nav tabs -->
              <mdb-tabset [buttonClass]="'classic-tabs tabs-orange'" [contentClass]="'card'" class="classic-tabs">
                <!--Panel 1-->
                <mdb-tab heading="<i class='fas fa-user fa-2x'></i><br>Profile">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <!--Panel 2-->
                <mdb-tab heading="<i class='fas fa-heart fa-2x'></i><br>Follow">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <!--Panel 3-->
                <mdb-tab heading="<i class='fas fa-envelope fa-2x'></i><br>Contact">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
                <mdb-tab heading="<i class='fas fa-star fa-2x'></i><br>Be awesome">
                  <div class="row">
                    <div class="col-12">
                      <br>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil odit magnam minima, soluta doloribus
                        reiciendis molestiae placeat unde eos molestias. Quisquam aperiam, pariatur. Tempora, placeat ratione
                        porro voluptate odit minima.</p>
                    </div>
                  </div>
                </mdb-tab>
              </mdb-tabset>
            </div>


          </div>

        </section>
        <!--Classic tabs-->
        <!--Section: Docs link-->
        <section class="pb-4">
          <panel header="Full documentation" color="primary-color">
            <p class="card-text">Read the full documentation for these components.</p>
            <a href="https://mdbootstrap.com/angular/components/tabs/" target="_blank" mdbBtn color="primary" class="waves-effect waves-light">Learn more</a>
          </panel>
        </section>
        <!--/.Section: Docs link-->
      </mdb-card-body>
      <!--/.Card content-->
    </mdb-card>
    <!--/.Card-->
  </div>
</div>
