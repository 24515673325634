<section class="section team-section">

    <!-- First row -->
    <div class="row">
        <!-- First column -->
        <div class="col-md-8">
            <div class="row mb-1">
                <div class="col-md-9">
                    <h4 class="h4-responsive mt-1">Your Clients</h4>
                </div>
                <div class="col-md-3">
                    <div class="md-form">
                        <input placeholder="Search..." type="text" id="form5" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-1">
                    <!-- Tabs -->
                    <!-- Nav tabs -->
					<mdb-tabset [buttonClass]="'classic-tabs tabs-primary primary-color'" [contentClass]="'card'" class="classic-tabs">
					    <!--Panel 1-->
					    <mdb-tab heading="Personal Clients">
					        <!--Panel 1-->
	                        <div class="tab-pane fade active show" id="panel83" role="tabpanel" aria-expanded="true">
	                            <div class="table-responsive">
	                                <table class="table">
	                                    <thead>
	                                        <tr>
	                                            <th>#</th>
	                                            <th>First Name</th>
	                                            <th>Last Name</th>
	                                            <th>Username</th>
	                                            <th>Actions</th>
	                                        </tr>
	                                    </thead>
	                                    <tbody>
	                                        <tr>
	                                            <th scope="row">1</th>
	                                            <td>Abby</td>
	                                            <td>Barrett</td>
	                                            <td>@abbeme</td>
	                                            <td>
	                                                <a class="blue-text" mdbTooltip="See results" placement="top"><mdb-icon fas icon="user" class="px-1"></mdb-icon></a>
	                                                <a class="teal-text" mdbTooltip="Edit" placement="top"><mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon></a>
	                                                <a class="red-text" mdbTooltip="Remove" placement="top"><mdb-icon fas icon="times" class="px-1"></mdb-icon></a>
	                                            </td>
	                                        </tr>
	                                        <tr>
	                                            <th scope="row">2</th>
	                                            <td>Danny</td>
	                                            <td>Collins</td>
	                                            <td>@dennis</td>
	                                            <td>
													<a class="blue-text" mdbTooltip="See results" placement="top"><mdb-icon fas icon="user" class="px-1"></mdb-icon></a>
													<a class="teal-text" mdbTooltip="Edit" placement="top"><mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon></a>
													<a class="red-text" mdbTooltip="Remove" placement="top"><mdb-icon fas icon="times" class="px-1"></mdb-icon></a>
	                                            </td>
	                                        </tr>
	                                        <tr>
	                                            <th scope="row">3</th>
	                                            <td>Clara</td>
	                                            <td>Ericson</td>
	                                            <td>@claris</td>
	                                            <td>
													<a class="blue-text" mdbTooltip="See results" placement="top"><mdb-icon fas icon="user" class="px-1"></mdb-icon></a>
													<a class="teal-text" mdbTooltip="Edit" placement="top"><mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon></a>
													<a class="red-text" mdbTooltip="Remove" placement="top"><mdb-icon fas icon="times" class="px-1"></mdb-icon></a>
	                                            </td>
	                                        </tr>
	                                        <tr>
	                                            <th scope="row">4</th>
	                                            <td>Jessie</td>
	                                            <td>Doe</td>
	                                            <td>@jessiedoeofficial</td>
	                                            <td>
													<a class="blue-text" mdbTooltip="See results" placement="top"><mdb-icon fas icon="user" class="px-1"></mdb-icon></a>
													<a class="teal-text" mdbTooltip="Edit" placement="top"><mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon></a>
													<a class="red-text" mdbTooltip="Remove" placement="top"><mdb-icon fas icon="times" class="px-1"></mdb-icon></a>
	                                            </td>
	                                        </tr>
	                                        <tr>
	                                            <th scope="row">5</th>
	                                            <td>Saul</td>
	                                            <td>Hudson</td>
	                                            <td>@slash</td>
	                                            <td>
													<a class="blue-text" mdbTooltip="See results" placement="top"><mdb-icon fas icon="user" class="px-1"></mdb-icon></a>
													<a class="teal-text" mdbTooltip="Edit" placement="top"><mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon></a>
													<a class="red-text" mdbTooltip="Remove" placement="top"><mdb-icon fas icon="times" class="px-1"></mdb-icon></a>
	                                            </td>
	                                        </tr>
	                                    </tbody>
	                                </table>
	                            </div>
	                        </div>
	                        <!--/.Panel 1-->
					    </mdb-tab>
					    <!--Panel 2-->
					    <mdb-tab heading="Corporate Clients">
					        <!--Panel 2-->
					        <div class="tab-pane fade active show" id="panel84" role="tabpanel" aria-expanded="false">
					            <div class="table-responsive">
					                <table class="table">
					                    <thead>
					                        <tr>
					                            <th>#</th>
					                            <th>Company Name</th>
					                            <th>Username</th>
					                            <th>Actions</th>
					                        </tr>
					                    </thead>
					                    <tbody>
					                        <tr>
					                            <th scope="row">1</th>
					                            <td>PiedPiper</td>
					                            <td>@piedpiper</td>
					                            <td>
													<a class="blue-text" mdbTooltip="See results" placement="top"><mdb-icon fas icon="user" class="px-1"></mdb-icon></a>
													<a class="teal-text" mdbTooltip="Edit" placement="top"><mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon></a>
													<a class="red-text" mdbTooltip="Remove" placement="top"><mdb-icon fas icon="times" class="px-1"></mdb-icon></a>
					                            </td>
					                        </tr>
					                        <tr>
					                            <th scope="row">2</th>
					                            <td>Github, Inc</td>
					                            <td>@github</td>
					                            <td>
													<a class="blue-text" mdbTooltip="See results" placement="top"><mdb-icon fas icon="user" class="px-1"></mdb-icon></a>
													<a class="teal-text" mdbTooltip="Edit" placement="top"><mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon></a>
													<a class="red-text" mdbTooltip="Remove" placement="top"><mdb-icon fas icon="times" class="px-1"></mdb-icon></a>
					                            </td>
					                        </tr>
					                        <tr>
					                            <th scope="row">3</th>
					                            <td>Twitter, Inc</td>
					                            <td>@twitter</td>
					                            <td>
													<a class="blue-text" mdbTooltip="See results" placement="top"><mdb-icon fas icon="user" class="px-1"></mdb-icon></a>
													<a class="teal-text" mdbTooltip="Edit" placement="top"><mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon></a>
													<a class="red-text" mdbTooltip="Remove" placement="top"><mdb-icon fas icon="times" class="px-1"></mdb-icon></a>
					                            </td>
					                        </tr>
					                        <tr>
					                            <th scope="row">4</th>
					                            <td>Alphabet, Inc</td>
					                            <td>@alphabet</td>
					                            <td>
													<a class="blue-text" mdbTooltip="See results" placement="top"><mdb-icon fas icon="user" class="px-1"></mdb-icon></a>
													<a class="teal-text" mdbTooltip="Edit" placement="top"><mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon></a>
													<a class="red-text" mdbTooltip="Remove" placement="top"><mdb-icon fas icon="times" class="px-1"></mdb-icon></a>
					                            </td>
					                        </tr>
					                        <tr>
					                            <th scope="row">5</th>
					                            <td>Adobe Corporation</td>
					                            <td>@adobe</td>
					                            <td>
													<a class="blue-text" mdbTooltip="See results" placement="top"><mdb-icon fas icon="user" class="px-1"></mdb-icon></a>
													<a class="teal-text" mdbTooltip="Edit" placement="top"><mdb-icon fas icon="pencil-alt" class="px-1"></mdb-icon></a>
													<a class="red-text" mdbTooltip="Remove" placement="top"><mdb-icon fas icon="times" class="px-1"></mdb-icon></a>
					                            </td>
					                        </tr>
					                    </tbody>
					                </table>
					            </div>
					        </div>
					        <!--/.Panel 2-->
					    </mdb-tab>
					</mdb-tabset>
                </div>
            </div>
        </div>
        <!-- /.First column -->
        <!-- Second column -->
        <div class="col-md-4 mb-1">

            <!--Card-->
            <mdb-card class="profile-card">

                <!--Avatar-->
                <div class="avatar z-depth-1-half mb-4">
                    <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(10).jpg" class="rounded-circle" alt="First sample avatar image">
                </div>

                <mdb-card-body class="pt-0 mt-0">
                    <!--Name-->
                    <div class="text-center">
                        <h3 class="mb-3 font-bold"><strong>Anna Deynah</strong></h3>
                        <h6 class="font-bold blue-text mb-4">Web Designer</h6>
                    </div>

                    <ul class="striped list-unstyled">
                        <li><strong>E-mail address:</strong> a.doe@example.com</li>
                        
                        <li><strong>Phone number:</strong> +1 234 5678 90</li>
                        
                        <li><strong>Company:</strong> The Company, Inc</li>
                        
                        <li><strong>Twitter username:</strong> @anna.doe</li>
                        
                        <li><strong>Instagram username:</strong> @anna.doe</li>
                    </ul>

                </mdb-card-body>

            </mdb-card>
            <!--Card-->

        </div>
    </div>
    <!-- /.Second column -->
</section>
