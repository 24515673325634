<div class="row mt-5">
    <div class="col-md-6">
        <!--Card-->
        <mdb-card cascade="true" narrower="true" class="mt-5">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header blue-gradient">
                <h4 class="h4-responsive">Regular map</h4>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">
                <agm-map [latitude]="map.lat" [longitude]="map.lng" [mapTypeId]="'roadmap'" style="height: 300px">
                    <agm-marker [latitude]="map.lat" [longitude]="map.lng"></agm-marker>
                </agm-map>

            </mdb-card-body>
            <!--/.Card content-->
        </mdb-card>
        <!--/.Card-->
    </div>
    <div class="col-md-6">
        <!--Card-->
        <mdb-card cascade="true" narrower="true" class="mt-5">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header blue-gradient">
                <h4 class="h4-responsive">Hybrid map</h4>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">
                <agm-map [latitude]="map.lat" [longitude]="map.lng" [mapTypeId]="'hybrid'" style="height: 300px">
                    <agm-marker [latitude]="map.lat" [longitude]="map.lng"></agm-marker>
                </agm-map>

            </mdb-card-body>
            <!--/.Card content-->
        </mdb-card>
        <!--/.Card-->
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-6">
        <!--Card-->
        <mdb-card cascade="true" narrower="true" class="mt-5">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header blue-gradient">
                <h4 class="h4-responsive">Satelite map</h4>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">
                <agm-map [latitude]="map.lat" [longitude]="map.lng" [mapTypeId]="'satellite'" style="height: 300px">
                    <agm-marker [latitude]="map.lat" [longitude]="map.lng"></agm-marker>
                </agm-map>

            </mdb-card-body>
            <!--/.Card content-->
        </mdb-card>
        <!--/.Card-->
    </div>
    <div class="col-md-6">
        <!--Card-->
        <mdb-card cascade="true" narrower="true" class="mt-5">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header blue-gradient">
                <h4 class="h4-responsive">Terrains map</h4>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">
                <agm-map [latitude]="map.lat" [longitude]="map.lng" [mapTypeId]="'terrain'" style="height: 300px">
                    <agm-marker [latitude]="map.lat" [longitude]="map.lng"></agm-marker>
                </agm-map>

            </mdb-card-body>
            <!--/.Card content-->
        </mdb-card>
        <!--/.Card-->
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-md-10">
        <!--Section: Docs link-->
        <section class="pb-4 pt-5">
            <panel header="Full documentation" color="primary-color">
                <p class="card-text">Read the full documentation for these components.</p>
                <a href="https://mdbootstrap.com/angular/components/maps/" target="_blank" mdbBtn color="primary"
                   class="waves-effect waves-light">Learn more</a>
            </panel>
        </section>
        <!--/.Section: Docs link-->
    </div>
</div>
