<app-stats-card></app-stats-card>

<section>
<div class="row">

    <!-- First column -->

    <div class="col-lg-6 col-md-12">

        <!--Panel-->
        <div class="my-5">
            <panel header="Recent comments and replies">
                
                <mdb-card-body>

                    <table class="table no-header">
                        <tbody>
                            <tr>
                                <td>John Doe</td>
                                <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</td>
                                <td class="hour"><small><span class="grey-text float-right"><mdb-icon far icon="clock" aria-hidden="true"></mdb-icon> 12 min</span></small></td>
                            </tr>
                            <tr>
                                <td>Merry Joe</td>
                                <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</td>
                                <td class="hour"><small><span class="grey-text float-right"><mdb-icon far icon="clock" aria-hidden="true"></mdb-icon> 12 min</span></small></td>
                            </tr>
                            <tr>
                                <td>Jessie Doe</td>
                                <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit...</td>
                                <td class="hour"><small><span class="grey-text float-right"><mdb-icon far icon="clock" aria-hidden="true"></mdb-icon> 12 min</span></small></td>
                            </tr>
                        </tbody>
                    </table>

                    <a class="btn grey btn-rounded waves-effect waves-light text-white" mdbWavesEffect>See more</a>

                </mdb-card-body>
            </panel>
        </div>
        <!--/.Panel-->

        <!-- Panel -->

        <div class="my-5">
            <panel header="Issues">
                <mdb-card-body>
                    <div class="table-responsive">

                        <table class="table">

                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Title</th>
                                    <th>User</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><mdb-badge color="green">Open</mdb-badge></td>
                                    <td>Lorem ipsum dolor</td>
                                    <td>John Doe</td>
                                    <td class="hour"><small><span class="grey-text"><mdb-icon far icon="clock" aria-hidden="true"></mdb-icon> 12 min</span></small></td>
                                </tr>
                                <tr>
                                    <td><mdb-badge color="green">Open</mdb-badge></td>
                                    <td>Lorem ipsum dolor</td>
                                    <td>John Doe</td>
                                    <td class="hour"><small><span class="grey-text"><mdb-icon far icon="clock" aria-hidden="true"></mdb-icon> 12 min</span></small></td>
                                </tr>
                                <tr>
                                    <td><mdb-badge warning="true">In progress</mdb-badge></td>
                                    <td>Lorem ipsum dolor</td>
                                    <td>John Doe</td>
                                    <td class="hour"><small><span class="grey-text"><mdb-icon far icon="clock" aria-hidden="true"></mdb-icon> 12 min</span></small></td>
                                </tr>
                                <tr>
                                    <td><mdb-badge color="red">Closed</mdb-badge></td>
                                    <td>Lorem ipsum dolor</td>
                                    <td>John Doe</td>
                                    <td class="hour"><small><span class="grey-text"><mdb-icon far icon="clock" aria-hidden="true"></mdb-icon> 12 min</span></small></td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </mdb-card-body>
            </panel>
        </div>

        <!-- /.Panel -->

    </div>

    <!-- /.First column -->

    <div class="col-lg-6 col-md-12">

        <!--Panel-->
        <div class="my-5">
            <panel header="Users activity">
                <mdb-card-body>
                    <div class="row">
                        <div class="col-4">
                            <small class="grey-text">Pages/Visits</small>
                            <h4>139 419</h4>
                        </div>
                        <div class="col-4">
                            <small class="grey-text">New visitors</small>
                            <h4>51.94%</h4>
                        </div>
                        <div class="col-4">
                            <small class="grey-text">Last week</small>
                            <h4>51 932</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <small class="grey-text">Pages/Visits</small>
                            <h4>139 419</h4>
                        </div>
                        <div class="col-4">
                            <small class="grey-text">New visitors</small>
                            <h4>51.94%</h4>
                        </div>
                        <div class="col-4">
                            <small class="grey-text">Last week</small>
                            <h4>51 932</h4>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <small class="grey-text">Pages/Visits</small>
                            <h4>139 419</h4>
                        </div>
                        <div class="col-4">
                            <small class="grey-text">New visitors</small>
                            <h4>51.94%</h4>
                        </div>
                        <div class="col-4">
                            <small class="grey-text">Last week</small>
                            <h4>51 932</h4>
                        </div>
                    </div>

                    <a mdbBtn rounded="true" class="grey waves-effect waves-light text-white" mdbWavesEffect>See more</a>
                </mdb-card-body>
            </panel>
        </div>
        <!--/.Panel-->

        <!-- Panel -->
        <mdb-card bgColor="red" classInside="py-3" class="my-5 text-center accent-2 white-text">
            <mdb-icon fas icon="bell" size="3x" class="mb-3"></mdb-icon>
            <h4 class="h4-responsive">28 warning messages</h4>
        </mdb-card>
        <!-- /.Panel -->

        <!-- Panel -->
        <div class="row my-5">
            <div class="col-4">
                <h4 class="h4-responsive"><mdb-badge info="true" class="big-badge">8K</mdb-badge> Fans</h4>
            </div>
            <div class="col-4">
                <h4 class="h4-responsive"><mdb-badge primary="true" class="big-badge">21K</mdb-badge> Orders</h4>
            </div>
            <div class="col-4">
                <h4 class="h4-responsive"><mdb-badge class="secondary-color big-badge">8K</mdb-badge> Fans</h4>
            </div>
        </div>

        <div class="row my-5">
            <div class="col-4">
                <h4 class="h4-responsive"><mdb-badge class="stylish-color big-badge">14K</mdb-badge> Total likes</h4>
            </div>
            <div class="col-4">
                <h4 class="h4-responsive"><mdb-badge warning="true" class="big-badge">14K</mdb-badge> Users</h4>
            </div>
            <div class="col-4">
                <h4 class="h4-responsive"><mdb-badge color="grey" class="big-badge darken-1">1.2K</mdb-badge> Online</h4>
            </div>
        </div>
        <!-- ./Panel -->
    </div>

    <!-- Second column -->


    <!-- /.Second column -->

</div>
</section>
