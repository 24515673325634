<div class="row mt-5">
    <div class="col-md-12">
        <!--Card-->
        <mdb-card cascade="true" narrower="true" class="mt-5">
            <!--Card image-->
            <div class="view view-cascade gradient-card-header blue-gradient">
                <h4 class="h4-responsive">Tags, labels & badges </h4>
            </div>
            <!--/Card image-->
            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">

                <!--Basic example-->
                <section class="section">

                    <h5 class="mt-5 mb-5 dark-grey-text font-bold">Basic examples</h5>

                    <div class="row pb-4">

                        <div class="col-lg-4 col-md-12 mt-2 my-5">
                            <ng-container *ngFor="let tag of predefined">
                                <div class="chip" *ngIf="tag.visible">
                                    <img *ngIf="!!tag.img" src="{{tag.img}}" alt="{{tag.text}}"> {{tag.text}}
                                    <i *ngIf="tag.closable" class="close fas fa-times"
                                       (click)="tag.visible=!tag.visible"></i>
                                </div>
                            </ng-container>

                            <div class="mt-4">
                                <h4 class="h4-responsive mb-4">Adding tags:</h4>
                                <mdb-material-chips [(ngModel)]="values"
                                                    placeholder="I am so lonely :( "></mdb-material-chips>
                                <p>You can simply add new tags. Just type something above and press Enter</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-8 my-5">
                            <h1>Example heading
                                <mdb-badge color="red">New</mdb-badge>
                            </h1>
                            <h2>Example heading
                                <mdb-badge color="blue">New</mdb-badge>
                            </h2>
                            <h3>Example heading
                                <mdb-badge color="black">New</mdb-badge>
                            </h3>
                            <h4>Example heading
                                <mdb-badge color="green">New</mdb-badge>
                            </h4>
                            <h5>Example heading
                                <mdb-badge color="grey" class="mt-3">New</mdb-badge>
                            </h5>
                            <h6>Example heading
                                <mdb-badge color="indigo">New</mdb-badge>
                            </h6>
                        </div>

                        <div class="col-lg-2 col-md-4 my-5">

                            <mdb-badge default="true">Default</mdb-badge>
                            <br>
                            <mdb-badge primary="true">Primary</mdb-badge>
                            <br>
                            <mdb-badge success="true">Success</mdb-badge>
                            <br>
                            <mdb-badge info="true">Info</mdb-badge>
                            <br>
                            <mdb-badge warning="true">Warning</mdb-badge>
                            <br>
                            <mdb-badge danger="true">Danger</mdb-badge>
                            <br>

                        </div>

                    </div>

                </section>
                <!--Basic example-->

            </mdb-card-body>
            <!--/.Card content-->
        </mdb-card>
        <!--/.Card-->
    </div>
</div>
