<div class="row my-5">
    <div class="col-md-3">


        <!--Card-->
        <mdb-card cascade="true" narrower="true">

            <!--Card image-->
            <div class="view view-cascade overlay hm-white-slight waves-light" mdbWavesEffect>
                <img src="https://mdbootstrap.com/img/Photos/Horizontal/People/6-col/img%20%283%29.jpg"
                     class="img-fluid">
                <a>
                    <div class="mask"></div>
                </a>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">
                <!--Title-->
                <h4 class="card-title mb-2 font-bold">
                    <strong>Alice Mayer</strong>
                </h4>
                <h5 class="indigo-text">
                    <strong>Photographer</strong>
                </h5>

                <h6 class='text-justify'>
                    <strong>About:</strong>
                </h6>
                <p class='text-justify'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione perferendis
                    quod animi dignissimos...</p>

                <div class="text-right">
                    <button type="button" mdbBtn outline="true" color="primary" rounded="true" size="sm" mdbWavesEffect>
                        More...
                    </button>
                </div>

                <hr>

                <div class="row">
                    <div class="col-md-8 text-left">Followers:</div>
                    <div class="col-md-4 text-right">
                        <strong>675</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 text-left">Following:</div>
                    <div class="col-md-4 text-right">
                        <b>234</b>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 text-left">Songs:</div>
                    <div class="col-md-4 text-right">27</div>
                </div>

                <hr>

                <!--Linkedin-->
                <a class="icons-sm li-ic px-3">
                    <mdb-icon fab icon="linkedin-in"></mdb-icon>
                </a>
                <!--Twitter-->
                <a class="icons-sm tw-ic px-3">
                    <mdb-icon fab icon="twitter"></mdb-icon>
                </a>
                <!--Dribbble-->
                <a class="icons-sm fb-ic px-3">
                    <mdb-icon fab icon="facebook-f"></mdb-icon>
                </a>

            </mdb-card-body>
            <!--/.Card content-->

        </mdb-card>
        <!--/.Card-->

    </div>

    <div class="col-md-9">


        <!--Card-->
        <mdb-card cascade="true" narrower="true">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header blue-gradient">
                <h4 class="h4-responsive">Edit your data:</h4>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">
                <!--Contact Form-->
                <form>

                    <!--First row-->
                    <div class="row">
                        <!--First column-->
                        <div class="col-md-4">
                            <div class="md-form">
                                <input placeholder="johnDoe78" [disabled]='true' type="text" id="form11"
                                       class="form-control" mdbInput>
                                <label class="active disabled" for="form11">Username (can't be changed)</label>
                            </div>
                        </div>

                        <!--Second column-->
                        <div class="col-md-4">
                            <div class="md-form">
                                <input placeholder="John" type="text" id="form12" class="form-control">
                                <label class="active" for="form12">First Name</label>
                            </div>
                        </div>

                        <!--Third column-->
                        <div class="col-md-4">
                            <div class="md-form">
                                <input placeholder="Doe" type="text" id="form13" class="form-control" mdbInput>
                                <label class="active" for="form13">Last Name</label>
                            </div>
                        </div>
                    </div>
                    <!--/.First row-->

                    <!--Second row-->
                    <div class="row">
                        <!--First column-->
                        <div class="col-md-6">
                            <div class="md-form">
                                <input placeholder="" type="text" id="form21" class="form-control" mdbInput>
                                <label for="form21">Address line 1</label>
                            </div>
                        </div>

                        <!--Second column-->
                        <div class="col-md-6">
                            <div class="md-form">
                                <input placeholder="" type="text" id="form22" class="form-control" mdbInput>
                                <label for="form22">Address line 2</label>
                            </div>
                        </div>
                    </div>
                    <!--/.Second row-->

                    <!--Third row-->
                    <div class="row">
                        <!--First column-->
                        <div class="col-md-4">
                            <div class="md-form">
                                <input placeholder="New York" type="text" id="form31" class="form-control" mdbInput>
                                <label class="active" for="form31">City</label>
                            </div>
                        </div>

                        <!--Second column-->
                        <div class="col-md-4">
                            <div class="md-form">
                                <input placeholder="00432" type="text" id="form32" class="form-control" mdbInput>
                                <label class="active" for="form32">Postcode</label>
                            </div>
                        </div>

                        <!--Third column-->
                        <div class="col-md-4">
                            <div class="md-form">
                                <input type="text" id="form33" class="form-control" mdbInput>
                                <label for="form33">Country</label>
                            </div>
                        </div>
                    </div>
                    <!--/.Third row-->

                    <!--Fourth row-->
                    <div class="row">
                        <div class="col-md-12">

                            <div class="md-form">
                                <textarea type="text" id="form76" class="md-textarea form-control"
                                          mdbInput>Lorem</textarea>
                                <label for="form76">Bio</label>
                            </div>

                        </div>


                    </div>
                    <!--/.Fourth row-->

                    <div class="text-center">
                        <button type="button" mdbBtn gradient="blue" rounded="true" class="waves-light" mdbWavesEffect>
                            Update
                        </button>
                    </div>

                </form>

            </mdb-card-body>
            <!--/.Card content-->

        </mdb-card>
        <!--/.Card-->

    </div>

</div>
