<header>
    <section class="view intro intro-2 ">
        <div class="full-bg-img mask rgba-stylish-strong flex-center">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-lg-5">

                        <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
                            <div class="modal-content">

                                <!--Header-->
                                <div class="modal-header">
                                    <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20%281%29.jpg"
                                         class="rounded-circle img-responsive avatar">
                                </div>
                                <!--Body-->
                                <div class="modal-body text-center mb-1">

                                    <h5 class="mt-1 mb-2">Maria Doe</h5>

                                    <div class="md-form ml-0 mr-0">
                                        <input type="password" id="form29" class="form-control ml-0" mdbInput>
                                        <label for="form29" class="ml-0">Enter password</label>
                                    </div>

                                    <div class="text-center">
                                        <button mdbBtn color="cyan" class="mt-1 waves-effect waves-light">Unlock
                                            <mdb-icon fas icon="sign-in-alt" class="ml-1"></mdb-icon>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</header>
