<section class="view intro intro-2">
  <div class="full-bg-img mask rgba-gradient rgba-indigo-slight">
    <div class="container py-5">
      <!--Grid row-->
      <div class="row  pt-5 mt-3">
        <!--Grid column-->
        <div class="col-md-12">
          <mdb-card>
            <mdb-card-body>
              <h2 class="feature-title text-center mb-5 mt-4 font-bold">
                <strong>REGISTER</strong>
              </h2>
              <hr />

              <!--Grid row-->
              <div class="row mt-5">
                <!--Grid column-->
                <div class="col-md-6 ml-lg-5 ml-md-3 features-small">
                  <!--Grid row-->
                  <div class="row pb-3">
                    <div class="col-2 col-lg-1">
                      <mdb-icon
                        fas
                        icon="university"
                        size="lg"
                        class="indigo-text"
                      ></mdb-icon>
                    </div>
                    <div class="col-10">
                      <h4 class="feature-title"><strong>Safety</strong></h4>
                      <p class="dark-grey-text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Reprehenderit maiores nam, aperiam minima
                        assumenda deleniti hic.
                      </p>
                    </div>
                  </div>
                  <!--Grid row-->

                  <!--Grid row-->
                  <div class="row pb-3">
                    <div class="col-2 col-lg-1">
                      <mdb-icon
                        fas
                        icon="desktop"
                        size="lg"
                        class="deep-purple-text"
                      ></mdb-icon>
                    </div>
                    <div class="col-10">
                      <h4 class="feature-title"><strong>Technology</strong></h4>
                      <p class="dark-grey-text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Reprehenderit maiores nam, aperiam minima
                        assumenda deleniti hic.
                      </p>
                    </div>
                  </div>
                  <!--Grid row-->

                  <!--Grid row-->
                  <div class="row pb-4">
                    <div class="col-2 col-lg-1">
                      <mdb-icon
                        fas
                        icon="money-bill-alt"
                        size="lg"
                        class="purple-text"
                      ></mdb-icon>
                    </div>
                    <div class="col-10">
                      <h4 class="feature-title"><strong>Finance</strong></h4>
                      <p class="dark-grey-text">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Reprehenderit maiores nam, aperiam minima
                        assumenda deleniti hic.
                      </p>
                    </div>
                  </div>
                  <!--Grid row-->
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-md-5">
                  <!--Grid row-->
                  <div class="row pb-4 d-flex justify-content-center mb-4">
                    <h4 class="mt-3 mr-4"><strong>Login with</strong></h4>

                    <div
                      class="inline-ul text-center d-flex justify-content-center"
                    >
                      <a class="icons-sm tw-ic px-2 mt-3"
                        ><mdb-icon
                          fab
                          icon="twitter"
                          size="lg"
                          class="indigo-text"
                        ></mdb-icon
                      ></a>
                      <a class="icons-sm li-ic px-2 mt-3"
                        ><mdb-icon
                          fab
                          icon="linkedin-in"
                          size="lg"
                          class="indigo-text"
                        >
                        </mdb-icon
                      ></a>
                      <a class="icons-sm ins-ic px-2 mt-3"
                        ><mdb-icon
                          fab
                          icon="instagram"
                          size="lg"
                          class="indigo-text"
                        >
                        </mdb-icon
                      ></a>
                    </div>

                    <h4 class="mt-3 ml-4"><strong>or:</strong></h4>
                  </div>
                  <!--/Grid row-->

                  <!--Body-->
                  <div class="md-form">
                    <mdb-icon fas icon="user" class="dark-grey-text"></mdb-icon>
                    <input
                      type="text"
                      id="orangeForm-name"
                      class="form-control"
                      mdbInput
                    />
                    <label for="orangeForm-name">Your name</label>
                  </div>
                  <div class="md-form">
                    <mdb-icon
                      far
                      icon="envelope"
                      class="dark-grey-text"
                    ></mdb-icon>
                    <input
                      type="text"
                      id="orangeForm-email"
                      class="form-control"
                      mdbInput
                    />
                    <label for="orangeForm-email">Your email</label>
                  </div>

                  <div class="md-form">
                    <mdb-icon fas icon="lock" class="dark-grey-text"></mdb-icon>
                    <input
                      type="password"
                      id="orangeForm-pass"
                      class="form-control"
                      mdbInput
                    />
                    <label for="orangeForm-pass">Your password</label>
                  </div>

                  <div class="text-center">
                    <button
                      mdbBtn
                      color="indigo"
                      rounded="true"
                      class="mt-3 waves-effect waves-light"
                      mdbWavesEffect
                    >
                      Sign up
                    </button>
                  </div>
                </div>
                <!--Grid column-->
              </div>
              <!--Grid row-->
            </mdb-card-body>
          </mdb-card>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
  </div>
</section>
