
<!--Double navigation-->
<header>
        
  <!-- Sidebar navigation -->
  <mdb-sidenav #sidenav class="sn-bg-1 fixed" [fixed]="true">

  <logo>
      <!-- Logo -->
      <li>
          <div class="logo-wrapper waves-light">
              <a href="#"><img src="http://mdbootstrap.com/img/logo/mdb-transparent.png" class="img-fluid flex-center"></a>
          </div>
      </li>
      <!--/. Logo -->
  </logo>

  <links>
      <!--Social-->
      <li>
          <ul class="social">
              <li><a class="icons-sm fb-ic"><mdb-icon fab icon="facebook-f"></mdb-icon></a></li>
              <li><a class="icons-sm pin-ic"><mdb-icon fab icon="pinterest-p"> </mdb-icon></a></li>
              <li><a class="icons-sm gplus-ic"><mdb-icon fab icon="google-plus-g"></mdb-icon></a></li>
              <li><a class="icons-sm tw-ic"><mdb-icon fab icon="twitter"></mdb-icon></a></li>
          </ul>
      </li>
      <!--/Social-->
      <!--Search Form-->
      <li>
          <form class="search-form" role="search">
              <div class="form-group waves-light" mdbWavesEffect>
                  <input type="text" class="form-control" placeholder="Search">
              </div>
          </form>
      </li>
      <!--/.Search Form-->
      <!-- Side navigation links -->
      <li>
          <ul class="collapsible collapsible-accordion">
              <mdb-squeezebox [multiple]="false" aria-multiselectable="false">

                  <!-- Collapsible link -->
                  <mdb-item>
                      <mdb-item-head mdbWavesEffect><mdb-icon fas icon="chevron-right"></mdb-icon> Collapsible menu</mdb-item-head>
                      <mdb-item-body>
                          <ul>
                              <li><a href="#" class="waves-effect" mdbWavesEffect>Link 1</a></li>
                              <li><a href="#" class="waves-effect" mdbWavesEffect>Link 2</a></li>
                          </ul>
                      </mdb-item-body>
                  </mdb-item>
  
                  <!-- Simple link -->
                  <mdb-item class="no-collase">
                      <mdb-item-head mdbWavesEffect><mdb-icon far icon="hand-pointer-o"></mdb-icon> Simple link</mdb-item-head>
                      <mdb-item-body></mdb-item-body>
                  </mdb-item>
  
                  <!-- Collapsible link -->
                  <mdb-item>
                      <mdb-item-head mdbWavesEffect><mdb-icon far icon="eye"></mdb-icon> Collapsible menu 2</mdb-item-head>
                      <mdb-item-body>
                          <ul>
                              <li><a href="#" class="waves-effect" mdbWavesEffect>Link 1</a></li>
                              <li><a href="#" class="waves-effect" mdbWavesEffect>Link 2</a></li>
                          </ul>
                      </mdb-item-body>
                  </mdb-item>
  
                      <!-- Simple link -->
                      <mdb-item class="no-collase">
                      <mdb-item-head mdbWavesEffect><mdb-icon far icon="gem"></mdb-icon> Simple link 2</mdb-item-head>
                      <mdb-item-body></mdb-item-body>
                  </mdb-item>
                  
              </mdb-squeezebox>
          </ul>
      </li>
      <!--/. Side navigation links -->
      </links>
      
  </mdb-sidenav>
  <div class="sidenav-bg mask-strong"></div>
  <!--/. Sidebar navigation -->

      <!-- Navbar -->
  <mdb-navbar SideClass="navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav" [containerInside]="false">

  <navlinks class="navbar-container">
      <!-- SideNav slide-out button -->
      <div class="float-left">
          <a (click)="sidenav.show()" class="button-collapse"><mdb-icon fas icon="bars"></mdb-icon></a>
      </div>
      <!--/. SideNav slide-out button -->
  </navlinks>

  <logo>
      <!-- Breadcrumb-->
      <div class="breadcrumbs breadcrumb-dn mr-auto">
          <p>Material Design for Bootstrap</p>
      </div>
      <!--/. Breadcrumb-->
  </logo>   

  <navlinks>
      <ul class="nav navbar-nav nav-flex-icons ml-auto ie-double-nav">
          <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect><mdb-icon far icon="envelope"></mdb-icon> <span class="clearfix d-none d-sm-inline-block">Contact</span></a>
      </li>
      <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect><mdb-icon far icon="comments"></mdb-icon> <span class="clearfix d-none d-sm-inline-block">Support</span></a>
      </li>
      <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect><mdb-icon fas icon="user"></mdb-icon> <span class="clearfix d-none d-sm-inline-block">Account</span></a>
      </li>
      <li class="nav-item dropdown btn-group" dropdown>
          <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
              Dropdown
          </a>
          <div class="dropdown-menu dropdown-primary dropdown-menu-right" role="menu">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
          </div>
      </li>
      </ul>
  </navlinks>
  </mdb-navbar>
      <!--/. Navbar -->

</header>
<!--/.Double navigation-->

<!--Main Layout-->
<main>
  <div class="container-fluid mt-5">
      <h2>Advanced Double Navigation with fixed SideNav & fixed Navbar:</h2>
      <br>
      <h5>1. Hidden side menu. Click "hamburger" icon in the top left corner to open it.</h5>
      <h5>2. Fixed navbar. It will always stay visible on the top, even when you scroll down.</h5>
      <div style="height: 2000px"></div>
  </div>
</main>
<!--/Main layout-->
