
<!--Second row-->
<div class="row mt-5">
  <div class="col-md-12">
    <!--Card-->
    <div class="card card-cascade narrower mt-5">
        
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
          <h4 class="h4-responsive">Icons</h4>
      </div>
      <!--/Card image-->

      <!--Card content-->
      <div class="card-body card-body-cascade text-center">
          <!--Icons-->
          <div class="example" id="ex1">                

      </div>
      <!--Icons-->
      <div class="row justify-content-center pt-3">
          <div class="col-md-10">
            <!--Section: Docs link-->
            <section class="pb-4 pt-5">
              <panel header="Full documentation" color="primary-color">
                <p class="card-text">Read the full documentation for these components.</p>
                <a href="https://mdbootstrap.com/angular/content/icons-list/" target="_blank" class="btn btn-primary waves-effect waves-light">Learn more</a>
              </panel>
            </section>
            <!--/.Section: Docs link-->
          </div>
        </div>
      </div>
      <!--/.Card content-->
    </div>
    <!--/.Card-->
  </div>
</div>
