<main>
	<div class="container py-5">
		<section class="section section-blog-fw mt-5 pb-3 wow fadeIn" style="animation-name: none; visibility: visible;">

		    <!--Grid row-->
		    <div class="row my-5">
		        <div class="col-md-12">
		            <!--Featured image-->
		            <mdb-card cascade="true" wider="true" reverse="true">
		                <div class="view view-cascade overlay hm-white-slight">
		                    <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(134).jpg" alt="Wide sample post image">
		                    <a>
		                        <div class=""></div>
		                    </a>
		                </div>

		                <!--Post data-->
		                <mdb-card-body cascade="true" class="text-center">
		                    <h2><a><strong>This is title of the news</strong></a></h2>
		                    <p>Written by <a>Abby Madison</a>, 26/08/2016</p>

		                    <!--Social shares-->
		                    <div class="social-counters ">

		                        <!--Facebook-->
		                        <a mdbBtn color="fb" class="waves-effect waves-light">
		                            <mdb-icon fab icon="facebook-f" class="left mr-1"></mdb-icon>
		                            <span class="hidden-md-down ">Facebook</span>
		                        </a>
		                        <span class="counter ">46</span>

		                        <!--Twitter-->
		                        <a mdbBtn color="tw" class="waves-effect waves-light">
		                            <mdb-icon fab icon="twitter" class="left mr-1"></mdb-icon>
		                            <span class="hidden-md-down ">Twitter</span>
		                        </a>
		                        <span class="counter ">22</span>

		                        <!--Dribbble-->
		                        <a mdbBtn color="dribbble" class="waves-effect waves-light">
		                            <mdb-icon fab icon="dribbble" class="left mr-1"></mdb-icon>
		                            <span class="hidden-md-down ">Dribbble</span>
		                        </a>
		                        <span class="counter ">31</span>

		                        <!--Comments-->
		                        <a mdbBtn color="blue-grey" class="waves-effect waves-light">
		                            <mdb-icon far icon="comments" class="left mr-1"></mdb-icon>
		                            <span class="hidden-md-down ">Comments</span>
		                        </a>
		                        <span class="counter ">18</span>

		                    </div>
		                    <!--Social shares-->

		                </mdb-card-body>
		                <!--Post data-->
		            </mdb-card>

		            <!--Excerpt-->
		            <div class="excerpt mt-5 wow fadeIn" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">
		                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
		                    deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate
		                    non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum
		                    et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
		                </p>

		                <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod
		                    maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus
		                    autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates
		                    repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus,
		                    ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus.</p>

		                <blockquote class="blockquote mt-4 mb-4">
		                    <p class="mb-0"><em>Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus.</em></p>
		                    <footer class="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>
		                </blockquote>


		                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
		                    totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
		                    vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
		                    aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem.
		                </p>

		                <p>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
		                    sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
		                    voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
		                    laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit
		                    qui in ea voluptate velit esse quam nihil molestiae consequatur.</p>


		                <div class="mt-4 d-flex justify-content-end">
		                    <mdb-badge color="pink">Travel</mdb-badge>
		                    <mdb-badge primary="true" class="mx-1">Adventure</mdb-badge>
		                    <mdb-badge color="grey" class="mr-1">Photography</mdb-badge>
		                    <mdb-badge info="true">Education</mdb-badge>
		                </div>

		            </div>
		        </div>
		    </div>
		    <!--Grid row-->

		</section>

		<hr class="mb-5 mt-4">

		<section class="section extra-margins text-center pb-3 wow fadeIn" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">

            <!--Section heading-->
            <h3 class="section-heading h3 pt-3">Recent posts</h3>

            <!--Grid row-->
            <div class="row">

                <!--Grid column-->
                <div class="col-lg-4 col-md-12 my-5">

                    <!--Card Light-->
                    <mdb-card>
                        <!--Card image-->
                        <div class="view overlay rgba-white-slight">
                            <img src="https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20%28147%29.jpg" class="img-fluid" alt="">
                            <a>
                                <div class="mask waves-effect waves-light"></div>
                            </a>
                        </div>
                        <!--/.Card image-->
                        <!--Card content-->
                        <mdb-card-body>
                            <!--Social shares button-->
                            <a class="activator"><mdb-icon fas icon="share-alt" class="pr-3"></mdb-icon></a>
                            <!--Title-->
                            <h4 class="card-title">Card title</h4>
                            <hr>
                            <!--Text-->
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="#" class="black-text d-flex flex-row-reverse">
                                <h5 class="waves-effect p-2">Read more <mdb-icon fas icon="chevron-right"></mdb-icon></h5>
                            </a>
                        </mdb-card-body>
                        <!--/.Card content-->
                    </mdb-card>
                    <!--/.Card Light-->

                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-4 col-md-6 my-5">

                    <!--Card Light-->
                    <mdb-card>
                        <!--Card image-->
                        <div class="view overlay rgba-white-slight">
                            <img src="https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20%2850%29.jpg" class="img-fluid" alt="">
                            <a>
                                <div class="mask waves-effect waves-light"></div>
                            </a>
                        </div>
                        <!--/.Card image-->
                        <!--Card content-->
                        <mdb-card-body>
                            <!--Social shares button-->
                            <a class="activator"><mdb-icon fas icon="share-alt" class="pr-3"></mdb-icon></a>
                            <!--Title-->
                            <h4 class="card-title">Card title</h4>
                            <hr>
                            <!--Text-->
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="#" class="black-text d-flex flex-row-reverse">
                                <h5 class="waves-effect p-2">Read more <mdb-icon fas icon="chevron-right"></mdb-icon></h5>
                            </a>
                        </mdb-card-body>
                        <!--/.Card content-->
                    </mdb-card>
                    <!--/.Card Light-->

                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-4 col-md-6 my-5">

                    <!--Card Light-->
                    <mdb-card>
                        <!--Card image-->
                        <div class="view overlay rgba-white-slight">
                            <img src="https://mdbootstrap.com/img/Photos/Lightbox/Original/img%20%2897%29.jpg" class="img-fluid" alt="">
                            <a>
                                <div class="mask waves-effect waves-light"></div>
                            </a>
                        </div>
                        <!--/.Card image-->
                        <!--Card content-->
                        <mdb-card-body>
                            <!--Social shares button-->
                            <a class="activator"><mdb-icon fas icon="share-alt" class="pr-3"></mdb-icon></a>
                            <!--Title-->
                            <h4 class="card-title">Card title</h4>
                            <hr>
                            <!--Text-->
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="#" class="black-text d-flex flex-row-reverse">
                                <h5 class="waves-effect p-2">Read more <mdb-icon fas icon="chevron-right"></mdb-icon></h5>
                            </a>
                        </mdb-card-body>
                        <!--/.Card content-->
                    </mdb-card>
                    <!--/.Card Light-->

                </div>
                <!--Grid column-->

            </div>
            <!--Grid row-->

        </section>

        <section>

            <div class="author-box mt-5 py-4 wow fadeIn" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">
                <!--Name-->
                <h4 class="section-heading h4 text-center">About author</h4>
                <hr>
                <div class="row">
                    <!--Avatar-->
                    <div class="col-12 col-sm-2">
                        <img src="http://mdbootstrap.com/img/Photos/Avatars/img%20(32).jpg" class="img-fluid rounded-circle z-depth-2">
                    </div>
                    <!--Author Data-->
                    <div class=" col-12 col-sm-10 text-left">
                        <p><strong>John Doe</strong></p>
                        <div class="personal-sm">
                            <a class="fb-ic"><mdb-icon fab icon="facebook-f"></mdb-icon></a>
                            <a class="tw-ic"><mdb-icon fab icon="twitter"></mdb-icon></a>
                            <a class="gplus-ic"><mdb-icon fab icon="google-plus-g"> </mdb-icon></a>
                            <a class="li-ic"><mdb-icon fab icon="linkedin-in"> </mdb-icon></a>
                        </div>
                        <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.</p>
                        <p class="hidden-md-down">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint esse nulla quia quam veniam commodi
                            dicta, iusto inventore. Voluptatum pariatur eveniet ea, officiis vitae praesentium beatae
                            quas libero, esse facere.
                        </p>
                    </div>
                </div>
            </div>

        </section>

        <section class="mb-4 pt-5 wow fadeIn" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">

            <!--Main wrapper-->
            <div class="comments-list text-md-left text-center">
                <div class="section-heading">
                    <h3 class="section-heading h3 pt-3 mb-5">Comments <span class="badge pink">3</span></h3>
                </div>
                <!--First row-->
                <div class="row">
                    <!--Image column-->
                    <div class="col-sm-2 col-12">
                        <img src="https://mdbootstrap.com/img/Photos/Avatars/img (9).jpg" class="avatar rounded-circle img-fluid z-depth-2">
                    </div>
                    <!--/.Image column-->

                    <!--Content column-->
                    <div class="col-sm-10 col-12">
                        <a>
                            <h5 class="user-name font-bold">Tom Smith</h5>
                        </a>
                        <div class="card-data">
                            <ul class="list-unstyled">
                                <li class="comment-date"><mdb-icon far icon="clock"></mdb-icon> 05/10/2015</li>
                            </ul>
                        </div>
                        <p class="comment-text">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                            fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                    </div>
                    <!--/.Content column-->

                </div>
                <!--/.First row-->

                <!--Second row-->
                <div class="row">
                    <!--Image column-->
                    <div class="col-sm-2 col-12">
                        <img src="https://mdbootstrap.com/img/Photos/Avatars/img (31).jpg" class="avatar rounded-circle img-fluid z-depth-2">
                    </div>
                    <!--/.Image column-->

                    <!--Content column-->
                    <div class="col-sm-10 col-12">
                        <a>
                            <h5 class="user-name font-bold">Marta Tev</h5>
                        </a>
                        <div class="card-data">
                            <ul class="list-unstyled">
                                <li class="comment-date"><mdb-icon far icon="clock"></mdb-icon> 08/10/2015</li>
                            </ul>
                        </div>
                        <p class="comment-text">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
                            vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur.</p>
                    </div>
                    <!--/.Content column-->

                </div>
                <!--/.Second row-->

                <!--Third row-->
                <div class="row">

                    <!--Image column-->
                    <div class="col-sm-2 col-12">
                        <img src="https://mdbootstrap.com/img/Photos/Avatars/img (10).jpg" class="avatar rounded-circle img-fluid z-depth-2">
                    </div>
                    <!--/.Image column-->
                    <!--Content column-->
                    <div class="col-sm-10 col-12">
                        <a>
                            <h5 class="user-name font-bold">Anna Maria</h5>
                        </a>
                        <div class="card-data">
                            <ul class="list-unstyled">
                                <li class="comment-date"><mdb-icon far icon="clock"></mdb-icon> 17/10/2015</li>
                            </ul>
                        </div>
                        <p class="comment-text">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                            deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate
                            non provident, similique sunt in culpa qui officia.
                        </p>
                    </div>
                    <!--/.Content column-->
                </div>
                <!--/.Third row-->
            </div>
            <!--/.Main wrapper-->

        </section>

        <section class="pb-5 mt-5 wow fadeIn" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">

	        <!--Leave a reply form-->
	        <div class="reply-form">
	            <h3 class="section-heading h3 pt-3">Leave a reply </h3>
	            <p class="text-center">(Logged In User)</p>

	            <!--Third row-->
	            <div class="row">
	                <!--Image column-->
	                <div class="col-sm-2 col-12">
	                    <img src="https://mdbootstrap.com/img/Photos/Avatars/img (32).jpg" alt="Sample avatar image" class="avatar rounded-circle img-fluid z-depth-2">
	                </div>
	                <!--/.Image column-->

	                <!--Content column-->
	                <div class="col-sm-10 col-12">
	                    <div class="md-form">
	                        <textarea type="text" id="form-mess" class="md-textarea form-control" mdbInput></textarea>
	                        <label for="form-mess">Your message</label>
	                    </div>

	                </div>

	                <div class="col-md-12 text-center">
	                    <button mdbBtn color="pink" rounded="true" class="waves-effect waves-light">Submit</button>
	                </div>
	                <!--/.Content column-->

	            </div>
	            <!--/.Third row-->
	        </div>
	        <!--/.Leave a reply form-->

	    </section>
	</div>
</main>

<footer class="page-footer center-on-small-only pt-0">

    <!--Footer Links-->
    <div class="container">

        <!--First row-->
        <div class="row">

            <!--First column-->
            <div class="col-md-12 wow fadeIn" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">

                <div class="text-center d-flex justify-content-center my-4">

                    <!--Facebook-->
                    <a class="icons-sm fb-ic px-2"><mdb-icon fab icon="facebook-f" size="lg" class="white-text pr-md-4"> </mdb-icon></a>
                    <!--Twitter-->
                    <a class="icons-sm tw-ic px-2"><mdb-icon fab icon="twitter" size="lg" class="white-text pr-md-4"> </mdb-icon></a>
                    <!--Google +-->
                    <a class="icons-sm gplus-ic px-2"><mdb-icon fab icon="google-plus-g" size="lg" class="white-text pr-md-4"> </mdb-icon></a>
                    <!--Linkedin-->
                    <a class="icons-sm li-ic px-2"><mdb-icon fab icon="linkedin-in" size="lg" class="white-text pr-md-4"> </mdb-icon></a>
                    <!--Instagram-->
                    <a class="icons-sm ins-ic px-2"><mdb-icon fab icon="instagram" size="lg" class="white-text pr-md-4"> </mdb-icon></a>
                    <!--Pinterest-->
                    <a class="icons-sm pin-ic px-2"><mdb-icon fab icon="pinterest-p" size="lg" class="white-text pr-md-4"> </mdb-icon></a>
                </div>

            </div>
            <!--/First column-->

        </div>
        <!--/First row-->

    </div>
    <!--/Footer Links-->

    <!--Copyright-->
    <div class="footer-copyright wow fadeIn text-center py-3" data-wow-delay="0.3s" style="animation-name: none; visibility: visible;">
        <div class="container-fluid">
            © 2016 Copyright: <a href="https://www.MDBootstrap.com"> MDBootstrap.com </a>

        </div>
    </div>
    <!--/Copyright-->

</footer>
