<div class="card p-4">
  <div class="row text-center">
    <div class="col-md-4">
      <div class="btn-group d-flex flex-wrap flex-xl-nowrap">
        <div
          mdbBtn
          color="primary"
          class="m-1"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="activeDayIsOpen = false"
        >
          Previous
        </div>
        <div
          mdbBtn
          color="primary"
          class="m-1"
          outline="true"
          mwlCalendarToday
          [(viewDate)]="viewDate"
        >
          Today
        </div>
        <div
          mdbBtn
          color="primary"
          class="m-1"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="activeDayIsOpen = false"
        >
          Next
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h3>{{ viewDate | calendarDate: view + 'ViewTitle':'en' }}</h3>
    </div>
    <div class="col-md-4">
      <div class="btn-group d-flex flex-wrap flex-xl-nowrap">
        <div
          mdbBtn
          color="primary"
          class="m-1"
          (click)="view = 'month'"
          [class.active]="view === 'month'"
        >
          Month
        </div>
        <div
          mdbBtn
          color="primary"
          class="m-1"
          (click)="view = 'week'"
          [class.active]="view === 'week'"
        >
          Week
        </div>
        <div
          mdbBtn
          color="primary"
          class="m-1"
          (click)="view = 'day'"
          [class.active]="view === 'day'"
        >
          Day
        </div>
      </div>
    </div>
  </div>
  <br />
  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="'month'"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="'week'"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="'day'"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-day-view>
  </div>
</div>
