<h2 class="mt-5 font-bold"><strong>Media object</strong></h2>

<!--Section: Example-->
<section class="mb-5 pb-5">

    <h4 class="my-5 dark-grey-text font-bold"><strong>Example</strong></h4>

    <!--Card-->
    <div class="card">

        <!--Card content-->
        <div class="card-body">
            <div class="media ml-3">
                <img class="d-flex mr-3 z-depth-1" src="https://mdbootstrap.com/img/Photos/Othres/placeholder1.jpg" alt="Generic placeholder image">
                <div class="media-body">
                    <h5 class="mt-0"><strong>Media heading</strong></h5>
                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in
                    vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla.
                    Donec lacinia congue felis in faucibus.
                </div>
            </div>
        </div>

    </div>
    <!--/.Card-->

</section>
<!--Section: Example-->

<!--Section: Order-->
<section class="mb-5 pb-5">

    <h4 class="my-5 dark-grey-text font-bold"><strong>Order</strong></h4>

    <!--Card-->
    <div class="card">

        <!--Card content-->
        <div class="card-body">
            <div class="media">
                <div class="media-body">
                    <h5 class="mt-0 mb-1"><strong>Media object</strong></h5>
                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in
                    vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla.
                    Donec lacinia congue felis in faucibus.
                </div>
                <img class="d-flex ml-3 z-depth-1" src="https://mdbootstrap.com/img/Photos/Othres/placeholder1.jpg" alt="Generic placeholder image">
            </div>
        </div>

    </div>
    <!--/.Card-->

</section>
<!--Section: Order-->

<!--Section: Nesting-->
<section class="mb-5 pb-5">

    <h4 class="my-5 dark-grey-text font-bold"><strong>Nesting</strong></h4>

    <!--Card-->
    <div class="card">

        <!--Card content-->
        <div class="card-body">
            <div class="media">
                <img class="d-flex mr-3 rounded-circle z-depth-1" src="https://mdbootstrap.com/img/Photos/Othres/avatar-min1.jpg" alt="Generic placeholder image">
                <div class="media-body">
                    <h5 class="mt-0"><strong>Media heading</strong></h5>
                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in
                    vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla.
                    Donec lacinia congue felis in faucibus.

                    <div class="media mt-4">
                        <a class="d-flex pr-3" href="#">
                            <img src="https://mdbootstrap.com/img/Photos/Othres/avatar-min2.jpg" alt="Generic placeholder image" class="rounded-circle z-depth-1">
                        </a>
                        <div class="media-body">
                            <h5 class="mt-0"><strong>Media heading</strong></h5>
                            Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in
                            vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla.
                            Donec lacinia congue felis in faucibus.
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!--/.Card-->

</section>
<!--Section: Nesting-->

<!--Section: Alignment-->
<section class="mb-5 pb-5">

    <h4 class="my-5 dark-grey-text font-bold"><strong>Alignment</strong></h4>
    <!--Card-->
    <div class="card mb-5">

        <!--Card content-->
        <div class="card-body">
            <div class="media">
                <img class="d-flex align-self-start mr-3 z-depth-1" src="https://mdbootstrap.com/img/Photos/Othres/placeholder6.jpg" alt="Generic placeholder image">
                <div class="media-body">
                    <h5 class="mt-0"><strong>Top-aligned media</strong></h5>
                    <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.
                        Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum
                        nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                    <p>Donec sed odio dui. Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque
                        penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                </div>
            </div>
        </div>

    </div>
    <!--/.Card-->

    <!--Card-->
    <div class="card mb-5">

        <!--Card content-->
        <div class="card-body">
            <div class="media">
                <img class="d-flex align-self-center mr-3 z-depth-1" src="https://mdbootstrap.com/img/Photos/Othres/placeholder6.jpg" alt="Generic placeholder image">
                <div class="media-body">
                    <h5 class="mt-0"><strong>Center-aligned media</strong></h5>
                    <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.
                        Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum
                        nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                    <p class="mb-0">Donec sed odio dui. Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque
                        penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                </div>
            </div>
        </div>

    </div>
    <!--/.Card-->


    <!--Card-->
    <div class="card">

        <!--Card content-->
        <div class="card-body">
            <div class="media">
                <img class="d-flex align-self-end mr-3 z-depth-1" src="https://mdbootstrap.com/img/Photos/Othres/placeholder6.jpg" alt="Generic placeholder image">
                <div class="media-body">
                    <h5 class="mt-0"><strong>Bottom-aligned media</strong></h5>
                    <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.
                        Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum
                        nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                    <p class="mb-0">Donec sed odio dui. Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque
                        penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                </div>
            </div>
        </div>

    </div>
    <!--/.Card-->

</section>
<!--Section: Alignment-->

<!--Section: Media list-->
<section class="mb-5">

    <h4 class="my-5 dark-grey-text font-bold"><strong>Media list</strong></h4>

    <!--Card-->
    <div class="card">

        <!--Card content-->
        <div class="card-body">
            <ul class="list-unstyled">
                <li class="media">
                    <img class="d-flex mr-3 z-depth-1" src="https://mdbootstrap.com/img/Photos/Othres/placeholder7.jpg" alt="Generic placeholder image">
                    <div class="media-body">
                        <h5 class="mt-0 mb-1"><strong>List-based media object</strong></h5>
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in
                        vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla.
                        Donec lacinia congue felis in faucibus.
                    </div>
                </li>
                <li class="media my-5">
                    <img class="d-flex mr-3 z-depth-1" src="https://mdbootstrap.com/img/Photos/Othres/placeholder7.jpg" alt="Generic placeholder image">
                    <div class="media-body">
                        <h5 class="mt-0 mb-1"><strong>List-based media object</strong></h5>
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in
                        vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla.
                        Donec lacinia congue felis in faucibus.
                    </div>
                </li>
                <li class="media">
                    <img class="d-flex mr-3 z-depth-1" src="https://mdbootstrap.com/img/Photos/Othres/placeholder7.jpg" alt="Generic placeholder image">
                    <div class="media-body">
                        <h5 class="mt-0 mb-1"><strong>List-based media object</strong></h5>
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in
                        vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla.
                        Donec lacinia congue felis in faucibus.
                    </div>
                </li>
            </ul>
        </div>

    </div>
    <!--/.Card-->

</section>
<!--Section: Media list-->
