<div class="map-wrapper mt-5">

  <agm-map [latitude]="map.lat" [longitude]="map.lng" style="height: 100vh ">
    <agm-marker [latitude]="map.lat" [longitude]="map.lng"></agm-marker>
  </agm-map>
</div>
<div class="row justify-content-center">
  <div class="col-md-10">
    <!--Section: Docs link-->
    <section class="pb-4 pt-5">
      <panel header="Full documentation" color="primary-color">
        <p class="card-text">Read the full documentation for these components.</p>
        <a href="https://mdbootstrap.com/angular/components/maps/" target="_blank" mdbBtn color="primary" class="waves-effect waves-light">Learn more</a>
      </panel>
    </section>
    <!--/.Section: Docs link-->
  </div>
</div>
