<div class="row mt-5">
    <div class="col-md-12">
        <!--Card-->

        <!--Card image-->
        <!--/Card image-->
        <!--Card content-->
        <h3 class="my-5 dark-grey-text font-bold mt-4">
            <strong>Cascading cards</strong>
        </h3>
        <div class="row pb-4">
            <div class="col-md-4">
                <h4 class="mb-4 dark-grey-text font-bold">
                    <strong>Wider</strong>
                </h4>
                <!--Card-->
                <mdb-card cascade="true" wider="true" class="mt-4">
                    <!--Card image-->
                    <div class="view view-cascade overlay hm-white-slight waves-light" mdbWavesEffect>
                        <img src="https://mdbootstrap.com/img/Photos/Horizontal/People/6-col/img%20%283%29.jpg"
                             class="img-fluid">
                        <a>
                            <div class="mask"></div>
                        </a>
                    </div>
                    <!--/Card image-->
                    <!--Card content-->
                    <mdb-card-body cascade="true" class="text-center">
                        <!--Title-->
                        <h4 class="card-title mb-3 font-bold">
                            <strong>Alice Mayer</strong>
                        </h4>
                        <h5 class="indigo-text">
                            <strong>Photographer</strong>
                        </h5>
                        <p class="card-text">Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium
                            doloremque laudantium, totam rem aperiam.
                        </p>
                        <!--Linkedin-->
                        <a class="icons-sm li-ic px-2">
                            <mdb-icon fab icon="linkedin-in"></mdb-icon>
                        </a>
                        <!--Twitter-->
                        <a class="icons-sm tw-ic px-2">
                            <mdb-icon fab icon="twitter"></mdb-icon>
                        </a>
                        <!--Dribbble-->
                        <a class="icons-sm fb-ic px-2">
                            <mdb-icon fab icon="facebook-f"></mdb-icon>
                        </a>
                    </mdb-card-body>
                    <!--/.Card content-->
                </mdb-card>
                <!--/.Card-->
            </div>
            <div class="col-md-4">
                <h4 class="mb-4 dark-grey-text font-bold mb-4">
                    <strong>Narrower</strong>
                </h4>
                <!--Card-->
                <mdb-card cascade="true" narrower="true">
                    <!--Card image-->
                    <div class="view view-cascade overlay hm-white-slight waves-light" mdbWavesEffect>
                        <img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(147).jpg"
                             class="img-fluid" alt="">
                        <a>
                            <div class="mask"></div>
                        </a>
                    </div>
                    <!--/.Card image-->
                    <!--Card content-->
                    <mdb-card-body cascade="true">
                        <h5 class="pink-text">
                            <mdb-icon fas icon="utensils"></mdb-icon>
                            Culinary
                        </h5>
                        <!--Title-->
                        <h4 class="card-title mb-3">Cheat day inspirations</h4>
                        <!--Text-->
                        <p class="card-text">Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
                            suscipit laboriosam, nisi ut aliquid ex
                            ea commodi.</p>
                        <a mdbBtn color="unique" class="waves-light" mdbWavesEffect>Button</a>
                    </mdb-card-body>
                    <!--/.Card content-->
                </mdb-card>
                <!--/.Card-->
            </div>
            <div class="col-md-4">
                <h4 class="mb-4 dark-grey-text font-bold">
                    <strong>Regular</strong>
                </h4>
                <!--Card-->
                <mdb-card cascade="true" class="mt-4">
                    <!--Card image-->
                    <div class="view view-cascade overlay hm-white-slight waves-light" mdbWavesEffect>
                        <img src="https://mdbootstrap.com/img/Photos/Others/men.jpg" class="img-fluid" alt="">
                        <a>
                            <div class="mask"></div>
                        </a>
                    </div>
                    <!--/.Card image-->
                    <!--Card content-->
                    <mdb-card-body cascade="true" class="text-center">
                        <!--Title-->
                        <h4 class="card-title mb-3">
                            <strong>Billy Cullen</strong>
                        </h4>
                        <h5>Web developer</h5>
                        <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus, ex,
                            recusandae. Facere modi sunt, quod
                            quibusdam.
                        </p>
                        <!--Facebook-->
                        <a type="button" mdbBtn floating="true" size="sm" color="fb" class="waves-light" mdbWavesEffect>
                            <mdb-icon fab icon="facebook-f"></mdb-icon>
                        </a>
                        <!--Twitter-->
                        <a type="button" mdbBtn floating="true" size="sm" color="tw" class="waves-light" mdbWavesEffect>
                            <mdb-icon fab icon="twitter"></mdb-icon>
                        </a>
                        <!--Google +-->
                        <a type="button" mdbBtn floating="true" size="sm" color="dribbble" class="waves-light"
                           mdbWavesEffect>
                            <mdb-icon fab icon="dribbble"></mdb-icon>
                        </a>
                    </mdb-card-body>
                    <!--/.Card content-->
                </mdb-card>
                <!--/.Card-->
            </div>
        </div>
        <!--Section: Cascading panels-->
        <section>
            <h3 class="my-5 dark-grey-text font-bold mt-4">
                <strong>Cascading panels</strong>
            </h3>
            <div class="row mb-5">
                <!--Grid column-->
                <div class="col-lg-4 col-md-12 my-5">
                    <!--Card-->
                    <mdb-card cascade="true" narrower="true">
                        <!--Card image-->
                        <div class="view view-cascade gradient-card-header purple-gradient">
                            <h2 class="h2-responsive">Mattonit</h2>
                            <p>The Boar</p>
                            <div class="text-center">
                                <!--Facebook-->
                                <a type="button" mdbBtn floating="true" size="sm" class="waves-effect waves-light">
                                    <mdb-icon fab icon="facebook-f"></mdb-icon>
                                </a>
                                <!--Twitter-->
                                <a type="button" mdbBtn floating="true" size="sm" class="waves-effect waves-light">
                                    <mdb-icon fab icon="twitter"></mdb-icon>
                                </a>
                                <!--Google +-->
                                <a type="button" mdbBtn floating="true" size="sm" class="waves-effect waves-light">
                                    <mdb-icon fab icon="google-plus-g"></mdb-icon>
                                </a>
                            </div>
                        </div>
                        <!--/Card image-->
                        <!--Card content-->
                        <mdb-card-body cascade="true" class="text-center">

                            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus,
                                ex, recusandae. Facere modi sunt, quod
                                quibusdam dignissimos neque rem nihil ratione est placeat vel, natus non quos laudantium
                                veritatis sequi.Ut
                                enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit
                                laboriosam.</p>

                        </mdb-card-body>
                        <!--/.Card content-->
                    </mdb-card>
                    <!--/.Card-->
                </div>
                <!--Grid column-->
                <!--Grid column-->
                <div class="col-lg-4 col-md-6 my-5">
                    <!--Card-->
                    <mdb-card cascade="true" wider="true" class="mt-4">
                        <!--Card image-->
                        <div class="view view-cascade gradient-card-header peach-gradient">
                            <h2 class="h2-responsive mb-2">Title of the news</h2>
                            <p class="">
                                <mdb-icon far icon="calendar-alt"></mdb-icon>
                                26.07.2017
                            </p>
                        </div>
                        <!--/Card image-->
                        <!--Card content-->
                        <mdb-card-body cascade="true" class="text-center">
                            <p class="card-text mr-2 ml-2 pb-1">Lorem ipsum dolor sit amet, consectetur adipisicing
                                elit. Voluptatibus, ex, recusandae. Facere modi sunt, quod
                                quibusdam dignissimos neque rem nihil ratione est placeat vel, natus non quos laudantium
                                veritatis sequi.Ut
                                enim ad minima veniam, quis nostrum.</p>

                            <a href="#" class="orange-text mt-1 d-flex flex-row-reverse">
                                <h5 class="waves-effect p-2">Read more
                                    <mdb-icon fas icon="chevron-right"></mdb-icon>
                                </h5>
                            </a>
                        </mdb-card-body>
                        <!--/.Card content-->
                    </mdb-card>
                    <!--/.Card-->
                </div>
                <!--Grid column-->
                <!--Grid column-->
                <div class="col-lg-4 col-md-6 my-5">
                    <!--Card-->
                    <mdb-card cascade="true" class="mt-4">
                        <!--Card image-->
                        <div class="view view-cascade gradient-card-header blue-gradient">
                            <h2 class="h2-responsive">Marta</h2>
                            <p>Deserve for her own card</p>
                        </div>
                        <!--/Card image-->
                        <!--Card content-->
                        <mdb-card-body cascade="true" class="text-center">

                            <p class="card-text pb-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                Voluptatibus, ex, recusandae. Facere modi sunt, quod
                                quibusdam dignissimos neque rem nihil ratione est placeat vel, natus non quos laudantium
                                veritatis sequi.Ut
                                enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.
                            </p>
                            <hr>
                            <!--Twitter-->
                            <a class="icons-sm tw-ic px-2">
                                <mdb-icon fab icon="twitter"></mdb-icon>
                            </a>
                            <!--Linkedin-->
                            <a class="icons-sm li-ic px-2">
                                <mdb-icon fab icon="linkedin-in"></mdb-icon>
                            </a>
                            <!--Facebook-->
                            <a class="icons-sm fb-ic px-2">
                                <mdb-icon fab icon="facebook-f"></mdb-icon>
                            </a>
                            <!--Email-->
                            <a class="icons-sm email-ic px-2">
                                <mdb-icon far icon="envelope"></mdb-icon>
                            </a>
                        </mdb-card-body>
                        <!--/.Card content-->
                    </mdb-card>
                    <!--/.Card-->
                </div>
                <!--Grid column-->
            </div>
        </section>
        <!--Section: Cascading panels-->

        <!--Section: Reverse cascade-->
        <section>
            <h3 class="mb-4 dark-grey-text font-bold">
                <strong>Reverse cascade</strong>
            </h3>
            <div class="row mb-5 pb-4">
                <!--Card-->
                <mdb-card cascade="true" wider="true" reverse="true" class="my-4 mx-3">
                    <!--Card image-->
                    <div class="view view-cascade overlay hm-white-slight">
                        <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(135).jpg" class="img-fluid">
                        <a href="#">
                            <div class="mask"></div>
                        </a>
                    </div>
                    <!--/Card image-->
                    <!--Card content-->
                    <mdb-card-body cascade="true" class="text-center">
                        <!--Title-->
                        <h4 class="card-title mb-4">
                            <strong>My adventure</strong>
                        </h4>
                        <h5 class="indigo-text">
                            <strong>Photography</strong>
                        </h5>

                        <p class="card-text">Sed ut perspiciatis unde omnis iste natus sit voluptatem accusantium
                            doloremque laudantium, totam rem aperiam.
                        </p>

                        <!--Linkedin-->
                        <a class="icons-sm li-ic px-2">
                            <mdb-icon fab icon="linkedin-in"></mdb-icon>
                        </a>
                        <!--Twitter-->
                        <a class="icons-sm tw-ic px-2">
                            <mdb-icon fab icon="twitter"></mdb-icon>
                        </a>
                        <!--Dribbble-->
                        <a class="icons-sm fb-ic px-2">
                            <mdb-icon fab icon="facebook-f"></mdb-icon>
                        </a>
                    </mdb-card-body>
                    <!--/.Card content-->
                </mdb-card>
                <!--/.Card-->
            </div>
        </section>
        <!--Section: Reverse cascade-->

        <!--Section: Additional cards-->
        <section class="mb-5">

            <div class="row">
                <!--Subheading-->
                <div class="col-md-4 my-5">
                    <h4 class="mb-4 font-bold dark-grey-text pb-4">
                        <strong>Waves effect</strong>
                    </h4>
                    <!--Card-->
                    <mdb-card>

                        <!--Card image-->
                        <div class="view overlay hm-white-slight">
                            <img src="https://mdbootstrap.com/img/Mockups/Lightbox/Thumbnail/img%20(67).jpg"
                                 class="img-fluid" alt="">
                            <a href="#!">
                                <div class="mask"></div>
                            </a>
                        </div>

                        <!--Card content-->
                        <mdb-card-body cascade="true">
                            <!--Title-->
                            <h4 class="card-title">Card title</h4>
                            <!--Text-->
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk
                                of the card's content.
                            </p>
                            <a mdbBtn color="primary" href="#">Button</a>
                        </mdb-card-body>
                    </mdb-card>
                    <!--/.Card-->
                </div>

                <div class="col-md-4 my-5">
                    <!--Subheading-->
                    <h4 class="mb-4 font-bold dark-grey-text">
                        <strong>Basic example</strong>
                    </h4>
                    <!--Card-->
                    <mdb-card>

                        <!--Card image-->
                        <img class="img-fluid"
                             src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg"
                             alt="Card image cap">

                        <!--Card content-->
                        <mdb-card-body>
                            <!--Title-->
                            <h4 class="card-title">
                                <strong>Card title</strong>
                            </h4>
                            <!--Text-->
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk
                                of the card's content.
                            </p>
                            <a href="#" mdbBtn color="pink" rounded="true">Button</a>
                        </mdb-card-body>

                    </mdb-card>
                    <!--/.Card-->
                </div>

                <div class="col-md-4 my-5">
                    <!--Subheading-->
                    <h4 class="mb-4 font-bold dark-grey-text ">
                        <strong>Testimonial card</strong>
                    </h4>
                    <!--Card-->
                    <mdb-card class="testimonial-card" style="max-width: 22rem;">

                        <!--Bacground color-->
                        <div class="card-up aqua-gradient">
                        </div>

                        <!--Avatar-->
                        <div class="avatar mx-auto">
                            <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20%2831%29.jpg"
                                 class="rounded-circle img-responsive">
                        </div>

                        <mdb-card-body>
                            <!--Name-->
                            <h4 class="card-title dark-grey-text">
                                <strong>Martha Smith</strong>
                            </h4>
                            <hr>
                            <!--Quotation-->
                            <p class="card-text pt-2"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos,
                                adipisci Sed ut perspiciatis unde omnis iste
                                natus sit voluptatem accusantium doloremque laudantium, totam rem aperiam. Facere modi
                                sunt, quod quibusdam
                                dignissimos neque rem nihil.</p>
                        </mdb-card-body>

                    </mdb-card>
                    <!--/.Card-->
                </div>
            </div>

        </section>
        <!--Section: Additional cards-->

        <!--Section: Overlay cards-->
        <section class="mb-5">
            <h3 class="font-bold dark-grey-text mb-4">
                <strong>Image overlay</strong>
            </h3>

            <div class="row">

                <div class="col-md-6 my-5">

                    <!-- Card -->
                    <mdb-card
                            imageBackground="url('https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2814%29.jpg')">

                        <!-- Content -->
                        <div class="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4">
                            <div>
                                <h6 class="pink-text">
                                    <mdb-icon fas icon="chart-pie"></mdb-icon>
                                    <strong> Marketing</strong>
                                </h6>
                                <h3 class="card-title pt-4 pb-3 font-bold">
                                    <strong>This is card title</strong>
                                </h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat fugiat,
                                    laboriosam, voluptatem, optio
                                    vero odio nam sit officia accusamus minus error nisi architecto nulla ipsum
                                    dignissimos. Odit sed qui,
                                    dolorum!.
                                </p>
                                <a mdbBtn color="pink" rounded="true" class="waves-effect waves-light">
                                    <mdb-icon far icon="clone" class="left"></mdb-icon>
                                    View project</a>
                            </div>
                        </div>
                        <!-- Content -->
                    </mdb-card>
                    <!-- Card -->

                </div>

                <div class="col-md-6 my-5">
                    <mdb-card
                            imageBackground="url('https://mdbootstrap.com/img/Photos/Horizontal/City/6-col/img%20(47).jpg')">
                        <div class="text-white text-center d-flex align-items-center rgba-indigo-strong py-5 px-4">
                            <div>
                                <h6 class="orange-text">
                                    <mdb-icon fas icon="desktop"></mdb-icon>
                                    <strong> Software</strong>
                                </h6>
                                <h3 class="card-title pt-4 pb-3 font-bold">
                                    <strong>This is card title</strong>
                                </h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat fugiat,
                                    laboriosam, voluptatem, optio
                                    vero odio nam sit officia accusamus minus error nisi architecto nulla ipsum
                                    dignissimos. Odit sed qui,
                                    dolorum!
                                </p>
                                <a mdbBtn color="deep-orange" rounded="true" class="waves-effect waves-light">
                                    <mdb-icon far icon="clone" class="left"></mdb-icon>
                                    View project</a>
                            </div>
                        </div>
                    </mdb-card>
                </div>
            </div>

        </section>
        <!--Section: Overlay cards-->

        <!--Section: Additional cards-->
        <section class="mb-5">

            <div class="row mb-5">
                <!--Subheading-->
                <div class="col-md-4 my-5">
                    <h4 class="font-bold mb-4 dark-grey-text pb-4">
                        <strong>Action button</strong>
                    </h4>

                    <!--Card-->
                    <mdb-card>
                        <!--Card image-->
                        <div class="view overlay waves-light" mdbWavesEffect>
                            <img src="https://mdbootstrap.com/img/Photos/Others/food.jpg" class="img-fluid"
                                 alt="sample">
                            <a>
                                <div class="mask rgba-white-slight"></div>
                            </a>
                        </div>
                        <!--/.Card image-->
                        <!--Button-->
                        <a mdbBtn floating="true" color="mdb-color"
                           class="btn-action ml-auto mr-4 lighten-3 waves-light" mdbWavesEffect>
                            <mdb-icon fas icon="chevron-right" class="pl-1 waves-light" mdbWavesEffect></mdb-icon>
                        </a>
                        <!--Card content-->
                        <mdb-card-body>
                            <!--Title-->
                            <h4 class="card-title">Card title</h4>
                            <hr>
                            <!--Text-->
                            <p class="font-small grey-dark-text mb-0">Some quick example text to build on the card title
                                and make up the bulk of the card's content.</p>
                        </mdb-card-body>
                        <!--/.Card content-->
                        <!-- Card footer -->
                        <div class="mdb-color lighten-3 text-center">
                            <ul class="list-unstyled list-inline font-small mt-3">
                                <li class="list-inline-item pr-2 white-text">
                                    <mdb-icon far icon="clock" class="pr-1"></mdb-icon>
                                    05/10/2015
                                </li>
                                <li class="list-inline-item pr-2">
                                    <a href="#" class="white-text">
                                        <mdb-icon far icon="comments" class="pr-1"></mdb-icon>
                                        12</a>
                                </li>
                                <li class="list-inline-item pr-2">
                                    <a href="#" class="white-text">
                                        <mdb-icon fab icon="facebook-f" class="pr-1"></mdb-icon>
                                        21</a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="#" class="white-text">
                                        <mdb-icon fab icon="twitter" class="pr-1"></mdb-icon>
                                        5</a>
                                </li>
                            </ul>
                        </div>
                        <!-- Card footer -->
                    </mdb-card>
                    <!--/.Card-->

                </div>
                <div class="col-md-4 my-5">
                    <!--Subheading-->
                    <h4 class="font-bold mb-4 dark-grey-text pb-4">
                        <strong>Light version</strong>
                    </h4>

                    <mdb-card>
                        <!--Card image-->
                        <div class="view overlay waves-light" mdbWavesEffect>
                            <img src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20%28131%29.jpg"
                                 class="img-fluid" alt="fern">
                            <a>
                                <div class="mask rgba-white-slight"></div>
                            </a>
                        </div>
                        <!--/.Card image-->
                        <!--Card content-->
                        <mdb-card-body>
                            <!--Social shares button-->
                            <a class="activator p-3 mr-2">
                                <mdb-icon fas icon="share-alt"></mdb-icon>
                            </a>
                            <!--Title-->
                            <h4 class="card-title">Card title</h4>
                            <hr>
                            <!--Text-->
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk
                                of the card's content.</p>
                            <a class="link-text">
                                <h5 class="waves-light" mdbWavesEffect>Read more
                                    <mdb-icon fas icon="chevron-right" class="waves-light" mdbWavesEffect></mdb-icon>
                                </h5>
                            </a>
                        </mdb-card-body>
                        <!--/.Card content-->
                    </mdb-card>
                    <!--/.Card Light-->

                </div>

                <div class="col-md-4 my-5">
                    <!--Subheading-->
                    <h4 id="" class="font-bold mb-4 dark-grey-text pb-4">
                        <strong>Dark card</strong>
                    </h4>

                    <!--Card Dark-->
                    <mdb-card dark="true">
                        <!--Card image-->
                        <div class="view overlay waves-light" mdbWavesEffect>
                            <img src="https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2821%29.jpg"
                                 class="img-fluid" alt="work desk">
                            <a>
                                <div class="mask rgba-white-slight"></div>
                            </a>
                        </div>
                        <!--/.Card image-->
                        <!--Card content-->
                        <mdb-card-body class="elegant-color white-text">
                            <!--Social shares button-->
                            <a class="activator p-3 mr-2">
                                <mdb-icon fas icon="share-alt" class="white-text"></mdb-icon>
                            </a>
                            <!--Title-->
                            <h4 class="card-title">Card title</h4>
                            <hr class="hr-light">
                            <!--Text-->
                            <p class="font-small mb-4">Some quick example text to build on the card title and make up
                                the bulk of the card's content.</p>
                            <a href="#!" class="white-text d-flex justify-content-end">
                                <h5 class="waves-light" mdbWavesEffect>Read more</h5>
                                <span>
                  <mdb-icon fas icon="chevron-right" class="pl-2 waves-light" mdbWavesEffect></mdb-icon>
                </span>
                            </a>
                        </mdb-card-body>
                        <!--/.Card content-->
                    </mdb-card>
                    <!--/.Card Dark-->

                </div>
            </div>
            <!-- row-->
        </section>
        <!--/.Section: Additional cards-->
        <!--Section: Docs link-->
        <section class="pb-4">
            <panel header="Full documentation" color="primary-color">
                <p class="card-text">Read the full documentation for these components.</p>
                <a href="https://mdbootstrap.com/angular/components/cards/" target="_blank"
                   class="btn btn-primary waves-effect waves-light">Learn more</a>
            </panel>
        </section>
        <!--/.Section: Docs link-->
    </div>
</div>
