<div class="row" #datePicker>
  <!--Grid column-->
  <div class="col-lg-4 col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true">
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">Stacked</h4>
      </div>
      <!--/Card image-->
      <!--Card content-->
      <mdb-card-body cascade="true">
        <!-- Login form -->
        <form>

          <div class="md-form">
            <input type="email" id="defaultForm-email" class="form-control" mdbInput>
            <label for="defaultForm-email">Your email</label>
          </div>

          <div class="md-form">
            <input type="password" id="defaultForm-pass" class="form-control" mdbInput>
            <label for="defaultFormth-pass">Your password</label>
          </div>

          <div class="text-center">
            <button mdbBtn color="default" class="waves-light" mdbWavesEffect>Login</button>
          </div>
        </form>
        <!-- Login form -->
      </mdb-card-body>
      <!--/.Card content-->
    </mdb-card>
    <!--/.Card-->
  </div>
  <!--Grid column-->
  <!--Grid column-->
  <div class="col-lg-4 col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true">
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">With icons</h4>
      </div>
      <!--/Card image-->
      <!--Card content-->
      <mdb-card-body casscade="true">
        <!-- Login form -->
        <form>

          <div class="md-form">
            <mdb-icon far icon="envelope" class="grey-text"></mdb-icon>
            <input type="text" id="defaultForm-email" class="form-control" mdbInput>
            <label for="defaultForm-email">Your email</label>
          </div>

          <div class="md-form">
            <mdb-icon fas icon="lock" class="grey-text"></mdb-icon>
            <input type="password" id="defaultForm-pass" class="form-control" mdbInput>
            <label for="defaultForm-pass">Your password</label>
          </div>

          <div class="text-center">
            <button mdbBtn color="default" class="waves-light" mdbWavesEffect>Login</button>
          </div>
        </form>
        <!-- Login form -->
      </mdb-card-body>
      <!--/.Card content-->
    </mdb-card>
    <!--/.Card-->
  </div>
  <!--Grid column-->
  <!--Grid column-->
  <div class="col-lg-4 col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true">
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">Aligned</h4>
      </div>
      <!--/Card image-->
      <!--Card content-->
      <mdb-card-body cascade="true">
        <!-- Login form -->
        <form>
          <div class="md-form form-group row d-flex">
            <p class="label col-md-4 pt-2">First name</p>
            <div class="col-md-8">
              <input type="text" id="defaultForm-firstname" class="form-control pt-0" mdbInput>
            </div>
          </div>
          <div class="md-form form-group row d-flex">
            <p class="label col-md-4 pt-2">Last name</p>
            <div class="col-md-8">
              <input type="text" id="defaultForm-lastname" class="form-control pt-0" mdbInput>
            </div>
          </div>
          <div class="md-form form-group row d-flex">
            <p class="label col-md-4 pt-2">Email</p>
            <div class="col-md-8">
              <input type="email" id="defaultForm-email" class="form-control pt-0" mdbInput>
            </div>
          </div>
          <div class="md-form form-group row d-flex">
            <p class="label col-md-4 pt-2">Password</p>
            <div class="col-md-8">
              <input type="password" id="defaultForm-pass" class="form-control pt-0" mdbInput>
            </div>
          </div>
          <div class="text-center">
            <button mdbBtn color="default" class="waves-light" mdbWavesEffect>Register</button>
          </div>
        </form>
        <!-- Login form -->
      </mdb-card-body>
      <!--/.Card content-->
    </mdb-card>
    <!--/.Card-->
  </div>
  <!--Grid column-->
</div>

<div class="row mt-3">
  <!--Grid column-->
  <div class="col-lg-12 col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true">
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">Inputs</h4>
      </div>
      <!--/Card image-->
      <!--Card content-->
      <mdb-card-body cascade="true">
        <table class="table">
          <tbody>
            <tr>
              <td>Basic</td>
              <td>
                <div class="md-form">
                  <input mdbInput type="text" id="form1" class="form-control">
                  <label for="form1" class="">Example label</label>
                </div>
              </td>
            </tr>
            <tr>
              <td>Small input</td>
              <td>
                <div class="md-form form-sm">
                  <input mdbInput type="text" id="form1" class="form-control">
                  <label for="form1" class="">Example label</label>
                </div>
              </td>
            </tr>
            <tr>
              <td>Icon prefixed</td>
              <td>
                <div class="md-form">
                  <mdb-icon far icon="envelope"></mdb-icon>
                  <input mdbInput type="text" id="form2" class="form-control">
                  <label for="form2">Example label</label>
                </div>
              </td>
            </tr>
            <tr>
              <td>Placeholder</td>
              <td>
                <div class="md-form">
                  <input placeholder="Placeholder" type="text" id="form58" class="form-control" mdbInput>
                  <label class="active" for="form58">Example label</label>
                </div>
              </td>
            </tr>
            <tr>
              <td>Prefilled</td>
              <td>
                <div class="md-form">
                  <input value="John Doe" type="text" id="form6" class="form-control" mdbInput>
                  <label class="active" for="form6">Example label</label>
                </div>
              </td>
            </tr>
            <tr>
              <td>Disabled</td>
              <td>
                <div class="md-form form-sm">
                  <input type="text" id="form11" class="form-control" disabled mdbInput>
                  <label for="form11" class="disabled">Example label</label>
                </div>
              </td>
            </tr>
            <tr>
              <td>Checbkoxes</td>
              <td>
                <mdb-checkbox>Classic checkbox</mdb-checkbox>
                <mdb-checkbox checked="true">Classic checked checkbox</mdb-checkbox>
                <mdb-checkbox filledIn="true">Filled-in checkbox</mdb-checkbox>
                <mdb-checkbox filledIn="true" checked="true">Filled-in checked checkbox</mdb-checkbox>
                <mdb-checkbox disabled="true">Disabled classic checkbox</mdb-checkbox>
                <mdb-checkbox disabled="true" filledIn="true" checked="true">Filled in disabled checkbox</mdb-checkbox>
              </td>
            </tr>
            <tr>
              <td>Inline</td>
              <td>
                <form class="form-inline">
                  <div class="form-group mr-4">
                    <input type="checkbox" id="checkbox11" checked="checked" class="form-check-input">
                    <label for="checkbox11" class="form-check-label">Classic checkbox</label>
                  </div>
                  <div class="form-group mr-4">
                    <input type="checkbox" class="filled-in form-check-input" id="checkbox22" checked="checked">
                    <label for="checkbox22" class="form-check-label">Filled-in checkbox</label>
                  </div>
                  <div class="form-group">
                    <input type="checkbox" id="checkbox33" checked="checked" class="form-check-input">
                    <label for="checkbox33" class="form-check-label">Classic checkbox</label>
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <td>Radio</td>
              <td>
                <div class="form-group">
                  <input name="group1" type="radio" id="radio1" checked="checked" class="form-check-input">
                  <label for="radio1" class="form-check-label">Basic radio 1</label>
                </div>

                <div class="form-group">
                  <input name="group1" type="radio" id="radio2" class="form-check-input">
                  <label for="radio2" class="form-check-label">Basic radio 2</label>
                </div>

                <div class="form-group">
                  <input name="group1" type="radio" id="radio3" class="form-check-input">
                  <label for="radio3" class="form-check-label">Basic radio 3</label>
                </div>
                <div class="form-group">
                  <input name="group2" type="radio" class="with-gap form-check-input" id="radio4" checked="checked">
                  <label for="radio4" class="form-check-label">Radio with gap 1</label>
                </div>

                <div class="form-group">
                  <input name="group2" type="radio" class="with-gap form-check-input" id="radio5">
                  <label for="radio5" class="form-check-label">Radio with gap 2</label>
                </div>

                <div class="form-group">
                  <input name="group2" type="radio" class="with-gap form-check-input" id="radio6">
                  <label for="radio6" class="form-check-label">Radio with gap 3</label>
                </div>

              </td>
            </tr>
            <tr>
              <td>Inline</td>
              <td>
                <form class="form-inline">
                  <div class="form-group px-2">
                    <input name="group1" type="radio" id="radio11" checked="checked" class="form-check-input">
                    <label for="radio11" class="form-check-label">Option 1</label>
                  </div>

                  <div class="form-group px-2">
                    <input name="group1" type="radio" id="radio21" class="form-check-input">
                    <label for="radio21" class="form-check-label">Option 2</label>
                  </div>

                  <div class="form-group px-2">
                    <input name="group1" type="radio" id="radio31" class="form-check-input">
                    <label for="radio31" class="form-check-label">Option 3</label>
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <td>Switch</td>
              <td>
                <!-- Switch -->
                <div class="switch">
                  <label>
                    Off
                    <input type="checkbox">
                    <span class="lever"></span>
                    On
                  </label>
                </div>

                <!-- Disabled Switch -->
                <div class="switch">
                  <label>
                    Off
                    <input disabled type="checkbox">
                    <span class="lever"></span>
                    On
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </mdb-card-body>
      <!--/.Card content-->
    </mdb-card>
    <!--/.Card-->
  </div>
  <!--Grid column-->
</div>
<div class="row justify-content-center">
  <div class="col-md-10">
    <!--Section: Docs link-->
    <section class="pb-4 pt-5">
      <panel header="Full documentation" color="primary-color">
        <p class="card-text">Read the full documentation for these components.</p>
        <a href="https://mdbootstrap.com/angular/components/inputs/" target="_blank" mdbBtn color="primary" class="waves-effect waves-light">Learn more</a>
      </panel>
    </section>
    <!--/.Section: Docs link-->
  </div>
</div>
