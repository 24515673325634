<!--Section: Doc content-->
<section class="documentation">




      <div class="row ">
        <div class="col-md-12">
          <!--Card-->
          <div class="card card-cascade  mt-5">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header blue-gradient">
              <h4 class="h4-responsive">Typography</h4>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <div class="card-body text-center flex-wrap">

              <table class="table table-responsive">
                <!-- <thead>
                            <tr>
                              <th>#</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Username</th>
                            </tr>
                          </thead> -->
                <tbody class="text-left">
                  <tr>
                    <td>Header 1</td>
                    <td>
                      <h1>Material Design for Bootstrap Angular PRO</h1>
                    </td>
                  </tr>
                  <tr>
                    <td>Header 2</td>
                    <td>
                      <h2>Material Design for Bootstrap Angular PRO</h2>
                    </td>
                  </tr>
                  <tr>
                    <td>Header 3</td>
                    <td>
                      <h3>Material Design for Bootstrap Angular PRO</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>Header 4</td>
                    <td>
                      <h4>Material Design for Bootstrap Angular PRO</h4>
                    </td>
                  </tr>
                  <tr>
                    <td>Header 5</td>
                    <td>
                      <h5>Material Design for Bootstrap Angular PRO</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>Header 6</td>
                    <td>
                      <h6>Material Design for Bootstrap Angular PRO</h6>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <strong>
                        <h1>Responsive Headings</h1>
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Responsive Header 1</td>
                    <td>
                      <h1 class="h1-responsive">Material Design for Bootstrap Angular PRO</h1>
                    </td>
                  </tr>
                  <tr>
                    <td>Responsive Header 2</td>
                    <td>
                      <h2 class="h2-responsive">Material Design for Bootstrap Angular PRO</h2>
                    </td>
                  </tr>
                  <tr>
                    <td>Responsive Header 3</td>
                    <td>
                      <h3 class="h3-responsive">Material Design for Bootstrap Angular PRO</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>Responsive Responsive Header 4</td>
                    <td>
                      <h4 class="h4-responsive">Material Design for Bootstrap Angular PRO</h4>
                    </td>
                  </tr>
                  <tr>
                    <td>Responsive Header 5</td>
                    <td>
                      <h5 class="h5-responsive">Material Design for Bootstrap Angular PRO</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>Responsive Header 6</td>
                    <td>
                      <h6 class="h6-responsive">Material Design for Bootstrap Angular PRO</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>Lead Paragraph</td>
                    <td>
                      <p class="lead">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores quibusdam dignissimos itaque harum
                        illo! Quidem, corporis at quae tempore nisi impedit cupiditate perferendis nesciunt, ex dolores doloremque!
                        Sit, rem, in?</p>
                    </td>
                  </tr>
                  <tr>
                    <td>Paragraph</td>
                    <td>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores quibusdam dignissimos itaque harum
                        illo! Quidem, corporis at quae tempore nisi impedit cupiditate perferendis nesciunt, ex dolores doloremque!
                        Sit, rem, in?</p>
                    </td>
                  </tr>
                  <tr>
                    <td>Highlight</td>
                    <td>
                      <p>You can use the mark tag to
                        <mark>highlight</mark> text.</p>
                    </td>
                  </tr>
                  <tr>
                    <td>Delete</td>
                    <td>
                      <p>
                        <del>This line of text is meant to be treated as deleted text.</del>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Crossed</td>
                    <td>
                      <p>
                        <s>This line of text is meant to be treated as no longer accurate.</s>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Insert</td>
                    <td>
                      <p>
                        <ins>This line of text is meant to be treated as an addition to the document.</ins>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Underline</td>
                    <td>
                      <p>
                        <u>This line of text will render as underlined</u>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Small</td>
                    <td>
                      <p>
                        <small>This line of text is meant to be treated as fine print.</small>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Strong</td>
                    <td>
                      <p>
                        <strong>This line rendered as bold text.</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Italic</td>
                    <td>
                      <p>
                        <em>This line rendered as italicized text.</em>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Quotes</td>
                    <td>
                      <blockquote class="blockquote">
                        <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                      </blockquote>
                    </td>
                  </tr>
                  <tr>
                    <td>Sourced quote</td>
                    <td>
                      <blockquote class="blockquote">
                        <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                        <footer class="blockquote-footer">Someone famous in
                          <cite title="Source Title">Source Title</cite>
                        </footer>
                      </blockquote>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>Reversed quote</td>
                    <td>
                      <blockquote class="blockquote blockquote-reverse">
                        <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                        <footer class="blockquote-footer">Someone famous in
                          <cite title="Source Title">Source Title</cite>
                        </footer>
                      </blockquote>
                    </td>
                  </tr> -->
                  <tr>
                    <td>Info</td>
                    <td>
                      <blockquote class="blockquote bq-primary">
                        <p class="bq-title">Info notification</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores quibusdam dignissimos itaque harum
                          illo! Quidem, corporis at quae tempore nisi impedit cupiditate perferendis nesciunt, ex dolores
                          doloremque! Sit, rem, in?</p>
                      </blockquote>
                    </td>
                  </tr>
                  <tr>
                    <td>Warning</td>
                    <td>
                      <blockquote class="blockquote bq-warning">
                        <p class="bq-title">Warning notification</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores quibusdam dignissimos itaque harum
                          illo! Quidem, corporis at quae tempore nisi impedit cupiditate perferendis nesciunt, ex dolores
                          doloremque! Sit, rem, in?</p>
                      </blockquote>
                    </td>
                  </tr>
                  <tr>
                    <td>Success</td>
                    <td>
                      <blockquote class="blockquote bq-success">
                        <p class="bq-title">Success notification</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores quibusdam dignissimos itaque harum
                          illo! Quidem, corporis at quae tempore nisi impedit cupiditate perferendis nesciunt, ex dolores
                          doloremque! Sit, rem, in?</p>
                      </blockquote>
                    </td>
                  </tr>
                  <tr>
                    <td>Danger</td>
                    <td>
                      <blockquote class="blockquote bq-danger">
                        <p class="bq-title">Danger notification</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores quibusdam dignissimos itaque harum
                          illo! Quidem, corporis at quae tempore nisi impedit cupiditate perferendis nesciunt, ex dolores
                          doloremque! Sit, rem, in?</p>
                      </blockquote>
                    </td>
                  </tr>
                  <tr>
                    <td>Colors</td>
                    <td>
                      <p class="red-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                      <p class="pink-text">Impedit architecto, totam hic sunt eum odio, deleniti.</p>
                      <p class="purple-text">Similique ex, expedita minus assumenda a magni beatae dolorum itaque.</p>
                      <p class="deep-purple-text">Veritatis eum libero nam, adipisci, necessitatibus eos.</p>
                      <p class="indigo-text">Sapiente, ea. Molestias sunt nihil saepe numquam quas perferendis.</p>
                      <p class="blue-text">Sapiente sit, possimus maiores, quo alias corporis cum eum nesciunt.</p>
                      <p class="light-blue-text">Corrupti adipisci, praesentium iusto eos, iure debitis modi.</p>
                      <p class="cyan-text">Odio autem veritatis aliquam consequuntur ea voluptatibus.</p>
                      <p class="teal-text">Similique, adipisci ea, sequi magnam sit inventore.</p>
                      <p class="green-text">Eos et vitae, odit deserunt dignissimos voluptas.</p>
                      <p class="light-green-text">Fugit nihil numquam inventore accusantium tenetur ex est.</p>
                      <p class="lime-text">Sed odit inventore illum excepturi officia, reiciendis numquam modi.</p>
                      <p class="yellow-text">At odio animi distinctio, aut enim tempora nobis error odit mollitia.</p>
                      <p class="amber-text">Quo at accusamus vel earum hic, inventore non, minima sint.</p>
                      <p class="orange-text">Iusto odit eos distinctio temporibus voluptates ad, illo repellat.</p>
                      <p class="deep-orange-text">Quas fugit fuga assumenda nihil esse et culpa reiciendis voluptatum.</p>
                      <p class="brown-text">Excepturi iusto amet sunt illo ad debitis quibusdam eius, consequatur.</p>
                      <p class="grey-text">Non sint nulla incidunt, odit repellat tempore, veniam ratione fugit.</p>
                      <p class="blue-grey-text">Eius, provident. Quo similique, repellat atque voluptas explicabo odio.</p>
                      <p class="white-text">In consequuntur error, non consequatur expedita maxime dolorum.</p>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <!--/.Card content-->

          </div>
          <!--/.Card-->
        </div>
      </div>


 
  <div class="row justify-content-center pt-3">
    <div class="col-md-10">
      <!--Section: Docs link-->
      <section class="pb-4 pt-4">
        <panel header="Full documentation" color="primary-color">
          <p class="card-text">Read the full documentation for these components.</p>
          <a href="https://mdbootstrap.com/angular/content/typography/" target="_blank" class="btn btn-primary waves-effect waves-light">Learn more</a>
        </panel>
      </section>
      <!--/.Section: Docs link-->
    </div>
  </div>
</section>