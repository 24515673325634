<div class="row mt-5">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">Pagination </h4>
      </div>
      <!--/Card image-->
      <!--Card content-->
      <mdb-card-body cascade="true" class="text-center">

        <!--Section: Pagination-->
        <section class="mb-5 pb-5">

          <div class="row">

            <!--Grid column-->
            <div class="col-md-6 col-lg-5">
              <h5 class="my-5 dark-grey-text font-bold">Basic</h5>

              <div class="row mb-5">
                <!--Pagination -->
                <nav class="my-4">
                  <ul class="pagination pagination-circle pg-blue mb-0 flex-wrap">

                    <!--First-->
                    <li class="page-item disabled">
                      <a class="page-link">First</a>
                    </li>

                    <!--Arrow left-->
                    <li class="page-item disabled">
                      <a class="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>

                    <!--Numbers-->
                    <li class="page-item active">
                      <a class="page-link">1</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link">2</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link">4</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link">5</a>
                    </li>

                    <!--Arrow right-->
                    <li class="page-item">
                      <a class="page-link" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>

                    <!--First-->
                    <li class="page-item">
                      <a class="page-link">Last</a>
                    </li>

                  </ul>
                </nav>
                <!--/Pagination -->

              </div>
              <!--Grid row-->


              <!--Grid row-->
              <div class="row mb-5">

                <nav>
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">1</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">2</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">4</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">5</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>


              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">

                <nav>
                  <ul class="pagination pg-blue">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>
                    <li class="page-item active">
                      <a class="page-link" href="#">1
                        <span class="sr-only">(current)</span>
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">2</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">4</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">5</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>

              </div>
              <!--Grid row-->

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-6 col-lg-4">
              <h5 class="my-5 dark-grey-text font-bold">Colors</h5>

              <!--Pagination blue-->
              <nav>
                <ul class="pagination pg-blue">
                  <!--Arrow left-->
                  <li class="page-item">
                    <a class="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>

                  <!--Numbers-->
                  <li class="page-item active">
                    <a class="page-link">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">3</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">4</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">5</a>
                  </li>

                  <!--Arrow right-->
                  <li class="page-item">
                    <a class="page-link" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <!--/Pagination blue-->

              <!--Pagination red-->
              <nav>
                <ul class="pagination pg-red">
                  <!--Arrow left-->
                  <li class="page-item">
                    <a class="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>

                  <!--Numbers-->
                  <li class="page-item active">
                    <a class="page-link">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">3</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">4</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">5</a>
                  </li>

                  <!--Arrow right-->
                  <li class="page-item">
                    <a class="page-link" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <!--/Pagination red-->

              <!--Pagination teal-->
              <nav>
                <ul class="pagination pg-teal">
                  <!--Arrow left-->
                  <li class="page-item">
                    <a class="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>

                  <!--Numbers-->
                  <li class="page-item active">
                    <a class="page-link">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">3</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">4</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">5</a>
                  </li>

                  <!--Arrow right-->
                  <li class="page-item">
                    <a class="page-link" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <!--/Pagination teal-->

              <!--Pagination dark-->
              <nav>
                <ul class="pagination pg-dark">
                  <!--Arrow left-->
                  <li class="page-item">
                    <a class="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>

                  <!--Numbers-->
                  <li class="page-item active">
                    <a class="page-link">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">3</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">4</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">5</a>
                  </li>

                  <!--Arrow right-->
                  <li class="page-item">
                    <a class="page-link" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <!--/Pagination dark-->

              <!--Pagination amber-->
              <nav>
                <ul class="pagination pg-amber">
                  <!--Arrow left-->
                  <li class="page-item">
                    <a class="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>

                  <!--Numbers-->
                  <li class="page-item active">
                    <a class="page-link">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">3</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">4</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">5</a>
                  </li>

                  <!--Arrow right-->
                  <li class="page-item">
                    <a class="page-link" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <!--/Pagination amber-->

              <!--Pagination purple-->
              <nav>
                <ul class="pagination pg-purple">
                  <!--Arrow left-->
                  <li class="page-item">
                    <a class="page-link" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>

                  <!--Numbers-->
                  <li class="page-item active">
                    <a class="page-link">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">3</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">4</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link">5</a>
                  </li>

                  <!--Arrow right-->
                  <li class="page-item">
                    <a class="page-link" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <!--/Pagination purple-->

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-6 col-lg-3">
              <h5 class="my-5 dark-grey-text font-bold">Size</h5>
              <h6 class="title mt-5 dark-grey-text font-bold">Large</h6>


              <!--Grid row-->
              <div class="row">

                <nav>
                  <ul class="pagination pagination-lg">
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">1</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">2</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>

              </div>
              <!--Grid row-->


              <h6 class="title mt-4 mb-3 dark-grey-text font-bold">Small</h6>
              <!--Grid row-->
              <div class="row">

                <nav>
                  <ul class="pagination pagination-sm">
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">1</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">2</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>


              </div>
              <!--Grid row-->

            </div>
            <!--Grid column-->
          </div>
        </section>
        <!--Section: Background variants-->
        <!--Section: Docs link-->
        <section class="pb-4">
          <panel header="Full documentation" color="primary-color">
            <p class="card-text">Read the full documentation for these components.</p>
            <a href="https://mdbootstrap.com/angular/components/pagination/" target="_blank" mdbBtn color="primary" class="waves-effect waves-light">Learn more</a>
          </panel>
        </section>
        <!--/.Section: Docs link-->
      </mdb-card-body>
      <!--/.Card content-->
    </mdb-card>
    <!--/.Card-->
  </div>
</div>
