<div class="row mt-5">
    <div class="col-md-12">
        <!--Card-->
        <mdb-card cascade="true" narrower="true" class="mt-5">
            <!--Card image-->
            <div class="view view-cascade gradient-card-header blue-gradient">
                <h4 class="h4-responsive">Time picker </h4>
            </div>
            <!--/Card image-->
            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">
                <div class="row">
                    <div class="col-md-6 col-lg-5 ml-auto">
                        <div class="px-5 pt-5 mt-5 mx-2">
                                <mdb-time-picker [twelvehour]="true" [darktheme]="false" [placeholder]="'Selected time'"
                                                 [label]="'Try me...'" [showClock]="false"></mdb-time-picker>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-5 mr-auto">
                        <div class="px-5 pt-5 mt-5 mx-2">
                                <mdb-time-picker [twelvehour]="false" [darktheme]="true" [placeholder]="'Selected time'"
                                                 [label]="'And me! I am different!'"></mdb-time-picker>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <!--Section: Docs link-->
                        <section class="pb-4 pt-5">
                            <panel header="Full documentation" color="primary-color">
                                <p class="card-text">Read the full documentation for these components.</p>
                                <a href="https://mdbootstrap.com/angular/advanced/timepicker/" target="_blank" mdbBtn
                                   color="primary" class="waves-effect waves-light">Learn more</a>
                            </panel>
                        </section>
                        <!--/.Section: Docs link-->
                    </div>
                </div>
            </mdb-card-body>
            <!--/.Card content-->
        </mdb-card>
        <!--/.Card-->
    </div>
</div>
