<h2 class="mt-5 font-bold">
    <strong>Grid system</strong>
</h2>

<!--Section: Five grid tiers-->
<section class="mt-5">

    <h4 class="my-5 dark-grey-text font-bold">
        <strong>Five grid tiers</strong>
    </h4>

    <div class="row">

        <!--Grid column-->
        <div class="col-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

    </div>

    <div class="row mt-4">

        <!--Grid column-->
        <div class="col-sm-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-sm-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-sm-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-sm-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-sm-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-sm-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

    </div>

    <div class="row mt-4">

        <!--Grid column-->
        <div class="col-md-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-md-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-md-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-md-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

    </div>

    <div class="row mt-4">

        <!--Grid column-->
        <div class="col-lg-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-lg-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-lg-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-lg-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

    </div>

    <div class="row mt-4">

        <!--Grid column-->
        <div class="col-xl-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-xl-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-xl-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-xl-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-xl-4">
            <div class="card white text-center z-depth-2">
                <div class="card-body">
                    <p class="mb-0">.col-xl-4</p>
                </div>
            </div>
        </div>
        <!--Grid column-->
    </div>

</section>
<!--Section: Five grid tiers-->

<!--Section: Three equal columns-->
<section class="mb-5">

    <div class="container-fluid mt-5">
        <h4 class="my-5 pt-4 dark-grey-text font-bold">
            <strong>Three equal columns</strong>
        </h4>

        <div class="row mt-4">

            <!--Grid column-->
            <div class="col-md-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-md-4</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-md-4</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-md-4</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

        </div>

    </div>

</section>
<!--Section: Three equal columns-->

<!--Section: Two columns-->
<section class="mb-5">

    <div class="container-fluid mt-5">
        <h4 class="my-5 pt-4 dark-grey-text font-bold">
            <strong>Two columns</strong>
        </h4>

        <div class="row">

            <!--Grid column-->
            <div class="col-md-8">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-md-8</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-md-4</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

        </div>
    </div>

</section>
<!--Section: Two columns-->

<!--Section: Mixed: mobile and desktop-->
<section class="mb-5">

    <div class="container-fluid mt-5">
        <h4 class="my-5 pt-4 dark-grey-text font-bold">
            <strong>Mixed: mobile and desktop</strong>
        </h4>

        <div class="row">

            <!--Grid column-->
            <div class="col-12 col-md-8">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-12 .col-md-8</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-6 col-md-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-6 .col-md-4</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

        </div>

        <div class="row mt-4">

            <!--Grid column-->
            <div class="col-6 col-md-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-6 .col-md-4</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-6 col-md-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-6 .col-md-4</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-6 col-md-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-6 .col-md-4</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

        </div>

        <div class="row mt-4">

            <!--Grid column-->
            <div class="col-6">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-md-6</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-6">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-6</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

        </div>

    </div>

</section>
<!--Section: Mixed: mobile and desktop-->

<!--Section: Mixed: mobile, tablet, and desktop-->
<section class="mb-5">

    <div class="container-fluid mt-5">
        <h4 class="my-5 pt-4 dark-grey-text font-bold">
            <strong>Mixed: mobile, tablet, and desktop</strong>
        </h4>

        <div class="row">

            <!--Grid column-->
            <div class="col-12 col-sm-6 col-lg-8">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class="mb-0">.col-12 .col-sm-6 .col-lg-8</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-6 col-lg-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-6 .col-lg-4</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

        </div>

        <div class="row mt-4">

            <!--Grid column-->
            <div class="col-6 col-sm-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-6. col-sm-4</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-6 col-sm-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-6 .col-sm-4</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-6 col-sm-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class=" mb-0">.col-6 .col-sm-4</p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

        </div>

    </div>

</section>
<!--Section: Mixed: mobile, tablet, and desktop-->

<!--Section: Two columns with two nested columns-->
<section class="mb-5 pb-4">

    <div class="container-fluid mt-5">
        <h4 class="my-5 pt-4 dark-grey-text font-bold">
            <strong>Two columns with two nested columns</strong>
        </h4>

        <div class="row">

            <!--Grid column-->
            <div class="col-md-8">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class="mb-4 mt-2">.col-md-8</p>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="card grey lighten-2 text-center z-depth-2">
                                    <div class="card-body">
                                        <p class="mb-0">.col-md-6</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="card grey lighten-2 text-center z-depth-2">
                                    <div class="card-body">
                                        <p class="mb-0">.col-md-6</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!--Grid column-->
            </div>

            <!--Grid column-->
            <div class="col-md-4">
                <div class="card white text-center z-depth-2">
                    <div class="card-body">
                        <p class="my-5">.col-md-4</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
<!--Section: Two columns with two nested columns-->
<div class="row justify-content-center">
    <div class="col-md-10">
        <!--Section: Docs link-->
        <section class="pb-4 pt-5">
            <panel header="Full documentation" color="primary-color">
                <p class="card-text">Read the full documentation for these components.</p>
                <a href="https://mdbootstrap.com/angular/layout/bootstrap-grid/" target="_blank" class="btn btn-primary waves-effect waves-light">Learn more</a>
            </panel>
        </section>
        <!--/.Section: Docs link-->
    </div>
</div>
