<div class="row mt-5">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">

      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">Panels </h4>
      </div>
      <!--/Card image-->

      <!--Card content-->
      <mdb-card-body cascade="true" class="text-center">

        <!--Section: Background variants-->
        <section class="mb-5 pb-5">
          <h5 class="my-5 dark-grey-text font-bold">Background variants</h5>

          <div class="row">
            <div class="col-md-6">

              <!--Card indigo-->
              <mdb-card bgColor="indigo" class="text-center z-depth-2">
                <mdb-card-body>
                  <p class="white-text mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                </mdb-card-body>
              </mdb-card>
              <!--/.Card indigo-->
              <br>
              <!--Card pink-->
              <mdb-card bgColor="pink" class="lighten-2 text-center z-depth-2">
                <mdb-card-body>
                  <p class="white-text mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                </mdb-card-body>
              </mdb-card>
              <!--/.Card pink-->
              <br>
              <!--Card info-->
              <mdb-card bgColor="info-color" class="text-center z-depth-2 mb-4">
                <mdb-card-body>
                  <p class="white-text mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                </mdb-card-body>
              </mdb-card>
              <!--/.Card info-->

              <!--Card white-->
              <mdb-card bgColor="white" class="text-center z-depth-2 mb-4">
                <mdb-card-body>
                  <p class="dark-grey mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                </mdb-card-body>
              </mdb-card>
              <!--/.Card white-->

            </div>

            <div class="col-md-6">

              <!--Card red-->
              <mdb-card bgColor="red" class="lighten-1 text-center z-depth-2">
                <mdb-card-body>
                  <p class="white-text mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                </mdb-card-body>
              </mdb-card>
              <!--/.Card red-->
              <br>
              <!--Card success-->
              <mdb-card bgColor="success-color" class="text-center z-depth-2">
                <mdb-card-body>
                  <p class="white-text mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                </mdb-card-body>
              </mdb-card>
              <!--/.Card success-->
              <br>
              <!--Card mdb-->
              <mdb-card bgColor="mdb-color" class="lighten-2 text-center z-depth-2 mb-4">
                <mdb-card-body>
                  <p class="white-text mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                </mdb-card-body>
              </mdb-card>
              <!--/.Card mdb-->

              <!--Card transparent-->
              <mdb-card bgColor="transparent" class="text-center z-depth-2">
                <mdb-card-body class="card-body">
                  <p class="dark-grey-text mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                </mdb-card-body>
              </mdb-card>
              <!--/.Card transparent-->

            </div>
          </div>

        </section>
        <!--Section: Background variants-->

        <!--Section: Basic examples-->
        <section class="mb-5">
          <h5 class="my-5 dark-grey-text font-bold">Basic examples</h5>

          <!--Grid row-->
          <div class="row">

            <!--Grid column-->
            <div class="col-lg-4 col-md-12 my-5">

              <!--Panel-->
              <mdb-card class="">
                <mdb-card-body>
                  <h4 class="card-title">Panel title</h4>
                  <p class="card-text mt-3 mb-4">Some quick example text to build on the card title and make up the bulk
                    of the card's content.</p>
                  <div class="flex-row">
                    <a class="card-link">Card link</a>
                    <a class="card-link">Another link</a>
                  </div>
                </mdb-card-body>
              </mdb-card>
              <!--/.Panel-->

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-lg-4 col-md-6 my-5">

              <!--Panel-->
              <mdb-card>
                <div class="card-header deep-orange lighten-1 white-text">
                  Featured
                </div>
                <mdb-card-body>
                  <h4 class="card-title">Special title treatment</h4>
                  <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                  <a mdbBtn color="deep-orange">Go somewhere</a>
                </mdb-card-body>
              </mdb-card>
              <!--/.Panel-->

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-lg-4 col-md-6 my-5">

              <!--Panel-->
              <mdb-card class="text-center">
                <div class="card-header success-color white-text">
                  Featured
                </div>
                <mdb-card-body>
                  <h4 class="card-title">Special title treatment</h4>
                  <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                  <a mdbBtn color="success">Go somewhere</a>
                </mdb-card-body>
                <div class="card-footer text-muted success-color white-text">
                  <p class="mb-0">2 days ago</p>
                </div>
              </mdb-card>
              <!--/.Panel-->

            </div>
            <!--Grid column-->

          </div>
          <!--Grid row-->

        </section>
        <!--Section: Basic examples-->
        <!--Section: Docs link-->
        <section class="pb-4">
          <panel header="Full documentation" color="primary-color">
            <p class="card-text">Read the full documentation for these components.</p>
            <a href="https://mdbootstrap.com/angular/components/panels/" target="_blank" class="btn btn-primary waves-effect waves-light">Learn more</a>
          </panel>
        </section>
        <!--/.Section: Docs link-->
      </mdb-card-body>
      <!--/.Card content-->
    </mdb-card>
    <!--/.Card-->
  </div>
</div>
