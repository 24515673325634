<div class="container-fluid">

    <style>
        .shadow-box-example {
            height: 120px;
            width: 100%;
            background-color: #64b5f6;
        }
    </style>

    <!--Section: Examples-->
    <section>

        <h2 class="mt-lg-5 mb-5 font-bold">
            <strong>Shadows</strong>
        </h2>

        <div class="row">

            <div class="col-md-4 my-4">
                <div class="shadow-box-example z-depth-1 flex-center">
                    <p class="white-text">.z-depth-1</p>
                </div>
            </div>

            <div class="col-md-4 my-4">
                <div class="shadow-box-example z-depth-1-half flex-center">
                    <p class="white-text">.z-depth-1-half</p>
                </div>
            </div>

            <div class="col-md-4 my-4">
                <div class="shadow-box-example z-depth-2 flex-center">
                    <p class="white-text">.z-depth-2</p>
                </div>
            </div>

        </div>

        <div class="row">

            <div class="col-md-4 my-4">
                <div class="shadow-box-example z-depth-3 flex-center">
                    <p class="white-text">.z-depth-3</p>
                </div>
            </div>

            <div class="col-md-4 my-4">
                <div class="shadow-box-example z-depth-4 flex-center">
                    <p class="white-text">.z-depth-4</p>
                </div>
            </div>

            <div class="col-md-4 my-4">
                <div class="shadow-box-example z-depth-5 flex-center">
                    <p class="white-text">.z-depth-5</p>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-md-4 my-4">
                <div class="shadow-box-example hoverable flex-center">
                    <p class="white-text">hover me!</p>
                </div>
            </div>
        </div>

    </section>
    <!--Section: Examples-->

    <div class="row justify-content-center pt-3">
        <div class="col-md-10">
            <!--Section: Docs link-->
            <section class="pb-4 pt-4">
                <panel header="Full documentation" color="primary-color">
                    <p class="card-text">Read the full documentation for these components.</p>
                    <a href="https://mdbootstrap.com/angular/css/shadows/" target="_blank" class="btn btn-primary waves-effect waves-light">Learn more</a>
                </panel>
            </section>
            <!--/.Section: Docs link-->
        </div>
    </div>
