<section>
    <!--Grid row-->
    <div class="row">
        <!--Grid column-->
        <div class="col-xl-3 col-md-6 my-4">
            <!--Card Primary-->
            <mdb-card bgColor="primary-color" class="classic-admin-card">
                <mdb-card-body>
                    <div class="pull-right d-flex justify-content-between">
                        <p class="white-text">SALES</p>
                        <mdb-icon fas icon="money-bill-alt"></mdb-icon>

                    </div>
                    <h4>
                        <strong>$2000</strong>
                    </h4>
                </mdb-card-body>
                <div class="progress">
                    <div class="progress-bar bg grey darken-3" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="card-body">
                    <p>Better than last week (25%)</p>
                </div>
            </mdb-card>
            <!--/.Card Primary-->
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-xl-3 col-md-6 my-4">
            <!--Card Warning-->
            <mdb-card bgColor="warning-color" class="classic-admin-card">
                <mdb-card-body>
                    <div class="pull-right d-flex justify-content-between">
                        <p>SUBSCRIPTIONS</p>
                        <mdb-icon fas icon="chart-line"></mdb-icon>
                    </div>
                    <h4>
                        <strong>200</strong>
                    </h4>
                </mdb-card-body>
                <div class="progress">
                    <div class="progress-bar bg grey darken-3" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="card-body">
                    <p>Worse than last week (25%)</p>
                </div>
            </mdb-card>
            <!--/.Card Warning-->
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-xl-3 col-md-6 my-4">
            <!--Card Blue-->
            <mdb-card bgColor="light-blue" class="classic-admin-card lighten-1">
                <mdb-card-body>
                    <div class="pull-right d-flex justify-content-between">
                        <p>TRAFFIC</p>
                        <mdb-icon fas icon="chart-pie"></mdb-icon>
                    </div>
                    <h4>
                        <strong>20000</strong>
                    </h4>
                </mdb-card-body>
                <div class="progress">
                    <div class="progress-bar bg grey darken-3" role="progressbar" style="width: 75%" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="card-body">
                    <p>Better than last week (75%)</p>
                </div>
            </mdb-card>
            <!--/.Card Blue-->
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-xl-3 col-md-6 my-4">
            <!--Card Red-->
            <mdb-card bgColor="red" class="classic-admin-card accent-2">
                <mdb-card-body>
                    <div class="pull-right d-flex justify-content-between">
                        <p>ORGANIC TRAFFIC</p>
                        <mdb-icon fas icon="chart-bar"></mdb-icon>
                    </div>
                    <h4>
                        <strong>2000</strong>
                    </h4>
                </mdb-card-body>
                <div class="progress">
                    <div class="progress-bar bg grey darken-3" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="card-body">
                    <p>Better than last week (25%)</p>
                </div>
            </mdb-card>
            <!--/.Card Red-->
        </div>
        <!--Grid column-->
    </div>
    <!--Grid row-->
</section>
