<!--Section: -->
<section id="responsive-images">

    <!--Title-->
    <h2 class="mt-lg-5 mb-5 font-bold">
        <strong>Responsive images</strong>
    </h2>

    <!--Section: Live preview-->
    <section>

        <div class="row my-4">
            <div class="col-md-4 my-3">
                <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(134).jpg" class="img-fluid" alt="Responsive image">
            </div>
        </div>

        <div class="row my-4">
            <div class="col-md-6 my-3">
                <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(134).jpg" class="img-fluid" alt="Responsive image">
            </div>
        </div>

        <div class="row my-4">
            <div class="col-md-8 my-3">
                <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(134).jpg" class="img-fluid" alt="Responsive image">
            </div>
        </div>

    </section>
    <!--Section: Live preview-->

</section>
<!--/Section: -->

<hr class="my-5">

<!--Section: -->
<section id="image-thumbnails">

    <!--Title-->
    <h4 class="mt-4 mb-5 font-bold dark-grey-text">
        <strong>With shadows</strong>
    </h4>

    <!--Section: Live preview-->
    <section>

        <!--Grid row-->
        <div class="row">

            <!--Grid column-->
            <div class="col-lg-4 col-md-12 my-4">

                <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(54).jpg" class="img-fluid z-depth-1" alt="">

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-lg-4 col-md-6 my-4">

                <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(54).jpg" class="img-fluid z-depth-1-half" alt="">

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-lg-4 col-md-6 my-4">

                <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(54).jpg" class="img-fluid z-depth-2" alt="">

            </div>
            <!--Grid column-->

        </div>
        <!--Grid row-->

        <!--Grid row-->
        <div class="row">

            <!--Grid column-->
            <div class="col-lg-4 col-md-12 my-4">

                <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(54).jpg" class="img-fluid z-depth-3" alt="">

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-lg-4 col-md-6 my-4">

                <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(54).jpg" class="img-fluid z-depth-4" alt="">

            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-lg-4 col-md-6 my-4">

                <img src="https://mdbootstrap.com/img/Photos/Slides/img%20(54).jpg" class="img-fluid z-depth-5" alt="">

            </div>
            <!--Grid column-->

        </div>
        <!--Grid row-->

    </section>
    <!--Section: Live preview-->

</section>
<!--/Section: -->

<hr class="my-5">

<!--Section: Overlay-->
<section>

    <h4 class="my-5 font-bold dark-grey-text">
        <strong>Overlays</strong>
    </h4>

    <!--Grid row-->
    <div class="row">

        <!--Grid column-->
        <div class="col-lg-4 col-md-12 my-4">

            <div class="view">
                <img src="https://mdbootstrap.com/img/Photos/Others/forest-sm.jpg" class="img-fluid" alt="">
                <div class="mask rgba-teal-slight flex-center waves-effect waves-light">
                    <p class="white-text">super light overlay</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 my-4">

            <div class="view">
                <img src="https://mdbootstrap.com/img/Photos/Others/forest-sm.jpg" class="img-fluid" alt="">
                <div class="mask  rgba-teal-light flex-center waves-effect waves-light">
                    <p class="white-text">light overlay</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 my-4">

            <div class="view">
                <img src="https://mdbootstrap.com/img/Photos/Others/forest-sm.jpg" class="img-fluid" alt="">
                <div class="mask rgba-teal-strong flex-center waves-effect waves-light">
                    <p class="white-text">strong overlay</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

    </div>
    <!--Grid row-->

</section>
<!--Section: Overlay-->

<hr class="my-5">

<!--Section: Patterns-->
<section>

    <h4 class="my-5 font-bold dark-grey-text">
        <strong>Patterns</strong>
    </h4>

    <!--Grid row-->
    <div class="row">

        <!--Grid column-->
        <div class="col-lg-4 col-md-12 my-4">

            <div class="view">
                <img src="https://mdbootstrap.com/img/Photos/Others/nature-sm.jpg" class="img-fluid" alt="Image of ballons flying over canyons with mask pattern one.">
                <div class="mask pattern-1 flex-center waves-effect waves-light">
                    <p class="white-text">.pattern-1</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 my-4">

            <div class="view">
                <img src="https://mdbootstrap.com/img/Photos/Others/nature-sm.jpg" class="img-fluid" alt="">
                <div class="mask pattern-2 flex-center waves-effect waves-light">
                    <p class="white-text">.pattern-2</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 my-4">

            <div class="view">
                <img src="https://mdbootstrap.com/img/Photos/Others/nature-sm.jpg" class="img-fluid" alt="Image of ballons flying over canyons with mask pattern one.">
                <div class="mask pattern-3 flex-center waves-effect waves-light">
                    <p class="white-text">.pattern-3</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

    </div>
    <!--Grid row-->

    <!--Grid row-->
    <div class="row">

        <!--Grid column-->
        <div class="col-lg-4 col-md-12 my-4">

            <div class="view">
                <img src="https://mdbootstrap.com/img/Photos/Others/nature-sm.jpg" class="img-fluid" alt="Image of ballons flying over canyons with mask pattern one.">
                <div class="mask pattern-4 flex-center waves-effect waves-light">
                    <p class="white-text">.pattern-4</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 my-4">

            <div class="view">
                <img src="https://mdbootstrap.com/img/Photos/Others/nature-sm.jpg" class="img-fluid" alt="">
                <div class="mask pattern-5 flex-center waves-effect waves-light">
                    <p class="white-text">.pattern-5</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 my-4">

            <div class="view">
                <img src="https://mdbootstrap.com/img/Photos/Others/nature-sm.jpg" class="img-fluid" alt="Image of ballons flying over canyons with mask pattern one.">
                <div class="mask pattern-6 flex-center waves-effect waves-light">
                    <p class="white-text">.pattern-6</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

    </div>
    <!--Grid row-->

    <!--Grid row-->
    <div class="row mb-4">

        <!--Grid column-->
        <div class="col-lg-4 col-md-12">

            <div class="view">
                <img src="https://mdbootstrap.com/img/Photos/Others/nature-sm.jpg" class="img-fluid" alt="Image of ballons flying over canyons with mask pattern one.">
                <div class="mask pattern-7 flex-center waves-effect waves-light">
                    <p class="white-text">.pattern-7</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6">

            <div class="view">
                <img src="https://mdbootstrap.com/img/Photos/Others/nature-sm.jpg" class="img-fluid" alt="">
                <div class="mask pattern-8 flex-center waves-effect waves-light">
                    <p class="white-text">.pattern-8</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6">

            <div class="view">
                <img src="https://mdbootstrap.com/img/Photos/Others/nature-sm.jpg" class="img-fluid" alt="Image of ballons flying over canyons with mask pattern one.">
                <div class="mask pattern-9 flex-center waves-effect waves-light">
                    <p class="white-text">.pattern-9</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

    </div>
    <!--Grid row-->

</section>
<!--Section: Patterns-->

<hr class="my-5">

<!--Section: Hover effects-->
<section>

    <h4 class="my-5 font-bold dark-grey-text">
        <strong>Hover effects</strong>
    </h4>

    <!--Grid row-->
    <div class="row mt-5">

        <!--Grid column-->
        <div class="col-lg-4 col-md-12 my-4">

            <div class="view overlay">
                <img src="https://mdbootstrap.com/img/Photos/Others/forest-sm.jpg" class="img-fluid " alt="">
                <div class="mask rgba-red-strong flex-center waves-effect waves-light">
                    <p class="white-text">Strong overlay</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 my-4">

            <div class="view overlay">
                <img src="https://mdbootstrap.com/img/Photos/Others/forest-sm.jpg" class="img-fluid " alt="">
                <div class="mask rgba-red-light flex-center waves-effect waves-light">
                    <p class="white-text">Light overlay</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 my-4">

            <div class="view overlay">
                <img src="https://mdbootstrap.com/img/Photos/Others/forest-sm.jpg" class="img-fluid " alt="">
                <div class="mask rgba-red-slight flex-center waves-effect waves-light">
                    <p class="white-text">Super light overlay</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

    </div>
    <!--Grid row-->

    <!--Grid row-->
    <div class="row">

        <!--Grid column-->
        <div class="col-md-6 my-4">

            <!--Zoom effect-->
            <div class="view overlay zoom">
                <img src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20(131).jpg" class="img-fluid " alt="">
                <div class="mask flex-center waves-effect waves-light">
                    <p class="white-text">Zoom effect</p>
                </div>
            </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-md-5 my-4">

            <h4 class="text-center">Shadow effect</h4>
            <br>

            <img src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20(132).jpg" class="img-fluid rounded-circle hoverable"
                alt="">

        </div>
        <!--Grid column-->

    </div>
    <!--Grid row-->

</section>
<!--Section: Hover effects-->

<hr class="my-5">

<!--Section: Waves effect-->
<section class="pb-5">

    <h4 class="my-5 font-bold dark-grey-text">
        <strong>Waves effect (click on the images)</strong>
    </h4>

    <!--Grid row-->
    <div class="row">

        <!--Grid column-->
        <div class="col-lg-6 my-4">

            <!--Mask with wave-->
            <div class="view overlay">
                <img src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20(54).jpg" class="img-fluid" alt="Sample image with waves effect.">
                <a>
                    <div class="mask rgba-white-slight waves-effect waves-light"></div>
                </a>
            </div>
            <!--Mask with wave-->
            <div class="view overlay waves-light" mdbWavesEffect>
                <img src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20(54).jpg" class="img-fluid" alt="Sample image with waves effect.">
                <a href="">
                    <div class="mask rgba-white-slight waves-effect"></div>
                </a>
            </div>

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-6 my-4">

            <!--Mask with wave-->
            <div class="view overlay">
                <img src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20(53).jpg" class="img-fluid" alt="Sample image with waves effect.">
                <a>
                    <div class="mask rgba-white-slight waves-effect"></div>
                </a>
            </div>
            <!--Mask with wave-->
            <div class="view overlay waves-light" mdbWavesEffect>
                <img src="https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20(53).jpg" class="img-fluid" alt="Sample image with waves effect.">
                <a href="" onClick="">
                    <div class="mask rgba-white-slight waves-effect"></div>
                </a>
            </div>

        </div>
        <!--Grid column-->

    </div>
    <!--Grid row-->

</section>
<!--Section: Waves effect-->

<div class="row justify-content-center pt-3">
    <div class="col-md-10">
        <!--Section: Docs link-->
        <section class="pb-4 pt-2">
            <panel header="Full documentation" color="primary-color">
                <p class="card-text">Read the full documentation for these components.</p>
                <a href="https://mdbootstrap.com/angular/content/bootstrap-images/" target="_blank" class="btn btn-primary waves-effect waves-light">Learn more</a>
            </panel>
        </section>
        <!--/.Section: Docs link-->
    </div>
</div>