<app-navigation *ngIf="!specialPage">
</app-navigation>

<mdb-navbar *ngIf="specialPage" SideClass="navbar fixed-top navbar-expand-md scrolling-navbar" [containerInside]="false">

    <links>

        <!-- SideNav slide-out button -->
        <div class="float-xs-left">
            <a (click)="goBack()" class="mr-5">
                <mdb-icon fas icon="arrow-left" class="ml-1"></mdb-icon> Go back</a>
        </div>
        <!--/. SideNav slide-out button -->
    </links>
    <logo>


        <!-- Breadcrumb-->
        <div class="breadcrumb-dn mr-auto">
            <p class="mb-0">Material Design for Bootstrap</p>
        </div>
        <!--/. Breadcrumb-->
    </logo>
    <links>

        <ul class="nav navbar-nav nav-flex-icons ml-auto">
            <li class="nav-item">
                <a class="nav-link waves-light" mdbWavesEffect>
                    <mdb-icon far icon="envelope" class="mr-1"></mdb-icon>
                    <span class="clearfix d-none d-sm-inline-block">Contact</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link waves-light" mdbWavesEffect>
                    <mdb-icon far icon="comments" class="mr-1"></mdb-icon>
                    <span class="clearfix d-none d-sm-inline-block">Support</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link waves-light" mdbWavesEffect>
                    <mdb-icon fas icon="user" class="mr-1"></mdb-icon>
                    <span class="clearfix d-none d-sm-inline-block">Account</span>
                </a>
            </li>
            <li class="nav-item dropdown btn-group" dropdown>
                <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                    Dropdown
                </a>
                <div class="dropdown-menu dropdown-primary dropdown-menu-right" role="menu">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                </div>
            </li>
        </ul>
    </links>
</mdb-navbar>

<main [ngClass]="{'py-0 px-0 my-0 mx-0': specialPage}">

    <app-notification></app-notification>

    <div [class.container-fluid]="!specialPage">
        <router-outlet></router-outlet>
    </div>
</main>

<app-footer *ngIf="!specialPage"></app-footer>
