<app-stats-card></app-stats-card>
<section class="my-5">

    <!--Card-->
    <div class="card card-cascade narrower">

        <!--Section: Chart-->
        <section>

            <!--Grid row-->
            <div class="row">

                <!--Grid column-->
                <div class="col-xl-5 col-lg-12 mr-0">

                    <!--Card image-->
                    <div class="view view-cascade gradient-card-header blue-gradient">
                        <h2 class="h2-responsive mb-0">Sales</h2>
                    </div>
                    <!--/Card image-->

                    <!--Card content-->
                    <mdb-card-body cascade="true" class="pb-0">

                        <!--Panel data-->
                        <mdb-card-body class="row pt-3">

                            <!--First column-->
                            <div class="col-md-6">

                                <!--Date select-->
                                <p class="lead"><mdb-badge info="true" class="p-2">Data range</mdb-badge></p>
                                <mdb-select [options]="dateOptionsSelect" placeholder="Choose time period"></mdb-select>

                                <!--Date pickers-->
                                <p class="lead mt-5"><mdb-badge info="true" class="p-2">Custom date</mdb-badge></p>
                                <br>
                                <div class="md-form">
                                    <mdb-date-picker name="mydate" [placeholder]="'Selected date'"  required></mdb-date-picker>
                                    <label class="active">From</label>
                                </div>
                                <div class="md-form">
                                    <mdb-date-picker name="mydate" [placeholder]="'Selected date'"  required></mdb-date-picker>
                                    <label class="active">To</label>
                                </div>

                            </div>
                            <!--/First column-->

                            <!--Second column-->
                            <div class="col-md-6 text-center">

                                <!--Summary-->
                                <p>Total sales: <strong>$2000</strong>
                                    <button type="button" mdbBtn color="info" size="sm" class="p-2 waves-effect waves-light" placement="top"
                                        mdbTooltip="Total sales in the given period" mdbWavesEffect><mdb-icon fas icon="question"></mdb-icon></button>
                                </p>
                                <p>Average sales: <strong>$100</strong>
                                    <button type="button" mdbBtn color="info" size="sm" class="p-2 waves-effect waves-light" mdbWavesEffect placement="top"
                                        mdbTooltip="Average daily sales in the given period"><mdb-icon fas icon="question"></mdb-icon></button>
                                </p>

                                <!--Change chart-->
                                <mdb-simple-chart [percent]="56" [barColor]="'FF5252'" [animate]="{duration: 1000, enabled: true}"></mdb-simple-chart>
                                <h5>
                                    <mdb-badge color="red" class="accent-2 p-2">Change <mdb-icon fas icon="arrow-circle-down" class="ml-1"></mdb-icon></mdb-badge>
                                    <button type="button" mdbBtn color="info" size="sm" class="p-2 waves-effect waves-light" placement="top"
                                        mdbTooltip="Percentage change compared to the same period in the past" mdbWavesEffect><mdb-icon fas icon="question"></mdb-icon>
                                </button>
                                </h5>
                            </div>
                            <!--/Second column-->

                        </mdb-card-body>
                        <!--/Panel data-->

                    </mdb-card-body>
                    <!--/.Card content-->

                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-xl-7 col-lg-12 my-5">

                    <!--Card image-->
                    <div class="view view-cascade gradient-card-header white">
                        <iframe class="chartjs-hidden-iframe" tabindex="-1" style="display: block; overflow: hidden; border: 0px; margin: 0px; top: 0px; left: 0px; bottom: 0px; right: 0px; height: 100%; width: 100%; position: absolute; pointer-events: none; z-index: -1;"></iframe>

                        <!-- Chart -->
                        <canvas mdbChart [chartType]="chartType" [datasets]="chartDatasets" [labels]="chart1Labels" [options]="chartOptions" [legend]="true">
                        </canvas>

                    </div>
                    <!--/Card image-->

                </div>
                <!--Grid column-->

            </div>
            <!--Grid row-->

        </section>
        <!--Section: Chart-->

        <!--Section: Table-->
        <section class="container mb-4">

            <app-table2 [shadows]="false"></app-table2>
            
        </section>
        <!--Section: Table-->

    </div>
    <!--/.Card-->

</section>

<section class="mb-3">

    <!--Grid row-->
    <div class="row">

        <!--Grid column-->
        <div class="col-lg-4 col-md-12 my-5">

            <!--Card-->
            <panel header="Things to improve">

                <!--Card content-->
                <mdb-card-body class="text-center">

                    <div class="list-group list-panel">
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Cras justo odio <mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Dapibus ac facilisi<mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Morbi leo risus <mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Porta ac consectet<mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Vestibulum at eros <mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                    </div>

                </mdb-card-body>
                <!--/.Card content-->

            </panel>
            <!--/.Card-->

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 my-5">

            <!--Card-->
            <panel header="Users activity">

                <!--Card content-->
                <mdb-card-body class="text-center">

                    <div class="list-group list-panel">
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Cras justo odio <mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Dapibus ac facilisi<mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Morbi leo risus <mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Porta ac consectet<mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Vestibulum at eros <mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                    </div>

                </mdb-card-body>
                <!--/.Card content-->

            </panel>
            <!--/.Card-->

        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 my-5">

            <!--Card-->
            <panel header="Statistics">

                <!--Card content-->
                <mdb-card-body class="text-center">

                    <div class="list-group list-panel">
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Cras justo odio <mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Dapibus ac facilisi<mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Morbi leo risus <mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Porta ac consectet<mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">Vestibulum at eros <mdb-icon fas icon="wrench" class="ml-auto" mdbTooltip="Click to fix" placement="top"></mdb-icon></a>
                    </div>

                </mdb-card-body>
                <!--/.Card content-->

            </panel>
            <!--/.Card-->

        </div>
        <!--Grid column-->

    </div>
    <!--Grid row-->

</section>

<app-stats-card2></app-stats-card2>
