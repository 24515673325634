<div class="row mt-5">
    <div class="col-md-12">
        <!--Card-->
        <mdb-card cascade="true" narrower="true" class="mt-5">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header blue-gradient">
                <h4 class="h4-responsive">Modals </h4>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">


                <!--Section: Position & Sizes-->
                <section>
                    <h4 class="mb-5 mt-4 dark-grey-text text-center font-bold">
                        <strong>Position & Sizes</strong>
                    </h4>

                    <div class="text-center mb-5">
                        <p class="lead">Click buttons below to launch modals demo</p>
                    </div>

                    <!-- First row-->
                    <div class="row">

                        <!--First column-->
                        <div class="col-md-3">
                            <h5 class="text-center mb-3">Frame Modal</h5>

                            <img src="https://mdbootstrap.com/img/brandflow/modal4.jpg" alt=""
                                 class="img-fluid z-depth-1">
                            <div class="text-center">
                                <h5 class="my-3">Position</h5>


                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="frameTop.show()"
                                        mdbWavesEffect>
                                    Top
                                </button>
                                <br>
                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="frameBottom.show()"
                                        mdbWavesEffect>
                                    Bottom
                                </button>

                                <div mdbModal #frameTop="mdb-modal" class="modal fade top" id="frameModalBottom"
                                     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                     aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: false}">
                                    <div class="modal-dialog modal-frame modal-top" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body text-center">
                                                <div class="row px-4">
                                                    <p class="pt-1 pr-2">Lorem ipsum dolor sit amet, consectetur
                                                        adipisicing elit. Impedit nisi quo provident fugiat
                                                        reprehenderit
                                                        nostrum quos..</p>
                                                    <button type="button" mdbBtn color="secondary" class="waves-light"
                                                            data-dismiss="modal" (click)="frameTop.hide()"
                                                            mdbWavesEffect>Close
                                                    </button>
                                                    <button type="button" mdbBtn color="primary" class="waves-light"
                                                            mdbWavesEffect>Save changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div mdbModal #frameBottom="mdb-modal" class="modal fade bottom" id="frameModalBottom"
                                     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                     aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: false}">
                                    <div class="modal-dialog modal-frame modal-bottom" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body text-center">
                                                <div class="row px-4">
                                                    <p class="pt-1 pr-2">Lorem ipsum dolor sit amet, consectetur
                                                        adipisicing elit. Impedit nisi quo provident fugiat
                                                        reprehenderit
                                                        nostrum quos..</p>
                                                    <button type="button" mdbBtn color="secondary" class="waves-light"
                                                            data-dismiss="modal" (click)="frameBottom.hide()"
                                                            mdbWavesEffect>Close
                                                    </button>
                                                    <button type="button" mdbBtn color="primary" class="waves-light"
                                                            mdbWavesEffect>Save changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--First column-->

                        <!--Second column-->
                        <div class="col-md-3">
                            <h5 class="text-center mb-3">Side Modal</h5>

                            <img src="https://mdbootstrap.com/img/brandflow/modal3.jpg" alt=""
                                 class="img-fluid z-depth-1">
                            <div class="text-center">
                                <h5 class="my-3">Position</h5>

                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="sideTopRight.show()"
                                        mdbWavesEffect>
                                    Top right
                                </button>
                                <br>
                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="sideTopLeft.show()"
                                        mdbWavesEffect>
                                    Top left
                                </button>
                                <br>
                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="sideBottomRight.show()"
                                        mdbWavesEffect>
                                    Bottom right
                                </button>
                                <br>
                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="sideBottomLeft.show()"
                                        mdbWavesEffect>
                                    Bottom left
                                </button>
                                <br>


                                <div mdbModal #sideTopRight="mdb-modal" class="modal fade right" id="centralModalSm"
                                     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                     aria-hidden="true">
                                    <div class="modal-dialog modal-side modal-top-right" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="sideTopRight.hide()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                ...
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" mdbBtn color="secondary" class="waves-light"
                                                        data-dismiss="modal" (click)="sideTopRight.hide()"
                                                        mdbWavesEffect>Close
                                                </button>
                                                <button type="button" mdbBtn color="primary" class="waves-light"
                                                        mdbWavesEffect>Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div mdbModal #sideTopLeft="mdb-modal" class="modal fade left" id="centralModalSm"
                                     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                     aria-hidden="true">
                                    <div class="modal-dialog modal-side modal-top-left" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="sideTopLeft.hide()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                ...
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" mdbBtn color="secondary" class="waves-light"
                                                        data-dismiss="modal" (click)="sideTopLeft.hide()"
                                                        mdbWavesEffect>Close
                                                </button>
                                                <button type="button" mdbBtn color="primary" class="waves-light"
                                                        mdbWavesEffect>Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div mdbModal #sideBottomRight="mdb-modal" class="modal fade right" id="centralModalSm"
                                     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                     aria-hidden="true">
                                    <div class="modal-dialog modal-side modal-bottom-right" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="sideBottomRight.hide()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                ...
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" mdbBtn color="secondary" class="waves-light"
                                                        data-dismiss="modal" (click)="sideBottomRight.hide()"
                                                        mdbWavesEffect>Close
                                                </button>
                                                <button type="button" mdbBtn color="primary" class="waves-light"
                                                        mdbWavesEffect>Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div mdbModal #sideBottomLeft="mdb-modal" class="modal fade left" id="centralModalSm"
                                     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                     aria-hidden="true">
                                    <div class="modal-dialog modal-side modal-bottom-left" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="sideBottomLeft.hide()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                ...
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" mdbBtn color="secondary" class="waves-light"
                                                        data-dismiss="modal" (click)="sideBottomLeft.hide()"
                                                        mdbWavesEffect>Close
                                                </button>
                                                <button type="button" mdbBtn color="primary" class="waves-light"
                                                        mdbWavesEffect>Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Second column-->

                        <!--Third column-->
                        <div class="col-md-3">
                            <h5 class="text-center mb-3">Central Modal</h5>

                            <img src="https://mdbootstrap.com/img/brandflow/modal2.jpg" alt=""
                                 class="img-fluid z-depth-1">
                            <div class="text-center">
                                <h5 class="my-3">Size</h5>

                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="loginAvatarForm.show()"
                                        mdbWavesEffect>
                                    Small
                                </button>
                                <br>
                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="centralRegular.show()"
                                        mdbWavesEffect>
                                    Medium
                                </button>
                                <br>
                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="centralLarge.show()"
                                        mdbWavesEffect>
                                    Large
                                </button>
                                <br>
                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="centralFluid.show()"
                                        mdbWavesEffect>
                                    Fluid
                                </button>
                                <br>


                                <div mdbModal #centralRegular="mdb-modal" class="modal fade" id="basicExample"
                                     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                     aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: false}">
                                    <div class="modal-dialog " role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="centralRegular.hide()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                ...
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" mdbBtn color="secondary" size="sm"
                                                        class=" waves-light" data-dismiss="modal"
                                                        (click)="centralRegular.hide()" mdbWavesEffect>Close
                                                </button>
                                                <button type="button" mdbBtn color="primary" size="sm"
                                                        class="waves-light" mdbWavesEffect>Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div mdbModal #centralLarge="mdb-modal" class="modal fade" id="basicExample"
                                     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                     aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: false}">
                                    <div class="modal-dialog modal-lg" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="centralLarge.hide()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                ...
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" mdbBtn color="secondary" size="sm"
                                                        class="waves-light" data-dismiss="modal"
                                                        (click)="centralLarge.hide()" mdbWavesEffect>Close
                                                </button>
                                                <button type="button" mdbBtn color="primary" size="sm"
                                                        class="waves-light" mdbWavesEffect>Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div mdbModal #centralFluid="mdb-modal" class="modal fade" id="basicExample"
                                     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                     aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: false}">
                                    <div class="modal-dialog modal-fluid" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="centralFluid.hide()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                ...
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" mdbBtn color="secondary" size="sm"
                                                        class="waves-light" data-dismiss="modal"
                                                        (click)="centralFluid.hide()" mdbWavesEffect>Close
                                                </button>
                                                <button type="button" mdbBtn color="primary" size="sm"
                                                        class="waves-light" mdbWavesEffect>Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--Modal Form Login with Avatar Demo-->
                                <div class="modal fade" id="modalLoginAvatarDemo" tabindex="-1" role="dialog"
                                     aria-labelledby="myModalLabel" aria-hidden="true">
                                    <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
                                        <!--Content-->
                                        <div class="modal-content">

                                            <!--Header-->
                                            <div class="modal-header">
                                                <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20%281%29.jpg"
                                                     class="rounded-circle img-responsive">
                                            </div>
                                            <!--Body-->
                                            <div class="modal-body text-center mb-1">

                                                <h5 class="mt-1 mb-2">Maria Doe</h5>

                                                <div class="md-form ml-0 mr-0">
                                                    <input type="password" type="text" id="form1"
                                                           class="form-control ml-0" mdbInput>
                                                    <label for="form1" class="ml-0">Enter password</label>
                                                </div>

                                                <div class="text-center">
                                                    <button mdbBtn color="cyan" class="mt-1">Login
                                                        <mdb-icon fas icon="sign-in-alt" class="ml-1"></mdb-icon>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        <!--/.Content-->
                                    </div>
                                </div>
                                <!--Modal Form Login with Avatar Demo-->

                            </div>
                        </div>
                        <!--Third column-->

                        <!--Fourth column-->
                        <div class="col-md-3">
                            <h5 class="text-center mb-3">Fluid Modal</h5>

                            <img src="https://mdbootstrap.com/img/brandflow/modal1.jpg" alt=""
                                 class="img-fluid z-depth-1">
                            <div class="text-center">
                                <h5 class="my-3">Position</h5>


                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="fluidRight.show()"
                                        mdbWavesEffect>
                                    Right
                                </button>
                                <br>
                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="fluidLeft.show()"
                                        mdbWavesEffect>
                                    Left
                                </button>
                                <br>
                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="fluidTop.show()"
                                        mdbWavesEffect>
                                    Top
                                </button>
                                <br>
                                <button type="button" mdbBtn color="primary" class="waves-light" data-toggle="modal"
                                        data-target="#basicExample" (click)="fluidBottom.show()"
                                        mdbWavesEffect>
                                    Bottom
                                </button>
                                <br>

                                <div mdbModal #fluidRight="mdb-modal" class="modal fade right" id="fullHeightModalRight"
                                     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                     aria-hidden="true">
                                    <div class="modal-dialog modal-full-height modal-right" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="fluidRight.hide()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente
                                                    magnam, sunt, ea dolore eum quod.
                                                    Minima fugiat enim aut soluta est reprehenderit reiciendis quos,
                                                    qui, amet possimus laborum assumenda
                                                    voluptate.
                                                </p>

                                                <ul class="list-group z-depth-0">
                                                    <li class="list-group-item justify-content-between">
                                                        Cras justo odio
                                                        <mdb-badge primary="true" pill="true">14</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Dapibus ac facilisis in
                                                        <mdb-badge primary="true" pill="true">2</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Morbi leo risus
                                                        <mdb-badge primary="true" pill="true">1</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Cras justo odio
                                                        <mdb-badge primary="true" pill="true">14</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Dapibus ac facilisis in
                                                        <mdb-badge primary="true" pill="true">2</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Morbi leo risus
                                                        <mdb-badge primary="true" pill="true">1</mdb-badge>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="modal-footer justify-content-center">
                                                <button type="button" mdbBtn color="secondary" class="waves-light"
                                                        data-dismiss="modal" (click)="fluidRight.hide()" mdbWavesEffect>
                                                    Close
                                                </button>
                                                <button type="button" mdbBtn color="primary" class="waves-light"
                                                        mdbWavesEffect>Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div mdbModal #fluidLeft="mdb-modal" class="modal fade left" id="fullHeightModalRight"
                                     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                     aria-hidden="true">
                                    <div class="modal-dialog modal-full-height modal-left" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="fluidLeft.hide()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente
                                                    magnam, sunt, ea dolore eum quod.
                                                    Minima fugiat enim aut soluta est reprehenderit reiciendis quos,
                                                    qui, amet possimus laborum assumenda
                                                    voluptate.
                                                </p>

                                                <ul class="list-group z-depth-0">
                                                    <li class="list-group-item justify-content-between">
                                                        Cras justo odio
                                                        <mdb-badge primary="true" pill="true">14</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Dapibus ac facilisis in
                                                        <mdb-badge primary="true" pill="true">2</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Morbi leo risus
                                                        <mdb-badge primary="true" pill="true">1</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Cras justo odio
                                                        <mdb-badge primary="true" pill="true">14</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Dapibus ac facilisis in
                                                        <mdb-badge primary="true" pill="true">2</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Morbi leo risus
                                                        <mdb-badge primary="true" pill="true">1</mdb-badge>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="modal-footer justify-content-center">
                                                <button type="button" mdbBtn color="secondary" class="waves-light"
                                                        data-dismiss="modal" (click)="fluidLeft.hide()" mdbWavesEffect>
                                                    Close
                                                </button>
                                                <button type="button" mdbBtn color="primary" class="waves-light"
                                                        mdbWavesEffect>Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div mdbModal #fluidTop="mdb-modal" class="modal fade top" id="fullHeightModalTop"
                                     tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                     aria-hidden="true">
                                    <div class="modal-dialog modal-full-height modal-top" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="fluidTop.hide()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente
                                                    magnam, sunt, ea dolore eum quod.
                                                    Minima fugiat enim aut soluta est reprehenderit reiciendis quos,
                                                    qui, amet possimus laborum assumenda
                                                    voluptate.
                                                </p>

                                                <ul class="list-group z-depth-0">
                                                    <li class="list-group-item justify-content-between">
                                                        Cras justo odio
                                                        <mdb-badge primary="true" pill="true">14</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Dapibus ac facilisis in
                                                        <mdb-badge primary="true" pill="true">2</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Morbi leo risus
                                                        <mdb-badge primary="true" pill="true">1</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Cras justo odio
                                                        <mdb-badge primary="true" pill="true">14</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Dapibus ac facilisis in
                                                        <mdb-badge primary="true" pill="true">2</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Morbi leo risus
                                                        <mdb-badge primary="true" pill="true">1</mdb-badge>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="modal-footer justify-content-center">
                                                <button type="button" mdbBtn color="secondary" class="waves-light"
                                                        data-dismiss="modal" (click)="fluidTop.hide()" mdbWavesEffect>
                                                    Close
                                                </button>
                                                <button type="button" mdbBtn color="primary" class="waves-light"
                                                        mdbWavesEffect>Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div mdbModal #fluidBottom="mdb-modal" class="modal fade bottom"
                                     id="fullHeightModalBottom" tabindex="-1" role="dialog"
                                     aria-labelledby="myModalLabel"
                                     aria-hidden="true">
                                    <div class="modal-dialog modal-full-height modal-bottom" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title w-100" id="myModalLabel">Modal title</h4>
                                                <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close" (click)="fluidBottom.hide()">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente
                                                    magnam, sunt, ea dolore eum quod.
                                                    Minima fugiat enim aut soluta est reprehenderit reiciendis quos,
                                                    qui, amet possimus laborum assumenda
                                                    voluptate.
                                                </p>

                                                <ul class="list-group z-depth-0">
                                                    <li class="list-group-item justify-content-between">
                                                        Cras justo odio
                                                        <mdb-badge primary="true" pill="true">14</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Dapibus ac facilisis in
                                                        <mdb-badge primary="true" pill="true">2</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Morbi leo risus
                                                        <mdb-badge primary="true" pill="true">1</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Cras justo odio
                                                        <mdb-badge primary="true" pill="true">14</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Dapibus ac facilisis in
                                                        <mdb-badge primary="true" pill="true">2</mdb-badge>
                                                    </li>
                                                    <li class="list-group-item justify-content-between">
                                                        Morbi leo risus
                                                        <mdb-badge primary="true" pill="true">1</mdb-badge>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="modal-footer justify-content-center">
                                                <button type="button" mdbBtn color="secondary" class="waves-light"
                                                        data-dismiss="modal" (click)="fluidBottom.hide()"
                                                        mdbWavesEffect>Close
                                                </button>
                                                <button type="button" mdbBtn color="primary" class="waves-light"
                                                        mdbWavesEffect>Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Fourth column-->

                    </div>
                    <!-- /.First row-->

                </section>
                <!--/Section: Position & Sizes-->

                <h2 class="h2-responsive mt-5">Samples</h2>

                <button type="button" mdbBtn color="primary" class="waves-light" (click)="centralSuccess.show()"
                        mdbWavesEffect>Central Success Modal
                </button>
                <button type="button" mdbBtn color="info" class="waves-light" (click)="sideInfo.show()" mdbWavesEffect>
                    Side Info Modal
                </button>
                <button type="button" mdbBtn color="danger" class="waves-light" (click)="fluidDanger.show()"
                        mdbWavesEffect>Fluid Danger Modal
                </button>
                <hr>
                <button type="button" mdbBtn color="primary" class="waves-light" (click)="contactForm.show()"
                        mdbWavesEffect>Contact form
                </button>
                <button type="button" mdbBtn color="primary" class="waves-light" (click)="loginAvatarForm.show()"
                        mdbWavesEffect>Login with avatar form
                </button>
                <button type="button" mdbBtn color="primary" class="waves-light" (click)="loginForm.show()"
                        mdbWavesEffect>Login form
                </button>
                <button type="button" mdbBtn color="primary" class="waves-light" (click)="loginRegisterForm.show()"
                        mdbWavesEffect>Login / Register form
                </button>
                <button type="button" mdbBtn color="primary" class="waves-light" (click)="registerForm.show()"
                        mdbWavesEffect>Register form
                </button>


            </mdb-card-body>
            <!--/.Card content-->
        </mdb-card>
        <!--/.Card-->
    </div>
</div>

<!--Modal: Register Form-->
<div mdbModal #registerForm="mdb-modal" class="modal fade" id="modalRegister" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog cascading-modal" role="document">
        <!--Content-->
        <div class="modal-content">

            <!--Header-->
            <div class="modal-header light-blue darken-3 white-text">
                <h4 class="title">
                    <mdb-icon fas icon="user-plus"></mdb-icon>
                    Register
                </h4>
                <button type="button" class="close waves-effect waves-light" data-dismiss="modal" aria-label="Close"
                        (click)="registerForm.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <!--Body-->
            <div class="modal-body">
                <div class="md-form form-sm">
                    <mdb-icon far icon="envelope"></mdb-icon>
                    <input mdbInput type="text" id="form32" class="form-control">
                    <label for="form32">Your email</label>
                </div>

                <div class="md-form form-sm">
                    <mdb-icon fas icon="lock"></mdb-icon>
                    <input mdbInput type="password" id="form33" class="form-control">
                    <label for="form33">Your password</label>
                </div>

                <div class="md-form form-sm">
                    <mdb-icon fas icon="lock"></mdb-icon>
                    <input mdbInput type="password" id="form34" class="form-control">
                    <label for="form34">Repeat password</label>
                </div>

                <div class="text-center mt-2">
                    <button mdbBtn color="info" class="waves-light" mdbWavesEffect>Sign up
                        <mdb-icon fas icon="sign-in-alt" class="ml-1"></mdb-icon>
                    </button>
                </div>

            </div>
            <!--Footer-->
            <div class="modal-footer">
                <div class="options text-center text-md-right mt-1">
                    <p>Already have an account?
                        <a href="#">Log In</a>
                    </p>
                </div>
                <button type="button" mdbBtn color="info" outline="true" class="ml-auto" data-dismiss="modal"
                        (click)="registerForm.hide()" mdbWavesEffect>Close
                </button>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal: Register Form-->

<!--Modal: Login Form-->
<div mdbModal #loginForm="mdb-modal" class="modal fade" id="modalLogin" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog cascading-modal" role="document">
        <!--Content-->
        <div class="modal-content">

            <!--Header-->
            <div class="modal-header light-blue darken-3 white-text">
                <h4 class="title">
                    <mdb-icon fas icon="user"></mdb-icon>
                    Log in
                </h4>
                <button type="button" class="close waves-effect waves-light" data-dismiss="modal" aria-label="Close"
                        (click)="loginForm.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <!--Body-->
            <div class="modal-body">
                <div class="md-form form-sm">
                    <mdb-icon far icon="envelope"></mdb-icon>
                    <input mdbInput type="text" id="form30" class="form-control">
                    <label for="form30">Your email</label>
                </div>

                <div class="md-form form-sm">
                    <mdb-icon fas icon="lock"></mdb-icon>
                    <input mdbInput type="password" id="form31" class="form-control">
                    <label for="form31">Your password</label>
                </div>

                <div class="text-center mt-2">
                    <button mdbBtn color="info" class="waves-light" mdbWavesEffect>Log in
                        <mdb-icon fas icon="sign-in-alt" class="ml-1"></mdb-icon>
                    </button>
                </div>

            </div>
            <!--Footer-->
            <div class="modal-footer">
                <div class="options text-center text-md-right mt-1">
                    <p>Not a member?
                        <a href="#">Sign Up</a>
                    </p>
                    <p>Forgot
                        <a href="#">Password?</a>
                    </p>
                </div>
                <button type="button" mdbBtn color="info" outline="true" class="ml-auto" data-dismiss="modal"
                        (click)="registerForm.hide()" mdbWavesEffect>Close
                </button>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal: Login Form-->

<!--Modal: Login with Avatar Form-->
<div mdbModal #loginAvatarForm="mdb-modal" class="modal fade" id="modalLoginAvatar" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
        <!--Content-->
        <div class="modal-content">

            <!--Header-->
            <div class="modal-header">
                <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20%281%29.jpg"
                     class="rounded-circle img-responsive">
            </div>
            <!--Body-->
            <div class="modal-body text-center mb-1">

                <h5 class="mt-1 mb-2">Maria Doe</h5>

                <div class="md-form ml-0 mr-0">
                    <input mdbInput type="password" type="text" id="form29" class="form-control ml-0">
                    <label for="form29" class="ml-0">Enter password</label>
                </div>

                <div class="text-center">
                    <button mdbBtn color="cyan" class="mt-1 waves-light" mdbWavesEffect>Login
                        <mdb-icon fas icon="sign-in-alt" class="ml-1"></mdb-icon>
                    </button>
                </div>
            </div>

        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal: Login with Avatar Form-->

<!--Modal: Login / Register Form-->
<div mdbModal #loginRegisterForm="mdb-modal" class="modal fade" id="modalLRForm" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog cascading-modal" role="document">
        <!--Content-->
        <div class="modal-content">

            <!--Modal cascading tabs-->
            <div class="modal-c-tabs">

                <!-- Tab panels -->
                <mdb-tabset #staticTabs [buttonClass]="'nav md-tabs tabs-2 light-blue darken-3'" [contentClass]="''"
                            class="tab-content">
                    <!--Panel 7-->
                    <mdb-tab class="tab-pane fade in show active" id="panel7" role="tabpanel"
                             heading="<i class='fas fa-user mr-1'></i> Login">

                        <!--Body-->
                        <div class="modal-body mb-1">
                            <div class="md-form form-sm">
                                <mdb-icon far icon="envelope"></mdb-icon>
                                <input mdbInput type="text" id="form22" class="form-control">
                                <label for="form22">Your email</label>
                            </div>

                            <div class="md-form form-sm">
                                <mdb-icon fas icon="lock"></mdb-icon>
                                <input mdbInput type="password" id="form23" class="form-control">
                                <label for="form23">Your password</label>
                            </div>
                            <div class="text-center mt-2">
                                <button mdbBtn color="info" class="waves-light" mdbWavesEffect>Log in
                                    <mdb-icon fas icon="sign-in-alt" class="ml-1"></mdb-icon>
                                </button>
                            </div>
                        </div>
                        <!--Footer-->
                        <div class="modal-footer display-footer">
                            <div class="options text-center text-md-right mt-1">
                                <p>Not a member?
                                    <a href="#" class="blue-text">Sign Up</a>
                                </p>
                                <p>Forgot
                                    <a href="#" class="blue-text">Password?</a>
                                </p>
                            </div>
                            <button type="button" mdbBtn color="info" outline="true" class="ml-auto"
                                    data-dismiss="modal" (click)="loginRegisterForm.hide()" mdbWavesEffect>Close
                            </button>
                        </div>

                    </mdb-tab>
                    <!--/.Panel 7-->

                    <!--Panel 8-->
                    <mdb-tab class="tab-pane fade" id="panel8" role="tabpanel"
                             heading="<i class='fas fa-user-plus mr-1'></i> Register">

                        <!--Body-->
                        <div class="modal-body">
                            <div class="md-form form-sm">
                                <mdb-icon far icon="envelope"></mdb-icon>
                                <input mdbInput type="text" id="form24" class="form-control">
                                <label for="form24">Your email</label>
                            </div>

                            <div class="md-form form-sm">
                                <mdb-icon fas icon="lock"></mdb-icon>
                                <input mdbInput type="password" id="form25" class="form-control">
                                <label for="form25">Your password</label>
                            </div>

                            <div class="md-form form-sm">
                                <mdb-icon fas icon="lock"></mdb-icon>
                                <input mdbInput type="password" id="form26" class="form-control">
                                <label for="form26">Repeat password</label>
                            </div>

                            <div class="text-center form-sm mt-2">
                                <button mdbBtn color="info" class="waves-light" mdbWavesEffect>Sign up
                                    <mdb-icon fas icon="sign-in-alt" class="ml-1"></mdb-icon>
                                </button>
                            </div>

                        </div>
                        <!--Footer-->
                        <div class="modal-footer">
                            <div class="options text-right">
                                <p class="pt-1">Already have an account?
                                    <a href="#" class="blue-text">Log In</a>
                                </p>
                            </div>
                            <button type="button" mdbBtn color="info" outline="true" class="ml-auto"
                                    data-dismiss="modal" (click)="loginRegisterForm.hide()" mdbWavesEffect>Close
                            </button>
                        </div>
                    </mdb-tab>
                    <!--/.Panel 8-->
                </mdb-tabset>

            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal: Login / Register Form-->
<!--Modal: Contact form-->
<div mdbModal #contactForm="mdb-modal" class="modal fade" id="modalContactForm" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog cascading-modal" role="document">
        <!--Content-->
        <div class="modal-content">

            <!--Header-->
            <div class="modal-header light-blue darken-3 white-text">
                <h4 class="title">
                    <mdb-icon fas icon="pencil-alt"></mdb-icon>
                    Contact form
                </h4>
                <button type="button" class="close waves-effect waves-light" data-dismiss="modal" aria-label="Close"
                        (click)="contactForm.hide()">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <!--Body-->
            <div class="modal-body mb-0">
                <div class="md-form form-sm">
                    <mdb-icon far icon="envelope"></mdb-icon>
                    <input mdbInput type="text" id="form19" class="form-control">
                    <label for="form19">Your name</label>
                </div>

                <div class="md-form form-sm">
                    <mdb-icon fas icon="lock"></mdb-icon>
                    <input mdbInput type="password" id="form20" class="form-control">
                    <label for="form20">Your email</label>
                </div>

                <div class="md-form form-sm">
                    <mdb-icon fas icon="tag"></mdb-icon>
                    <input mdbInput type="text" id="form21" class="form-control">
                    <label for="form21">Subject</label>
                </div>

                <div class="md-form form-sm">
                    <mdb-icon fas icon="pencil-alt"></mdb-icon>
                    <textarea mdbInput type="text" id="form8" class="md-textarea mb-0"></textarea>
                    <label for="form8">Your message</label>
                </div>

                <div class="text-center mt-1-half">
                    <button mdbBtn color="info" class="mb-2 waves-light" mdbWavesEffect>Send
                        <mdb-icon far icon="paper-plane" class="ml-1"></mdb-icon>
                    </button>
                </div>

            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal: Contact form-->

<div mdbModal #fluidDanger="mdb-modal" class="modal fade right" id="centralModalSuccess" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-full-height modal-right modal-notify modal-danger" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Modal Danger</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="fluidDanger.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="times" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit iusto nulla aperiam blanditiis
                        ad consequatur
                        in dolores culpa, dignissimos, eius non possimus fugiat. Esse ratione fuga, enim, ab officiis
                        totam.</p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" class="waves-light text-white" mdbWavesEffect>Get it now
                    <mdb-icon far icon="gem" class="ml-1"></mdb-icon>
                </a>
                <a type="button" mdbBtn color="secondary" outline="true" class="btn btn-outline-secondary-modal"
                   data-dismiss="modal" (click)="fluidDanger.hide()" mdbWavesEffect>No, thanks</a>
            </div>
        </div>
    </div>
</div>
<div mdbModal #sideInfo="mdb-modal" class="modal fade" id="centralModalSuccess" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-notify modal-info modal-side modal-top-right" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Modal Info</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="sideInfo.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit iusto nulla aperiam blanditiis
                        ad consequatur
                        in dolores culpa, dignissimos, eius non possimus fugiat. Esse ratione fuga, enim, ab officiis
                        totam.</p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" class="waves-light  text-white" mdbWavesEffect>Get it now
                    <mdb-icon far icon="gem" class="ml-1"></mdb-icon>
                </a>
                <a type="button" mdbBtn color="secondary" outline="true" data-dismiss="modal" (click)="sideInfo.hide()"
                   mdbWavesEffect>No, thanks</a>
            </div>
        </div>
    </div>
</div>


<div mdbModal #centralSuccess="mdb-modal" class="modal fade" id="centralModalSuccess" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-notify modal-success" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading lead">Modal Success</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="centralSuccess.hide()">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit iusto nulla aperiam blanditiis
                        ad consequatur
                        in dolores culpa, dignissimos, eius non possimus fugiat. Esse ratione fuga, enim, ab officiis
                        totam.</p>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" class="waves-light  text-white" mdbWavesEffect>Get it now
                    <mdb-icon far icon="gem" class="ml-1"></mdb-icon>
                </a>
                <a type="button" mdbBtn color="secondary" outline="true" data-dismiss="modal"
                   (click)="centralSuccess.hide()" mdbWavesEffect>No, thanks</a>
            </div>
        </div>
    </div>
</div>
