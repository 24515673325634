<div class="row mt-5">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">Popovers</h4>
      </div>
      <!--/Card image-->
      <!--Card content-->
      <mdb-card-body cascade="true" class="text-center">
        <div class="row">
          <div class="col-md-12 col-xl-10 mx-auto">

           

              <div class="card-block py-5">

                <button type="button" mdbBtn color="default" class="waves-light" mdbPopover="And here some amazing content. It's very engaging. Right?"
                  placement="left" popoverTitle="Popover on left"  triggers="focus" mdbWavesEffect>
                  Popover on left
                </button>

                <button type="button" mdbBtn color="default" class="waves-light" mdbPopover="And here some amazing content. It's very engaging. Right?"
                  placement="top" popoverTitle="Popover on top"  triggers="focus" mdbWavesEffect>
                  Popover on top
                </button>
                <button type="button" mdbBtn color="default" class="waves-light" mdbPopover="And here some amazing content. It's very engaging. Right?"
                  placement="bottom" popoverTitle="Popover on bottom"  triggers="focus" mdbWavesEffect>
                  Popover on bottom
                </button>

                <button type="button" mdbBtn color="default" class="waves-light" mdbPopover="I'm a bit stubborn. You will have to click button again to close me!"
                  placement="right" popoverTitle="Popover on right" mdbWavesEffect>
                  Popover on right
                </button>
              </div>


          </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-10">
              <!--Section: Docs link-->
              <section class="pb-4 pt-5">
                <panel header="Full documentation" color="primary-color">
                  <p class="card-text">Read the full documentation for these components.</p>
                  <a href="https://mdbootstrap.com/angular/advanced/popover/" target="_blank" mdbBtn color="primary" class="waves-effect waves-light">Learn more</a>
                </panel>
              </section>
              <!--/.Section: Docs link-->
            </div>
          </div>
      </mdb-card-body>
      <!--/.Card content-->
    </mdb-card>
    <!--/.Card-->
  </div>
</div>
