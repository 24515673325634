<div class="row mt-5">
  <div class="col-md-12">
    <!--Card-->
    <div class="card card-cascade narrower mt-5">
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">Alerts </h4>
      </div>
      <!--/Card image-->
      <!--Card content-->
      <div class="card-body card-body-cascade text-center">
        <div class="row">
          <div class="col-md-6">
            <h4 class="h4responsive">Basic example</h4>

            <button class="btn btn-primary waves-light" type="button" (click)="test.toggle()" mdbWavesEffect>
              Toggle collapse
            </button>

            <div class="" [mdbCollapse]="isCollapsed" #test="bs-collapse">
              <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
                keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.</p>
            </div>

          </div>
          <div class="col-md-6">

            <h4 class="h4responsive">Accordion</h4>

            <!--Accordion wrapper-->
            <mdb-squeezebox [multiple]="false" aria-multiselectable="true">
              <mdb-item *ngFor="let item of itemsList">
                <mdb-item-head> {{ item.title }} </mdb-item-head>
                <mdb-item-body> {{ item.description }} </mdb-item-body>
              </mdb-item>
            </mdb-squeezebox>
            <!--/.Accordion wrapper-->

          </div>
        </div>
      </div>
      <!--/.Card content-->
    </div>
    <!--/.Card-->
  </div>
</div>