<div class="row ">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">Markers map</h4>
      </div>
      <!--/Card image-->
      <!--Card content-->
      <mdb-card-body cascade="true" class="text-center">
        <agm-map [zoom]="2" [latitude]="map.lat" [longitude]="map.lng" [mapTypeId]="'roadmap'" style="height: 400px">
          <agm-marker *ngFor="let marker of markers" [latitude]="marker.lat" [longitude]="marker.lng"></agm-marker>
        </agm-map>
      </mdb-card-body>
      <h5 class="text-center">Markers list:</h5>
      <table class="table table-sm text-center">
        <!--Table head-->
        <thead class="blue-gradient">
          <tr>
            <th>#</th>
            <th>Longitude</th>
            <th>Latitude</th>
          </tr>
        </thead>
        <!--Table head-->
        <!--Table body-->
        <tbody>
          <tr *ngFor='let marker of markers; let i = index'>
            <th scope="row">{{i+1}}</th>
            <td>{{marker.lng}}</td>
            <td>{{marker.lat}}</td>
          </tr>
        </tbody>
        <!--Table body-->
      </table>
    </mdb-card>
    <!--/.Card-->
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-md-10">
    <!--Section: Docs link-->
    <section class="pb-4 pt-5">
      <panel header="Full documentation" color="primary-color">
        <p class="card-text">Read the full documentation for these components.</p>
        <a href="https://mdbootstrap.com/angular/components/maps/" target="_blank" mdbBtn color="primary" class="waves-effect waves-light">Learn more</a>
      </panel>
    </section>
    <!--/.Section: Docs link-->
  </div>
</div>
