<!--Section: Basic examples-->
<section class="mb-5 pb-5">
  <h5 class="my-5 dark-grey-text font-bold">Basic examples</h5>

  <!--Grid row-->
  <div class="row">

    <!--Grid column-->
    <div class="col-lg-6 col-md-6 my-5">

      <ul class="list-group z-depth-1">
        <li class="list-group-item">Cras justo odio</li>
        <li class="list-group-item">Dapibus ac facilisis in</li>
        <li class="list-group-item">Morbi leo risus</li>
        <li class="list-group-item">Porta ac consectetur ac</li>
        <li class="list-group-item">Vestibulum at eros</li>
      </ul>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-6 col-md-6 my-5">

      <ul class="list-group z-depth-1">
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Cras justo odio
          <mdb-badge primary="true" pill="true">14</mdb-badge>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Dapibus ac facilisis in
          <mdb-badge primary="true" pill="true">2</mdb-badge>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Morbi leo risus
          <mdb-badge primary="true" pill="true">1</mdb-badge>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Cras justo odio
          <mdb-badge primary="true" pill="true">10</mdb-badge>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Morbi leo risus
          <mdb-badge primary="true" pill="true">5</mdb-badge>
        </li>
      </ul>

    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->

</section>
<!--Section: Basic examples-->

<!--Section: Linked items-->
<section class="mb-5">
  <h5 class="my-5 dark-grey-text font-bold">Linked items</h5>

  <!--Grid row-->
  <div class="row">

    <!--Grid column-->
    <div class="col-lg-4 col-md-12 my-5">

      <div class="list-group z-depth-1">
        <a href="#" class="list-group-item active"> Cras justo odio</a>
        <a href="#" class="list-group-item list-group-item-action">Dapibus ac facilisis in</a>
        <a href="#" class="list-group-item list-group-item-action">Morbi leo risus</a>
        <a href="#" class="list-group-item list-group-item-action">Porta ac consectetur ac</a>
        <a href="#" class="list-group-item list-group-item-action disabled">Vestibulum at eros</a>
      </div>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-4 col-md-6 my-5">

      <div class="list-group z-depth-1">
        <a href="#" class="list-group-item active">Cras justo odio</a>
        <a href="#" class="list-group-item">Dapibus ac facilisis in</a>
        <a href="#" class="list-group-item">Morbi leo risus</a>
        <a href="#" class="list-group-item">Porta ac consectetur ac</a>
        <a href="#" class="list-group-item">Vestibulum at eros</a>
      </div>

    </div>
    <!--Grid column-->

    <!--Grid column-->
    <div class="col-lg-4 col-md-6 my-5">

      <div class="list-group z-depth-1">
        <a href="#" class="list-group-item disabled">Cras justo odio</a>
        <a href="#" class="list-group-item">Dapibus ac facilisis in</a>
        <a href="#" class="list-group-item">Morbi leo risus</a>
        <a href="#" class="list-group-item">Porta ac consectetur ac</a>
        <a href="#" class="list-group-item">Vestibulum at eros</a>
      </div>

    </div>
    <!--Grid column-->

  </div>
  <!--Grid row-->

</section>
<!--Section: Linked items-->
<div class="row justify-content-center">
  <!--Section: Reviews-->
  <div class="col-md-8">
    <section class="mb-5">
      <h5 class="my-5 dark-grey-text font-bold">Reviews</h5>

      <!--First review-->
      <div class="media mb-1">
        <a class="media-left waves-light">
          <img class="rounded-circle" src="https://mdbootstrap.com/img/Photos/Avatars/avatar-13.jpg" alt="Generic placeholder image">
        </a>
        <div class="media-body">
          <h4 class="media-heading">John Doe</h4>
          <ul class="rating inline-ul">
            <li>
              <mdb-icon fas icon="star" class="amber-text"></mdb-icon>
            </li>
            <li>
              <mdb-icon fas icon="star" class="amber-text"></mdb-icon>
            </li>
            <li>
              <mdb-icon fas icon="star" class="amber-text"></mdb-icon>
            </li>
            <li>
              <mdb-icon fas icon="star"></mdb-icon>
            </li>
            <li>
              <mdb-icon fas icon="star"></mdb-icon>
            </li>
          </ul>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi cupiditate temporibus iure soluta. Quasi mollitia
            maxime nemo quam accusamus possimus, voluptatum expedita assumenda. Earum sit id ullam eum vel delectus!</p>
        </div>
      </div>

      <!--Second review-->
      <div class="media mb-1">
        <a class="media-left waves-light">
          <img class="rounded-circle" src="https://mdbootstrap.com/img/Photos/Avatars/avatar-10.jpg" alt="Generic placeholder image">
        </a>
        <div class="media-body">
          <h4 class="media-heading">Anna Casie</h4>
          <ul class="rating inline-ul">
            <li>
              <mdb-icon fas icon="star" class="amber-text"></mdb-icon>
            </li>
            <li>
              <mdb-icon fas icon="star" class="amber-text"></mdb-icon>
            </li>
            <li>
              <mdb-icon fas icon="star" class="amber-text"></mdb-icon>
            </li>
            <li>
              <mdb-icon fas icon="star" class="amber-text"></mdb-icon>
            </li>
            <li>
              <mdb-icon fas icon="star" class="amber-text"></mdb-icon>
            </li>
          </ul>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi cupiditate temporibus iure soluta. Quasi mollitia
            maxime nemo quam accusamus possimus, voluptatum expedita assumenda. Earum sit id ullam eum vel delectus!</p>
        </div>
      </div>

      <!--Third review-->
      <div class="media mb-1">
        <a class="media-left waves-light">
          <img class="rounded-circle" src="https://mdbootstrap.com/img/Photos/Avatars/avatar-7.jpg" alt="Generic placeholder image">
        </a>
        <div class="media-body">
          <h4 class="media-heading">Dave Snow</h4>
          <ul class="rating inline-ul">
            <li>
              <mdb-icon fas icon="star" class="amber-text"></mdb-icon>
            </li>
            <li>
              <mdb-icon fas icon="star" class="amber-text"></mdb-icon>
            </li>
            <li>
              <mdb-icon fas icon="star" class="amber-text"></mdb-icon>
            </li>
            <li>
              <mdb-icon fas icon="star" class="amber-text"></mdb-icon>
            </li>
            <li>
              <mdb-icon fas icon="star"></mdb-icon>
            </li>
          </ul>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi cupiditate temporibus iure soluta. Quasi mollitia
            maxime nemo quam accusamus possimus, voluptatum expedita assumenda. Earum sit id ullam eum vel delectus!</p>
        </div>
      </div>

    </section>
    <!--Section: Reviews-->
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-md-10">
    <!--Section: Linked items-->
    <section class="mb-5 ml-10 mr-10">
      <h5 class="my-5 dark-grey-text font-bold">Custom content</h5>

      <div class="list-group">
        <a href="#" class="list-group-item list-group-item-action flex-column align-items-start active">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">List group item heading</h5>
            <small>3 days ago</small>
          </div>
          <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
          <small>Donec id elit non mi porta.</small>
        </a>
        <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">List group item heading</h5>
            <small class="text-muted">3 days ago</small>
          </div>
          <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
          <small class="text-muted">Donec id elit non mi porta.</small>
        </a>
        <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">List group item heading</h5>
            <small class="text-muted">3 days ago</small>
          </div>
          <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>
          <small class="text-muted">Donec id elit non mi porta.</small>
        </a>
      </div>

    </section>
    <!--Section: Reviews-->
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-md-10">
    <!--Section: Linked items-->
    <section class="mb-5 pb-5 ml-10 mr-10">
      <h5 class="my-5 dark-grey-text font-bold">Vertical tab</h5>



      <!-- Tabset tabs -->
      <mdb-tabset [vertical]="true" [buttonClass]="'tabs-white flex-column'" [contentClass]="'vertical'">
        <!--Panel 1-->
        <mdb-tab heading="<a class='list-group-item'> Home </a>">
          <div class="row">
            <div class="col-12">
              <br>
              <p class="h5"> Heading 1 </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam similique, eveniet inventore magni laborum,
                a sed quaerat quam dicta possimus, quos sint veniam. Minus vero quo voluptates, error, quisquam impedit.</p>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 2-->
        <mdb-tab heading="<a class='list-group-item'> Profile </a>">
          <div class="row">
            <div class="col-12">
              <br>
              <p class="h5"> Heading 2 </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam similique, eveniet inventore magni laborum,
                a sed quaerat quam dicta possimus, quos sint veniam. Minus vero quo voluptates, error, quisquam impedit.</p>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 3-->
        <mdb-tab heading="<a class='list-group-item'> Messages </a>">
          <div class="row">
            <div class="col-12">
              <br>
              <p class="h5"> Heading 3 </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam similique, eveniet inventore magni laborum,
                a sed quaerat quam dicta possimus, quos sint veniam. Minus vero quo voluptates, error, quisquam impedit.</p>
            </div>
          </div>
        </mdb-tab>
        <!--Panel 4-->
        <mdb-tab heading="<a class='list-group-item'> Settings </a>">
          <div class="row">
            <div class="col-12">
              <br>
              <p class="h5"> Heading 4 </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam similique, eveniet inventore magni laborum,
                a sed quaerat quam dicta possimus, quos sint veniam. Minus vero quo voluptates, error, quisquam impedit.</p>
            </div>
          </div>
        </mdb-tab>
      </mdb-tabset>
    </section>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-md-10">
    <!--Section: Docs link-->
    <section class="pb-4">
      <panel header="Full documentation" color="primary-color">
        <p class="card-text">Read the full documentation for these components.</p>
        <a href="https://mdbootstrap.com/angular/components/list-group/" target="_blank" mdbBtn color="primary" class="waves-effect waves-light">Learn more</a>
      </panel>
    </section>
    <!--/.Section: Docs link-->
  </div>
</div>
