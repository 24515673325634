<!--Panel 1-->
<!-- Tabset tabs -->
<mdb-tabset [vertical]="true" [buttonClass]="'tabs-white flex-column'" [contentClass]="'vertical'" (showBsTab)="showLoader($event)">
  <mdb-tab heading="<a class='list-group-item'><i class='fas fa-file-text-o' aria-hidden='true'></i> About</a>">
    <!--First row-->
    <div class="row mt-3">
      <!--First column-->
      <div class="col-md-6">
        <div class="md-form">
          <input placeholder="MDB Admin Dashboard (Angular)" [disabled]='true' type="text" id="form11" class="form-control" mdbInput>
          <label class="active disabled" for="form11">Name</label>
        </div>
      </div>
      <!--Second column-->
      <div class="col-md-6">
        <div class="md-form">
          <input placeholder="6.0.0" type="text" id="form12" [disabled]='true' class="form-control" mdbInput>
          <label class="active" for="form12">Version</label>
        </div>
      </div>
    </div>
    <!--/.First row-->
    <!--Second row-->
    <div class="row">
      <!--First column-->
      <div class="col-md-6">
        <div class="md-form">
          <input placeholder="https://mdbootstrap.com/angular" [disabled]='true' type="text" id="form11" class="form-control" mdbInput>
          <label class="active disabled" for="form11">URL</label>
        </div>
      </div>
      <!--Second column-->
      <div class="col-md-6">
        <div class="md-form">
          <input placeholder="MDBootstrap Team" [disabled]='true' type="text" id="form12" class="form-control" mdbInput>
          <label class="active" for="form12">Author</label>
        </div>
      </div>
    </div>
    <!--/.Second row-->
    <!--Third row-->
    <div class="row">
      <!--First column-->
      <div class="col-md-6">
        <div class="md-form">
          <textarea mdbInput type="text" [disabled]='true' id="form76" class="md-textarea form-control">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,eaque
            ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. </textarea>
          <label for="form76">Description</label>
        </div>
      </div>
      <!--Second column-->
      <div class="col-md-6">
        <div class="md-form">
          <input placeholder="Active" [disabled]='true' type="text" id="form12" class="form-control" mdbInput>
          <label class="active" for="form12">Status</label>
        </div>
      </div>
    </div>
    <!--/.Third row-->
    <!--Second row-->
    <div class="row">
      <!--First column-->
      <div class="col-md-6">
        <div class="md-form">
          <label class="active disabled" for="form11">Tags:</label>
        </div>
        <div class="chip">Angular</div>
        <div class="chip">Admin</div>
        <div class="chip">Java Script</div>
        <div class="chip">HTML</div>
        <div class="chip">CSS</div>
        <div class="chip">Dashboard</div>
        <div class="chip">Typescript</div>
        <div class="chip">Template</div>
        <div class="chip">Server</div>
        <div class="chip">Website</div>
        <div class="chip">Charts</div>
        <div class="chip">Tables</div>
        <div class="chip">Alerts</div>
        <div class="chip">Notifications</div>
        <div class="chip">Analysis</div>
        <div class="chip">Business</div>
        <div class="chip">Sales</div>
      </div>
      <!--Second column-->
      <div class="col-md-6">
        <div class="md-form">
          <input placeholder="office@myexample.com" [disabled]='true' type="text" id="form12" class="form-control" mdbInput>
          <label class="active" for="form12">Email</label>
        </div>
      </div>
    </div>
    <!--/.Second row-->
  </mdb-tab>
  <!--Panel 2-->
  <mdb-tab heading="<a class='list-group-item'><i class='fas fa-wrench' aria-hidden='true'></i> Settings</a>">
    <!--First row-->
    <div class="row mt-3">
      <!--First column-->
      <div class="col-md-6">
        <label for="basic-url">Username</label>
        <div class="md-form input-group">
          <input type="text" class="form-control" placeholder="Recipient's username" aria-describedby="basic-addon2" mdbInput>
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon2">@example.com</span>
          </div>
        </div>
      </div>
      <!--Second column-->
      <div class="col-md-6">
        <label for="basic-url">URL</label>
        <div class="md-form input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">https://example.com/users/</span>
          </div>
          <input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3" mdbInput>
        </div>
      </div>
    </div>
    <!--/.First row-->
    <!--Second row-->
    <div class="row">
      <!--First column-->
      <div class="col-md-6 my-5">
        <h3>Link options:</h3>
        <div class="form-group">
          <input name="group1" type="radio" class="with-gap form-check-input" id="radio1" checked="checked">
          <label for="radio1" class="form-check-label">Plain</label>
        </div>
        <div class="form-group">
          <input name="group1" type="radio" class="with-gap form-check-input" id="radio2">
          <label for="radio2" class="form-check-label">Day and name</label>
        </div>
        <div class="form-group">
          <input name="group1" type="radio" class="with-gap form-check-input" id="radio3">
          <label for="radio3" class="form-check-label">Numeric</label>
        </div>
      </div>
      <!--Second column-->
      <div class="col-md-6  my-5">
        <h3>Share options:</h3>
        <mdb-checkbox>Facebook</mdb-checkbox>
        <mdb-checkbox>Twitter</mdb-checkbox>
        <mdb-checkbox>Google+</mdb-checkbox>
      </div>
    </div>
    <!--/.Second row-->
    <!--Third row-->
    <div class="row">
      <!--First column-->
      <div class="col-md-6">
        <h3>Do you want this option?</h3>
        <form class="form-inline">
          <div class="form-group px-3">
            <input name="group1" type="radio" id="radio11" checked="checked" class="form-check-input">
            <label for="radio11" class="form-check-label">Yes</label>
          </div>
          <div class="form-group px-3">
            <input name="group1" type="radio" id="radio21" class="form-check-input">
            <label for="radio21" class="form-check-label">No</label>
          </div>
        </form>
      </div>
      <!--Second column-->
      <div class="col-md-6">
        <h3>Autoshare?</h3>
        <!-- Switch -->
        <div class="switch">
          <label>
            Off
            <input type="checkbox">
            <span class="lever"></span>
            On
          </label>
        </div>
      </div>
    </div>
    <!--/.Third row-->
  </mdb-tab>
  <!--Panel 3-->
  <mdb-tab heading="<a class='list-group-item'><i class='fas fa-share-alt' aria-hidden='true'></i> Social</a>">
    <!--First row-->
    <div class="row mt-3">
      <!--First column-->
      <div class="col-md-6">
        <div class="md-form">
          <mdb-icon fab icon="facebook-f"></mdb-icon>
          <input placeholder="https://www.facebook.com/mdbootstrap" type="text" id="form2" class="form-control" mdbInput>
        </div>
      </div>
      <!--Second column-->
      <div class="col-md-6">
        <div class="md-form">
          <mdb-icon fab icon="linkedin-in"></mdb-icon>
          <input placeholder="https://www.linkedin.com/company/10912052" type="text" id="form2" class="form-control" mdbInput>
        </div>
      </div>
    </div>
    <!--/.First row-->
    <!--Second row-->
    <div class="row mt-3">
      <!--First column-->
      <div class="col-md-6">
        <div class="md-form">
          <mdb-icon fab icon="twitter"></mdb-icon>
          <input placeholder="https://twitter.com/MDBootstrap" type="text" id="form2" class="form-control" mdbInput>
        </div>
      </div>
      <!--Second column-->
      <div class="col-md-6">
        <div class="md-form">
          <mdb-icon fab icon="google-plus-g"></mdb-icon>
          <input placeholder="https://plus.google.com/u/0/b/107863090883699620484/+Mdbootstrap/posts" type="text" id="form2" class="form-control" mdbInput>
        </div>
      </div>
    </div>
    <!--/.Second row-->
    <!--Third row-->
    <div class="row mt-3">
      <!--First column-->
      <div class="col-md-6">
        <div class="md-form">
          <mdb-icon fab icon="instagram"></mdb-icon>
          <input placeholder="https://istagram.com/mdbootstrap/" type="text" id="form2" class="form-control" mdbInput>
        </div>
      </div>
      <!--Second column-->
      <div class="col-md-6">
        <div class="md-form">
          <mdb-icon fab icon="pinterest"></mdb-icon>
          <input placeholder="https://pinterest.com/mdbootstrap/" type="text" id="form2" class="form-control" mdbInput>
        </div>
      </div>
    </div>
    <!--/.Third row-->
    <!--Fourth row-->
    <div class="row mt-3">
      <!--First column-->
      <div class="col-md-6">
        <div class="md-form">
          <mdb-icon fab icon="youtube"></mdb-icon>
          <input placeholder="https://www.youtube.com/watch?v=7MUISDJ5ZZ4" type="text" id="form2" class="form-control" mdbInput>
        </div>
      </div>
      <!--Second column-->
      <div class="col-md-6">
        <div class="md-form">
          <mdb-icon fab icon="dribbble"></mdb-icon>
          <input placeholder="https://dribbble.com/mdbootstrap" type="text" id="form2" class="form-control" mdbInput>
        </div>
      </div>
    </div>
    <!--/.Fourth row-->
    <!--Fifth row-->
    <div class="row mt-3">
      <!--First column-->
      <div class="col-md-6">
        <div class="md-form">
          <mdb-icon fab icon="github"></mdb-icon>
          <input placeholder="https://github.com/mdbootstrap" type="text" id="form2" class="form-control" mdbInput>
        </div>
      </div>
      <!--Second column-->
      <div class="col-md-6">
        <div class="md-form">
          <mdb-icon fab icon="stack-overflow"></mdb-icon>
          <input placeholder="https://stackoverflow.com/mdbootstrap" type="text" id="form2" class="form-control" mdbInput>
        </div>
      </div>
    </div>
    <!--/.Fifth row-->
  </mdb-tab>
  <!--Panel 3-->
  <!--Panel 4-->
  <mdb-tab heading="<a class='list-group-item check'><i class='fas fa-spinner' aria-hidden='true'></i> Update</a>">
    <div class="row">
      <div class="col-12">
        <br>
        <h3 class="h3-responsive text-center">Your version (6.0.0) is up to date.</h3>
        <h5 class="h5-responsive text-center">Last update check on:
          <code>2018.04.19</code>
          <div class="view hm-black-strong" *ngIf="visible" style="height:100vh;width:100vw;position:fixed;top:0;left:0;z-index:999">
            <div class="mask flex-center">
              <p class="white-text">Comparing versions...</p>
              <br>
              <mdb-spinner spinnerType="big"></mdb-spinner>
            </div>
          </div>
        </h5>
      </div>
    </div>
  </mdb-tab>
</mdb-tabset>
