<!-- Intro Section -->

<main>
  <div class="container py-5">
    <!--Section: Blog v.3-->
    <section
      class="section extra-margins pb-3 text-center text-lg-left wow fadeIn py-3"
      data-wow-delay="0.3s"
      style="animation-name: none; visibility: visible;"
    >
      <!--Section heading-->
      <h2 class="section-heading h1 pt-5">Recent posts</h2>
      <!--Section description-->
      <p class="section-description">
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>

      <!--Grid row-->
      <div *ngFor="let post of posts">
        <div class="row">
          <!--Grid column-->
          <div class="col-lg-4 mb-4">
            <!--Featured image-->
            <div class="view overlay z-depth-1">
              <img
                src="{{ post.img }}"
                alt="{{ post.number }} sample image"
                class="img-fluid"
              />
              <a>
                <div
                  class="mask rgba-white-slight waves-effect waves-light"
                  mdbWavesEffect
                ></div>
              </a>
            </div>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-7 mb-4">
            <!--Excerpt-->
            <a href="" class="{{ post.color }}">
              <h6 class="pb-1">
                <i class="{{ post.icon }}"></i>
                <strong> {{ post.category }}</strong>
              </h6>
            </a>
            <h4 class="mb-4">
              <strong>{{ post.title }}</strong>
            </h4>
            <p>{{ post.content }}</p>
            <p>
              by
              <a>
                <strong>{{ post.by }}</strong> </a
              >, {{ post.date }}
            </p>
            <a
              mdbBtn
              color="primary"
              class="waves-effect waves-light"
              mdbWavesEffect
              >Read more</a
            >
          </div>
          <!--Grid column-->
        </div>
        <hr class="mb-5" />
        <!--Grid row-->
      </div>

      <!--Pagination dark-->
      <nav
        class="wow fadeIn mb-4 mt-5"
        data-wow-delay="0.4s"
        style="visibility: visible; animation-name: none;"
      >
        <ul class="pagination pg-dark flex-center">
          <!--Arrow left-->
          <li class="page-item">
            <a
              class="page-link waves-effect waves-effect"
              aria-label="Previous"
              mdbWavesEffect
            >
              <span aria-hidden="true">«</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>

          <!--Numbers-->
          <li class="page-item active">
            <a class="page-link waves-effect waves-effect" mdbWavesEffect>1</a>
          </li>
          <li class="page-item">
            <a class="page-link waves-effect waves-effect" mdbWavesEffect>2</a>
          </li>
          <li class="page-item">
            <a class="page-link waves-effect waves-effect" mdbWavesEffect>3</a>
          </li>
          <li class="page-item">
            <a class="page-link waves-effect waves-effect" mdbWavesEffect>4</a>
          </li>
          <li class="page-item">
            <a class="page-link waves-effect waves-effect" mdbWavesEffect>5</a>
          </li>

          <!--Arrow right-->
          <li class="page-item">
            <a
              class="page-link waves-effect waves-effect"
              mdbWavesEffect
              aria-label="Next"
            >
              <span aria-hidden="true">»</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
      <!--/Pagination dark-->
    </section>
    <!--Section: Blog v.3-->

    <hr class="mb-5" />

    <!--Section: Blog v.2-->
    <section
      class="section extra-margins text-center pb-3 wow fadeIn"
      data-wow-delay="0.3s"
      style="animation-name: none; visibility: visible;"
    >
      <!--Section heading-->
      <h2 class="section-heading h1 pt-4">Older posts</h2>
      <!--Section description-->
      <p class="section-description">
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      <div class="row">
        <!--Grid column-->
        <div class="col-lg-4 col-md-12 my-5" *ngFor="let post2 of posts2">
          <!--Featured image-->
          <div class="view overlay z-depth-1 mb-2">
            <img
              src="{{ post2.img }}"
              alt="{{ post2.number }} sample image"
              class="img-fluid"
            />
            <a>
              <div
                class="mask rgba-white-slight waves-effect waves-light"
                mdbWavesEffect
              ></div>
            </a>
          </div>
          <!--Excerpt-->
          <a href="" class="{{ post2.color }}">
            <h6 class="mb-3 mt-3">
              <i class="{{ post2.icon }}"></i>
              <strong> {{ post2.category }}</strong>
            </h6>
          </a>
          <h4 class="mb-3">
            <strong>{{ post2.title }}</strong>
          </h4>
          <p>
            by
            <a>
              <strong>{{ post2.by }}</strong> </a
            >, {{ post2.date }}
          </p>
          <p>{{ post2.content }}</p>
          <a
            mdbBtn
            color="primary"
            class="waves-effect waves-light"
            mdbWavesEffect
            >Read more</a
          >
        </div>
        <!--Grid column-->
      </div>
    </section>
    <!--Section: Blog v.2-->
  </div>
</main>

<footer class="page-footer text-center text-md-left pt-4 mt-4">
  <!--Footer Links-->
  <div class="container">
    <div class="row mt-3">
      <!--First column-->
      <div class="col-md-3 offset-md-1 col-12 my-5">
        <h6 class="title mb-4 font-bold">Company name</h6>
        <p>
          Here you can use rows and columns here to organize your footer
          content. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </p>
      </div>
      <!--/.First column-->

      <hr class="hidden-md-up" />

      <!--Second column-->
      <div class="col-md-2 offset-md-1 col-12 my-5">
        <h6 class="title mb-4 font-bold">Products</h6>
        <p>
          <a href="#!">MDBootstrap</a>
        </p>
        <p>
          <a href="#!">MDWordPress</a>
        </p>
        <p>
          <a href="#!">BrandFlow</a>
        </p>
        <p>
          <a href="#!">Bootstrap Angular</a>
        </p>
      </div>
      <!--/.Second column-->

      <hr class="hidden-md-up" />

      <!--Third column-->
      <div class="col-md-2 col-12 my-5">
        <h6 class="title mb-4 font-bold">Useful links</h6>
        <p>
          <a href="#!">Your Account</a>
        </p>
        <p>
          <a href="#!">Become an Affiliate</a>
        </p>
        <p>
          <a href="#!">Shipping Rates</a>
        </p>
        <p>
          <a href="#!">Help</a>
        </p>
      </div>
      <!--/.Third column-->

      <hr class="hidden-md-up" />

      <!--Fourth column-->
      <div class="col-md-3 col-12">
        <h6 class="title mb-4 font-bold">Contact</h6>
        <p>
          <mdb-icon fas icon="th-large" class="mr-3"></mdb-icon> New York, NY
          10012, US
        </p>
        <p>
          <mdb-icon far icon="envelope" class="mr-3"></mdb-icon>
          info@example.com
        </p>
        <p>
          <mdb-icon fas icon="phone" class="mr-3"></mdb-icon> + 01 234 567 88
        </p>
        <p>
          <mdb-icon fas icon="print" class="mr-3"></mdb-icon> + 01 234 567 89
        </p>
      </div>
      <!--/.Fourth column-->
    </div>

    <!--/.Footer Links-->

    <hr />

    <!--Footer Links-->
    <div class="row mt-3 d-flex align-items-center">
      <!--Grid column-->
      <div class="col-md-7  col-12">
        <!--Copyright-->
        <p class="text-left grey-text">
          © 2017 Copyright:
          <a href="https://www.MDBootstrap.com">
            <strong> MDBootstrap.com</strong>
          </a>
        </p>
        <!--/.Copyright-->
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-md-5 col-12">
        <!--Social buttons-->
        <div class="social-section text-left">
          <ul class="list-unstyled list-inline">
            <li class="list-inline-item">
              <a
                mdbBtn
                floating="true"
                size="sm"
                class="rgba-white-slight mr-4 waves-effect waves-light"
              >
                <mdb-icon fab icon="facebook-f"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                mdbBtn
                floating="true"
                size="sm"
                class="rgba-white-slight mr-4 waves-effect waves-light"
              >
                <mdb-icon fab icon="twitter"></mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                mdbBtn
                floating="true"
                size="sm"
                class="rgba-white-slight mr-4 waves-effect waves-light"
              >
                <mdb-icon fab icon="google-plus-g"> </mdb-icon>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                mdbBtn
                floating="true"
                size="sm"
                class="rgba-white-slight mr-4 waves-effect waves-light"
              >
                <mdb-icon fab icon="linkedin-in"> </mdb-icon>
              </a>
            </li>
          </ul>
        </div>
        <!--/.Social buttons-->
      </div>
      <!--Grid column-->
    </div>
  </div>
</footer>
