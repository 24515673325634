<div class="row">
    <div class="col-md-8">

        <!--Card-->
        <mdb-card cascade="true" narrower="true">

            <!--Card image-->
            <div class="view view-cascade gradient-card-header light-blue lighten-1">
                <h4 class="h4-responsive">Edit your profile:</h4>
            </div>
            <!--/Card image-->

            <!--Card content-->
            <mdb-card-body cascade="true" class="text-center">

                <form>

                    <!--First row-->
                    <div class="row">
                        <!--First column-->
                        <div class="col-md-4">
                            <div class="md-form">
                                <input [disabled]='true' type="text" id="form11"
                                       class="form-control" mdbInput>
                                <label class="disabled" for="form11">Username</label>
                            </div>
                        </div>

                        <!--Second column-->
                        <div class="col-md-4">
                            <div class="md-form">
                                <input placeholder="John" type="text" id="form12" class="form-control" mdbInput>
                                <label class="active" for="form12">First Name</label>
                            </div>
                        </div>

                        <!--Third column-->
                        <div class="col-md-4">
                            <div class="md-form">
                                <input placeholder="Doe" type="text" id="form13" class="form-control" mdbInput>
                                <label class="active" for="form13">Last Name</label>
                            </div>
                        </div>
                    </div>
                    <!--/.First row-->

                    <!--Second row-->
                    <div class="row">
                        <!--First column-->
                        <div class="col-md-6">
                            <div class="md-form">
                                <input placeholder="" type="text" id="form21" class="form-control" mdbInput>
                                <label for="form21">Address line 1</label>
                            </div>
                        </div>

                        <!--Second column-->
                        <div class="col-md-6">
                            <div class="md-form">
                                <input placeholder="" type="text" id="form22" class="form-control" mdbInput>
                                <label for="form22">Address line 2</label>
                            </div>
                        </div>
                    </div>
                    <!--/.Second row-->

                    <!--Third row-->
                    <div class="row">
                        <!--First column-->
                        <div class="col-md-4">
                            <div class="md-form">
                                <input placeholder="New York" type="text" id="form31" class="form-control" mdbInput>
                                <label class="active" for="form31">City</label>
                            </div>
                        </div>

                        <!--Second column-->
                        <div class="col-md-4">
                            <div class="md-form">
                                <input placeholder="00432" type="text" id="form32" class="form-control" mdbInput>
                                <label class="active" for="form32">Postcode</label>
                            </div>
                        </div>

                        <!--Third column-->
                        <div class="col-md-4">
                            <div class="md-form">
                                <input type="text" id="form33" class="form-control" mdbInput>
                                <label for="form33">Country</label>
                            </div>
                        </div>
                    </div>
                    <!--/.Third row-->

                    <!--Fourth row-->
                    <div class="row">
                        <div class="col-md-12">

                            <div class="md-form">
                                <textarea type="text" id="form76" class="md-textarea form-control"
                                          mdbInput>Lorem</textarea>
                                <label for="form76">Bio</label>
                            </div>

                        </div>


                    </div>
                    <!--/.Fourth row-->

                    <div class="text-center">
                        <button type="button" mdbBtn gradient="blue" rounded="true" class="waves-light" mdbWavesEffect>
                            Update
                        </button>
                    </div>

                </form>
                <!--Contact Form-->

            </mdb-card-body>
            <!--/.Card content-->

        </mdb-card>
        <!--/.Card-->


    </div>
    <div class="col-md-4">

        <!--Section: Team v.2-->
        <section class="section team-section pb-3">

            <div class="row text-center">


                <div class="col-md-12">
                    <!--Card-->
                    <mdb-card cascade="true" narrower="true">
                        <!--Card image-->
                        <div class="view view-cascade gradient-card-header blue-gradient">
                            <h4 class="h4-responsive">Bio: </h4>
                        </div>
                        <!--/Card image-->
                        <!--Card content-->
                        <mdb-card-body cascade="true" class="text-center">
                            <div class="avatar">
                                <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(10).jpg" class="z-depth-1"
                                     alt="First sample avatar image">
                            </div>
                            <h4>Maria Kate</h4>
                            <h5>Photographer</h5>

                            <!--Facebook-->
                            <a type="button" mdbBtn floating="true" size="sm" color="fb" class="waves-light"
                               mdbWavesEffect>
                                <mdb-icon fab icon="facebook-f"></mdb-icon>
                            </a>
                            <!--Dribbble-->
                            <a type="button" mdbBtn floating="true" size="sm" color="dribbble" class="waves-light"
                               mdbWavesEffect>
                                <mdb-icon fab icon="dribbble"></mdb-icon>
                            </a>
                            <!--Twitter-->
                            <a type="button" mdbBtn floating="true" size="sm" color="tw" class="waves-light"
                               mdbWavesEffect>
                                <mdb-icon fab icon="twitter"></mdb-icon>
                            </a>

                        </mdb-card-body>
                        <!--/.Card content-->
                    </mdb-card>
                    <!--/.Card-->
                </div>

            </div>
        </section>
        <!--Section: Team v.2-->

    </div>
</div>
