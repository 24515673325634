<section class="view intro intro-2 ">
  <div class="full-bg-img mask rgba-stylish-strong">
    <div class="container">
      <!--Section: Pricing v.3-->
      <section class="section pt-5 my-5">
        <!--Section heading-->
        <h1 class="section-heading h1 py-5 white-text text-center">
          Our pricing plans
        </h1>
        <!--Section description-->
        <p class="section-description white-text text-center py-5">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
          amet numquam iure provident voluptate esse quasi, veritatis totam
          voluptas nostrum quisquam eum porro a pariatur accusamus veniam.
        </p>

        <!--Grid row-->
        <div class="row">
          <!--Grid column-->
          <div class="col-lg-4 col-md-12 my-5">
            <!--Card-->
            <mdb-card class="hoverable text-center">
              <!--Content-->
              <mdb-card-body>
                <h5>Basic plan</h5>
                <div class="card-circle d-flex flex-center mx-auto">
                  <mdb-icon
                    fas
                    icon="home"
                    size="4x"
                    class="light-blue-text"
                  ></mdb-icon>
                </div>

                <!--Price-->
                <h2 class="font-bold my-3">59$</h2>
                <p class="grey-text">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Culpa pariatur id nobis accusamus deleniti cumque hic laborum.
                </p>
                <a
                  mdbBtn
                  color="light-blue"
                  rounded="true"
                  class="waves-effect waves-light"
                  >Buy now</a
                >
              </mdb-card-body>
            </mdb-card>
            <!--Card-->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-4 col-md-12 my-5">
            <!--Card-->
            <mdb-card
              bgColor="purple-gradient"
              class="hoverable text-center text-white"
            >
              <!--Content-->
              <mdb-card-body>
                <h5>Premium plan</h5>
                <div class="card-circle d-flex flex-center mx-auto">
                  <mdb-icon
                    fas
                    icon="layer-group"
                    size="4x"
                    class="white-text"
                  ></mdb-icon>
                </div>

                <!--Price-->
                <h2 class="font-bold my-3">79$</h2>
                <p>
                  Esse corporis saepe laudantium velit adipisci cumque iste
                  ratione facere non distinctio cupiditate sequi atque.
                </p>
                <a
                  mdbBtn
                  outline="true"
                  color="white"
                  rounded="true"
                  class="waves-effect waves-light"
                  >Buy now</a
                >
              </mdb-card-body>
            </mdb-card>
            <!--Card-->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-4 col-md-12 my-5">
            <!--Card-->
            <mdb-card class="text-center hoverable">
              <!--Content-->
              <mdb-card-body>
                <h5>Advanced plan</h5>
                <div class="card-circle d-flex flex-center mx-auto">
                  <mdb-icon
                    fas
                    icon="chart-bar"
                    size="4x"
                    class="light-blue-text"
                  ></mdb-icon>
                </div>

                <!--Price-->
                <h2 class="font-bold my-3">99$</h2>
                <p class="grey-text">
                  At ab ea a molestiae corrupti numquam quo beatae minima
                  ratione magni accusantium repellat eveniet quia vitae.
                </p>
                <a
                  mdbBtn
                  color="light-blue"
                  rounded="true"
                  class="waves-effect waves-light"
                  >Buy now</a
                >
              </mdb-card-body>
            </mdb-card>
            <!--Card-->
          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </section>
      <!--Section: Pricing v.3-->
    </div>
  </div>
</section>
