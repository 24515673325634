<div class="row mt-5">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">Progress bars </h4>
      </div>
      <!--/Card image-->
      <!--Card content-->
      <mdb-card-body cascade="true" class="text-center">
        <section>

          <div class="row">
            <h2 class="mt-lg-5 mb-5 pb-4 font-bold">
              <strong>Progress bars</strong>
            </h2>
          </div>


          <div class="row">
            <div class="col-md-12 text-left">

              <h5 class="mb-3 dark-grey-text font-bold">Basic examples</h5>

              <mdb-progress value="0" min="0" max="100" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></mdb-progress>
              <mdb-progress value="25" min="0" max="100" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></mdb-progress>
              <mdb-progress value="50" min="0" max="100" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></mdb-progress>
              <mdb-progress value="75" min="0" max="100" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></mdb-progress>
              <mdb-progress value="100" min="0" max="100" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></mdb-progress>
            </div>
          </div>


          <div class="row">
            <div class="col-md-12 text-left">

              <h5 class="mt-5 mb-3 pt-4 dark-grey-text font-bold">Contextual alternatives</h5>

              <mdb-progress value="25" min="0" max="100" type="success" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></mdb-progress>
              <mdb-progress value="50" min="0" max="100" type="info" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></mdb-progress>
              <mdb-progress value="75" min="0" max="100" type="warning" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></mdb-progress>
              <mdb-progress value="100" min="0" max="100" type="danger" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></mdb-progress>
            </div>
          </div>


          <div class="row">
            <h5 class="mt-5 mb-3 pt-4 dark-grey-text font-bold">Infinite loading</h5>
            <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>
          </div>


          <div class="row mb-5">
            <h5 class="mt-5 mb-3 pt-4 dark-grey-text font-bold">Circles</h5>
          </div>

          <div class="row mb-5 pb-3">
            <div class="col-md-4">
              <mdb-spinner spinnerType="big" spinnerColor="blue"></mdb-spinner>
            </div>

            <div class="col-md-4">
              <mdb-spinner spinnerColor="red"></mdb-spinner>
            </div>

            <div class="col-md-4">
              <mdb-spinner spinnerType="small" spinnerColor="yellow"></mdb-spinner>
            </div>

          </div>

        </section>
        <!--Section: Docs link-->
        <section class="pb-4">
          <panel header="Full documentation" color="primary-color">
            <p class="card-text">Read the full documentation for these components.</p>
            <a href="https://mdbootstrap.com/angular/components/progressbar/" target="_blank" mdbBtn color="primary" class="waves-effect waves-light">Learn more</a>
          </panel>
        </section>
        <!--/.Section: Docs link-->
      </mdb-card-body>
      <!--/.Card content-->
    </mdb-card>
    <!--/.Card-->
  </div>
</div>
