<div class="row mt-5">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">Basic tables</h4>
      </div>
      <!--/Card image-->
      <!--Card content-->
      <mdb-card-body cascade="true">
        <h3 class="mt-5 text-left"><strong>Basic examples</strong></h3>
        <p>
          Using the most basic table markup, here’s how .table-based tables look
          in Bootstrap. All table styles are inherited in Bootstrap 4, meaning
          any nested tables will be styled in the same manner as the parent.
        </p>

        <table class="table table-responsive-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Larry</td>
              <td>the Bird</td>
              <td>@twitter</td>
            </tr>
          </tbody>
        </table>

        <h3 class="mt-5 text-left"><strong>Table head options</strong></h3>
        <p>
          To change a background-color of thead (or any other element) use our
          color classes. If you are going to use a dark background you should
          also consider white text (to provide a proper contrast) by adding
          .text-white class.
        </p>

        <!--Table-->
        <table class="table table-responsive-sm ">
          <!--Table head-->
          <thead class="blue-grey lighten-4">
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
            </tr>
          </thead>
          <!--Table head-->

          <!--Table body-->
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Larry</td>
              <td>the Bird</td>
              <td>@twitter</td>
            </tr>
          </tbody>
          <!--Table body-->
        </table>
        <!--Table-->

        <!--Table-->
        <table class="table table-responsive-sm ">
          <!--Table head-->
          <thead class="mdb-color darken-3">
            <tr class="text-white">
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
            </tr>
          </thead>
          <!--Table head-->

          <!--Table body-->
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Larry</td>
              <td>the Bird</td>
              <td>@twitter</td>
            </tr>
          </tbody>
          <!--Table body-->
        </table>
        <!--Table-->

        <h3 class="mt-5 text-left"><strong>Striped rows:</strong></h3>

        <table class="table table-responsive-sm table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Larry</td>
              <td>the Bird</td>
              <td>@twitter</td>
            </tr>
          </tbody>
        </table>
      </mdb-card-body>
      <!--/.Card content-->
    </mdb-card>
    <!--/.Card-->
  </div>
</div>
