<!--Footer-->
<footer class="page-footer center-on-small-only pt-0 mt-5">

  <!--Copyright-->
  <div class="footer-copyright text-center py-3">
    <div class="container-fluid">
      &copy; 2017 Copyright: <a href="https://www.MDBootstrap.com"> MDBootstrap.com </a>
    </div>
  </div>
  <!--/.Copyright-->

</footer>
<!--/.Footer-->
