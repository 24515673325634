<div class="row mt-5">
  <div class="col-md-12">
    <!--Card-->
    <mdb-card cascade="true" narrower="true" class="mt-5">
      <!--Card image-->
      <div class="view view-cascade gradient-card-header blue-gradient">
        <h4 class="h4-responsive">Tooltip </h4>
      </div>
      <!--/Card image-->
      <!--Card content-->
      <mdb-card-body cascade="true" class="text-center">
        <div class="row">
          <div class="col-md-12 col-xl-10 mx-auto">



            <div class="card-block py-5">

              <button type="button" mdbBtn color="primary" class="waves-light" mdbTooltip="Tooltip on left" placement="left" mdbWavesEffect>
                Tooltip on left
              </button>

              <button type="button" mdbBtn color="primary" class="waves-light" mdbTooltip="Tooltip on top" placement="top" mdbWavesEffect>
                Tooltip on top
              </button>

              <button type="button" mdbBtn color="primary" class="waves-light" mdbTooltip="Tooltip on bottom" placement="bottom" mdbWavesEffect>
                Tooltip on bottom
              </button>

              <button type="button" mdbBtn color="primary" class="waves-light" mdbTooltip="Tooltip on right" placement="right" mdbWavesEffect>
                Tooltip on right
              </button>
            </div>


          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-10">
            <!--Section: Docs link-->
            <section class="pb-4 pt-5">
              <panel header="Full documentation" color="primary-color">
                <p class="card-text">Read the full documentation for these components.</p>
                <a href="https://mdbootstrap.com/angular/advanced/tooltip/" target="_blank" mdbBtn color="primary" class="waves-effect waves-light">Learn more</a>
              </panel>
            </section>
            <!--/.Section: Docs link-->
          </div>
          <!--/.Card content-->
        </div>
        <!--/.Card-->
      </mdb-card-body>
    </mdb-card>
